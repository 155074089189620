import React from 'react';
import {
  ListItem, ListItemIcon, ListItemText, Divider,
  List, ListSubheader, makeStyles
} from '@material-ui/core';
import {
  DashboardOutlined, Fastfood, PersonAdd, GroupAdd, MenuBook,
  Schedule, TrackChanges, Alarm, ViewCarousel, Whatshot, WorkOff,
  ShoppingCart, Receipt, MoneyOff, Store, Category, Info, Settings, InsertEmoticon, Message
} from '@material-ui/icons';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  linkto: {
      color: 'inherit',
      textDecoration:'inherit'
  },
  listitem: {
    padding: '2px 8px',
  },
  header: {
    padding: '0px 8px',
    backgroundColor: 'white'
  },
  menu: {
    padding:0
  }
});

function AdminListItems(){
   const classes = useStyles();
   const token = useSelector(state => state.token.token);
    return (
      <div>
      <Divider />
      {
        token.role == "Administrator" || token.role == "Internal" ?
        <List className={classes.menu}>
          <Link to="/bo/dashboard" className={classes.linkto}>
            <ListItem button key="li-dashadmin" className={classes.listitem}>
              <ListItemIcon><DashboardOutlined /></ListItemIcon>
              <ListItemText primary="Dashboard"/>
            </ListItem>      
          </Link>
        </List> : null
      }
      {
        token.role == "Administrator" || token.role == "Internal" ?
        <div>
          <ListSubheader className={classes.header}>Menu</ListSubheader>
          <List className={classes.menu}>
            <Link to="/bo/master/user/list" className={classes.linkto}>
              <ListItem button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><PersonAdd /></ListItemIcon>
                <ListItemText primary="User"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/master/category/list" className={classes.linkto}>
              <ListItem button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Category /></ListItemIcon>
                <ListItemText primary="Kategori"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/master/item/list" className={classes.linkto}>
              <ListItem button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Store /></ListItemIcon>
                <ListItemText primary="Item"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/master/slider/list" className={classes.linkto}>
              <ListItem button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><ViewCarousel /></ListItemIcon>
                <ListItemText primary="Banner"/>
              </ListItem>      
            </Link>
          </List>
        </div> : null
      }
      {
        token.role == "Administrator" || token.role == "Internal" ?
        <div>
          <ListSubheader className={classes.header}>Setting</ListSubheader>
          <List className={classes.menu}>
            <Link to="/bo/contact/list" className={classes.linkto}>
              <ListItem button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Info /></ListItemIcon>
                <ListItemText primary="Kontak"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/settings" className={classes.linkto}>
              <ListItem button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Settings /></ListItemIcon>
                <ListItemText primary="Usaha"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/welcome" className={classes.linkto}>
              <ListItem button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><InsertEmoticon /></ListItemIcon>
                <ListItemText primary="Selamat Datang"/>
              </ListItem>      
            </Link>
          </List>
          <List className={classes.menu}>
            <Link to="/bo/aboutus" className={classes.linkto}>
              <ListItem button key="li-dashadmin" className={classes.listitem}>
                <ListItemIcon><Message /></ListItemIcon>
                <ListItemText primary="Tentang Kami"/>
              </ListItem>      
            </Link>
          </List>
        </div> : null
      }
  </div>
    )
}

export default AdminListItems