import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import axios from '../config/axios';

const PrivateRoute = ({ component: Component, roles, token, ...rest }) => (
    <Route {...rest} render={props => {
        let role = token !== null ? token.role : "";
        axios.defaults.headers.common['Authorization'] = "Bearer " + token.token.tkn;
        if (token === null) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(role) === -1) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: '/bo/dashboard'}} />
            // if(role == "Administrator" || role == "Internal") {

            //     return <Redirect to={{ pathname: '/bo/dashboard'}} />
            // } 
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)

const mapStatestoProps = state => {
    return {
        token: state.token
    };
};
  
export default connect(
mapStatestoProps
)(PrivateRoute);
 