import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage' 
import localForage from 'localforage';
import {reducer} from "./reducers/configReducer";

localForage.config({
    name: "katalokol",
    storeName: "persist",
    version: "0.1"
});

const persistConfig = {
    key: 'primary',
    storage: localForage,
    whitelist: ['token'] // place to select which state you want to persist
}

const persistedReducer = persistReducer(persistConfig, reducer)

const configureStore = () => {
    const store = createStore(persistedReducer);
    const persistor = persistStore(store)
    
    return {store, persistor}
}

export default configureStore;