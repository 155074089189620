
let urlConfig = (process.env.NODE_ENV !== "production") ? {
    urlBackend: process.env.REACT_APP_DEV_URL_BACKEND,
    urlBackendProfile: process.env.REACT_APP_DEV_URL_BACKEND_PROFILE,
    urlBackendMenu: process.env.REACT_APP_DEV_URL_BACKEND_MENU,
    urlBackendItem: process.env.REACT_APP_DEV_URL_BACKEND_ITEM,
    urlBackendSlider: process.env.REACT_APP_DEV_URL_BACKEND_SLIDER,
    urlBackendTransfer: process.env.REACT_APP_DEV_URL_BACKEND_TRANSFER,
} : {
    urlBackend: process.env.REACT_APP_PROD_URL_BACKEND,
    urlBackendProfile: process.env.REACT_APP_PROD_URL_BACKEND_PROFILE,
    urlBackendMenu: process.env.REACT_APP_PROD_URL_BACKEND_MENU,
    urlBackendItem: process.env.REACT_APP_PROD_URL_BACKEND_ITEM,
    urlBackendSlider: process.env.REACT_APP_PROD_URL_BACKEND_SLIDER,
    urlBackendTransfer: process.env.REACT_APP_PROD_URL_BACKEND_TRANSFER,
}

export default urlConfig;