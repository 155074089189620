export const ADD_TOKEN = 'ADD_TOKEN';
export const SELECT_TOKEN = 'SELECT_TOKEN';
export const DESELECT_TOKEN = 'DESELECT_TOKEN';
export const ADD_PARAM = 'ADD_PARAM';
export const SELECT_PARAM = 'SELECT_PARAM';
export const DESELECT_PARAM = 'DESELECT_PARAM';
export const ADD_LOGO = 'ADD_LOGO';
export const SELECT_LOGO = 'SELECT_LOGO';
export const DESELECT_LOGO = 'DESELECT_LOGO';

