import React, { useState, useEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import {
    Link
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import urlConfig from "./../config/backend";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        // marginLeft:'10%'
    }
});

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function Dashboardlogo(props) {
    // const today = new Date();
    const logoref = useSelector(state => state.logo);
    const classes = useStyles();
    const [logo, setLogo] = useState(urlConfig.urlBackend + 'bo/int/logo/' + logoref);

    let link = "/bo/dashboard";
    if (props.refuser == 'admin') {
        link = "/bo/dashadmin";
    }

    const prevLogo = usePrevious(logoref);
    // console.log("prevLogo", prevLogo, logoref);
    useEffect(() => {
        if(prevLogo !== logoref && logo !== require("../assets/katalok.png")) {
            setLogo(urlConfig.urlBackend + 'bo/int/logo/' + logoref);
        }
    });

    return (
        <div className={classes.container}>
            <Box component="span" m={0.25}>
                <Link to={link}>
                    <img id="logo" src={logo} alt="logo" onError={() => setLogo(require("../assets/katalok.png"))} style={{ maxWidth: 150, maxHeight: 45 }} />
                </Link>
            </Box>
        </div>
    )
}

// const mapStatestoProps = state => {
//     return {
//       logo: state.logo
//     };
// };

export default (Dashboardlogo);