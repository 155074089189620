import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {InputLabel, Select, MenuItem, Card, Paper, 
  CardMedia ,CardContent, Typography} from '@material-ui/core';
import {MoodBad} from '@material-ui/icons';
import axios from '../../../../../config/axios';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { connect } from "react-redux";
import moment from 'moment';

import IntlMessages from "../../../../../util/IntlMessages";
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

import DataTable from 'react-data-table-component';

//import actions
import { addParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});

function numberWithCommas(x) {
  var parts = x.toString().split(",");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
}

class DbReportOmset extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        page:{
          type: '',
          startstr: new Date(),
          start: moment().format('YYYY-MM-DD'),
          endstr: new Date(),
          end: moment().format('YYYY-MM-DD')
        },
        omsetList:[],
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        dialogMode:'',
        detailOpen:false,
      }
  }

  

  componentDidMount(){
    this.getOmsetReport();
  }

  showDialog = (title,content,mode) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content,
      dialogMode:mode
    });
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen:false
    })
  }

  getOmsetReport = () => {
    let outer = this;
    axios.post('bo/int/report/omset', outer.state.page).then(({data})=>{
      if(data.st && data.sc === 200){
        outer.setState({
          omsetList:data.data
        });
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  onchangeType = (e) => {
    this.setState({
      page: {
        ...this.state.page,
        type: e.target.value
      }
    }, () => {
      this.getOmsetReport();
    })
  }

  onchangeDateStart = (e) => {
    this.setState({
      page: {
        ...this.state.page,
        startstr: e,
        start: e ? moment(e).format('YYYY-MM-DD') : ""
      }
    }, () => {
      this.getOmsetReport();
    })
  }

  onchangeDateEnd = (e) => {
    this.setState({
      page: {
        ...this.state.page,
        endstr: e,
        end: e ? moment(e).format('YYYY-MM-DD') : ""
      }
    }, () => {
      this.getOmsetReport();
    })
  }
  
  render() {
    const { classes, container, theme, content, title } = this.props;
    const { omsetList} = this.state;
    
      
    return (
      <div style={{marginTop: theme.spacing(4), height: '100%'}}>
        <div>
          <Typography
            variant="h5"
            component="h5"
          >
            Laporan Omset
          </Typography>
        </div>
        <br/>
        <div>
          <div>
            <InputLabel style={{fontSize: 12}} id="formtype">
              Tipe Laporan
            </InputLabel>
            <Select
              value={this.state.page.type}
              onChange={this.onchangeType}
              labelId={"formtype"}
              fullWidth
            >
              <MenuItem value="weekly">Mingguan</MenuItem>
              <MenuItem value="monthly">Bulanan</MenuItem>
              <MenuItem value="yearly">Tahunan</MenuItem>

            </Select>
          </div>
          <br/>
          <div>
            <InputLabel style={{fontSize: 12}} id="formtype">
              Tanggal Mulai
            </InputLabel>
            <KeyboardDatePicker
              clearable
              value={this.state.page.startstr}
              placeholder="10-10-2018"
              onChange={date => this.onchangeDateStart(date)}
              format="DD-MM-YYYY"
              fullWidth
            />
          </div>
          <br/>
          <div >
            <InputLabel style={{fontSize: 12}} id="formtype">
              Tanggal Akhir
            </InputLabel>
            <KeyboardDatePicker
              clearable
              value={this.state.page.endstr}
              placeholder="10-10-2018"
              onChange={date => this.onchangeDateEnd(date)}
              format="DD-MM-YYYY"
              fullWidth
            />
          </div>
        </div>
        {
          omsetList.length > 0 &&
          <Paper className={classes.root}>
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: 10,
                  }}
                >
                  <Typography
                    component="h5"
                    variant="h5"
                  >
                    Omset Tercapai
                  </Typography>
                </div>
                <div style={{width: '100%', height: 300}}>

                  <ResponsiveContainer>

                    <LineChart
                      data={omsetList}
                      margin={{
                        top: 10,
                        bottom: 10,
                        right: 15
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis dataKey="omset"/>
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="omset" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
          </Paper>
        }
      </div>
    );
  }
}

DbReportOmset.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbReportOmset)));