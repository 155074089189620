import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {IconButton, TextField, Checkbox, Card, FormControl, Chip, Tooltip,
  CardMedia ,CardContent, Typography, InputLabel, Select, MenuItem} from '@material-ui/core';
import {MoodBad, Power, PowerOff} from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import {compareSequence} from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';
import IntlMessages from "../../../../../util/IntlMessages";
import urlConfig from "./../../../../../config/backend";

import DataTable from 'react-data-table-component';

//import actions
import { addParam,deselectParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});

function numberWithCommas(x) {
  var parts = x.toString().split(",");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
}


class DbMasterSlider extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        page:{
          active: "", //Inactive, Active
          catname: "",
          search: '',
          page:1,
          count:10
        },
        nonact: {},
        sliderList:[],
        sliderListTotal: 0,
        categoryList:[],
        categoryListTotal: 0,
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        dialogMode:'',
        detailOpen:false,
        detailTitle:'Detil Banner',
        detailContent:[],
        selectedRows:[],
      }
  }

  columns = [
    {
      name: 'Action',
      button: true,
      cell: row => {return <div>
        <IconButton color="primary" aria-label="detail" component="span" size="small" style={{marginRight:5}} onClick={()=>this.openSliderDetail(row)}>
            <List />
        </IconButton>
        <IconButton color="primary" aria-label="edit" component="span" size="small"  onClick={()=>this.editSlider(row)}>
            <Edit />
        </IconButton>
        <IconButton color="primary" aria-label="edit" component="span" size="small"  onClick={()=>this.nonActivate(row)}>
            {
              row.active == "Active" ?
              <Tooltip title="Non Aktifkan">
                <PowerOff />
              </Tooltip> :
              <Tooltip title="Aktifkan">
                <Power />
              </Tooltip>
            }
        </IconButton>
        {/* <List style={{ margin: '5px' }} color="action" /> */}
        {/* <Edit style={{ margin: '5px' }} color="action" /> */}
      </div>},
    },
    {
      name: <IntlMessages id="master.slider.title"/>,
      selector: 'title',
      sortable: true,
    },
    {
      name: <IntlMessages id="master.slider.catname"/>,
      selector: 'catname',
      sortable: true
    },
    {
      name: <IntlMessages id="master.slider.active"/>,
      selector: 'active',
      sortable: true,
      cell: row => {
        return (
          <Chip label={row.active == "Active" ? "Aktif" : "Tidak Aktif"} color={row.active == "Active" ? "secondary" : "default"}/> 
        )
      }
    },
  ];

  componentDidMount(){
    this.getSliderList();
    this.getCategoryList();
  }

  showDialog = (title,content,mode) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content,
      dialogMode:mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen:false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen:false
    })
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "expired content") {
      this.setState({
        dialogOpen:false
      });
      this.props.history.push("/logout");  
    } else {

      this.setState({
        dialogOpen:false
      });
      switch (this.state.dialogMode) {
        case "delete":
          this.handleDeleteSlider();
          break;
        case "nonactive":
          this.handleActivate();
          break;
        default:
          break;
      }
    }
  }

  getSliderList = () => {
    let outer = this;
    axios.post('bo/int/master/slider/list', outer.state.page).then(({data})=>{
      if(data.st && data.sc === 200){
        outer.setState({
          sliderList:data.data.list,
          sliderListTotal: data.data.total
        });
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  getCategoryList = () => {
    let outer = this;
    axios.post('bo/int/master/category/list', {
      keyword: '',
      page:1,
      count:50
    }).then(({data})=>{
      if(data.st && data.sc === 200){
        outer.setState({
          categoryList:data.data.list,
          categoryListTotal: data.data.total
        });
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  openSliderDetail = (row) => {
    let detailContent = [];
    Object.keys(row).forEach(function(key) {
      let name = '';
      let seq = 0;
      let view = true;
      let pict = false;
      let list = false;
      let url = urlConfig.urlBackendMenu;
      switch (key) {
        case 'active':
          name = <IntlMessages id="master.slider.active"/>;
          seq = 1;
          view = true;
          pict = false;
          list = false;
          break;
        case 'title':
          name = <IntlMessages id="master.slider.title"/>;
          seq = 2;
          view = true;
          pict = false;
          list = false;
          break;
        case 'description':
          name = <IntlMessages id="master.slider.description"/>;
          seq = 3;
          view = true;
          pict = false;
          list = false;
          break;
        case 'catname':
          name = <IntlMessages id="master.slider.catname"/>;
          seq = 4;
          view = true;
          pict = false;
          list = false;
          break;
        case 'pict':
          name = <IntlMessages id="master.slider.pict"/>;
          seq = 5;
          view = true;
          pict = true;
          url = urlConfig.urlBackendSlider;
          list = false;
          break;
        default:
          view = false;
          break;
      }
      let content = {id:key, name:name, value:row[key], seq: seq, view:view, pict: pict, url: url, list: list}
      detailContent.push(content);
    
    });
    detailContent.sort(compareSequence);
    this.setState({
      detailOpen:true,
      detailContent
    });

  }

  editSlider = (row) => {
    this.props.onAddParam({detail:row});
    this.props.history.push("/bo/master/slider/edit");
  }

  nonActivate = (row) => {
    this.setState({
      nonact: row
    }, () => {
      if(row.active == "Active") {
        this.showDialog("Non Aktif Banner", "Anda akan menon-aktifkan banner ini. Apakah Anda yakin ?", "nonactive");
      } else {
        this.showDialog("Aktifkan Banner", "Anda akan mengaktifkan banner ini. Apakah Anda yakin ?", "nonactive");
      }
    })
  }
 
  handleSelectedRows = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    // console.log('Selected Rows: ', state.selectedRows);
    this.setState({selectedRows:state.selectedRows});
  };

  deleteSlider = () => {
    let sliderLength = this.state.selectedRows.length;
    if(sliderLength == 0) {
      this.showDialog("Peringatan","Pilih salah satu item untuk menghapus","");  
    } else {
      this.showDialog("Hapus User","Anda akan menghapus "+ sliderLength+" banner. Apakah anda yakin?","delete");
    }
  }

  handleActivate = () => {
    
    const outer = this;
    const {nonact} = this.state;
    let slidecont = {
      id: nonact.id,
      active: nonact.active == "Active" ? "Inactive" : "Active"
    }
    this.setState({
      disableBtnSubmit:true
    }, () => {
      axios.post('bo/int/master/slider/active', slidecont
      ).then(({data}) => {
        if (data.st && data.sc === 200) {
          outer.setState({
            disableBtnSubmit:false
          }, () => {
            outer.getSliderList();
            outer.showDialog('Information',<IntlMessages id="master.slider.success.activateslider"/>,"");
          })
          
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',data.msg,"");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
      });
    });
  }
   
  handleDeleteSlider = () => {
    
    const outer = this;
    const {selectedRows} = this.state;
    let deleteSlider = [];
    selectedRows.forEach((menu)=>{
      deleteSlider.push(menu.id);
    })
    
    this.setState({
      disableBtnSubmit:true
    }, () => {
      axios.post('bo/int/master/slider/delete', {id:deleteSlider}
      ).then(({data}) => {
        if (data.st && data.sc === 200) {
          outer.setState({
            disableBtnSubmit:false
          }, () => {
            outer.getSliderList();
            outer.showDialog('Information',<IntlMessages id="master.menu.success.deletemenu"/>,"");
          })
          
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',data.msg,"");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
      });
    });
  }

  search(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        keyword: val.target.value
      }
    }, () => {
      outer.getSliderList();
    })
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        keyword: "",
        count: val
      }
    }, () => {
      outer.getSliderList();
    })
  }

  onChangePage(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val,
        keyword: "",
      }
    }, () => {
      outer.getSliderList();
    })
  }

  handleChangeCat = (e) => {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        catname: e.target.value
      }
    }, () => {
      outer.getSliderList();
    })
  }

  handleChangeActive = (e) => {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        active: e.target.value
      }
    }, () => {
      outer.getSliderList();
    })
  }
   
  render() {
    const { classes, container, theme, content, title } = this.props;
    const { sliderList} = this.state;
    const Comp = (row) => {
      return (
        <div style={{paddingTop: 10, paddingBottom: 10}}>

          <img width={200} src={urlConfig.urlBackendSlider + row.data.imgpath}/>
        </div>
      )
    };
      
    return (
      <div style={{marginTop: theme.spacing(4), height: '100%'}}>
         <DataTable
          title="Master Banner"
          columns={this.columns}
          data={sliderList}
          noDataComponent={
            <Card style={{maxWidth: 200, borderWidth: 1, borderStyle: 'solid'}}>
              <CardMedia style={{textAlign: 'center'}}>
                <MoodBad color="action" style={{
                  fontSize: 40, 
                  marginTop: 10, 
                  marginBottom: 10
                }}/>
              </CardMedia>
              <CardContent>
                <Typography>
                  No data available
                </Typography>
              </CardContent>
            </Card>
            
          }
          selectableRows
          selectableRowsComponent={Checkbox}
          expandableRows
          expandableRowsComponent={<Comp/>}
          highlightOnHover
          subHeader
          customStyles={customStyles}
          onSelectedRowsChange={this.handleSelectedRows}
          pagination
          paginationServer
          paginationTotalRows={this.state.sliderListTotal}
          paginationPerPage={this.state.page.count}
          onChangePage={(val) => this.onChangePage(val)}
          onChangeRowsPerPage={(val) => this.onChangeRows(val)}
          subHeaderComponent={
            (
              <div>
                <div>
                  <FormControl
                    style={{
                      minWidth: '100%',
                      marginBottom: 5
                    }}
                  >
                    <InputLabel style={{fontSize: 12}} id="formcat"
                            variant="outlined" style={{backgroundColor:'#fff'}}>
                      <IntlMessages id="master.slider.catname"/>
                    </InputLabel>
                    <Select
                      value={this.state.page.catname}
                      onChange={this.handleChangeCat}
                      labelId={"formcat"}
                      variant="outlined"
                      style={{width: '100%'}}
                    >
                      <MenuItem value="">Tampilkan semua</MenuItem>
                      <MenuItem value="Home">Beranda</MenuItem>
                      {/* <MenuItem value="Cuci Gudang">Cuci Gudang</MenuItem>
                      <MenuItem value="Last Stock">Last Stock</MenuItem>
                      <MenuItem value="Rupa-rupa">Rupa-rupa</MenuItem>
                      <MenuItem value="Free Item">Free Item</MenuItem> */}
                      {this.state.categoryList.length > 0 && this.state.categoryList.map((category)=>{
                        return <MenuItem value={category.name}>{category.name}</MenuItem>
                      })}

                    </Select>
                  </FormControl>
                </div>
                <div>
                  <FormControl
                    style={{
                      minWidth: '100%',
                      marginBottom: 5
                    }}
                  >
                    <InputLabel style={{fontSize: 12}} id="formact"
                            variant="outlined" style={{backgroundColor:'#fff'}}>
                      <IntlMessages id="master.slider.active"/>
                    </InputLabel>
                    <Select
                      value={this.state.page.active}
                      onChange={this.handleChangeActive}
                      labelId={"formcat"}
                      variant="outlined"
                      style={{width: '100%'}}
                    >
                      <MenuItem value="">Tampilkan Semua</MenuItem>
                      <MenuItem value="Active">Ya</MenuItem>
                      <MenuItem value="Inactive">Tidak</MenuItem>

                    </Select>
                  </FormControl>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  
                  <TextField 
                    id="outlined-basic" 
                    label="Search" 
                    variant="outlined" 
                    size="small" 
                    style={{ margin: '5px' }}
                    onChange={(val) => this.search(val)} 
                  />
                  <IconButton 
                    color="primary" 
                    aria-label="detail" 
                    component="span" 
                    onClick={()=>{this.props.onDeselectParam();this.props.history.push("/bo/master/slider/edit")}} 
                  >
                      <Add />
                  </IconButton>
                  <IconButton 
                    color="primary" 
                    aria-label="detail" 
                    component="span" 
                    onClick={()=>{this.deleteSlider()}} 
                  >
                      <Remove />
                  </IconButton>
                </div>
              </div>
            )
          }
        />

          <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            mode={this.state.dialogMode}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
          />  
          <DialogDetail
            open={this.state.detailOpen} 
            title={this.state.detailTitle}
            content={this.state.detailContent}
            handleClose={this.handleDetailClose}
            handleOk={this.handleDetailClose}
            />  
      </div>
    );
  }
}

DbMasterSlider.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param)),
    onDeselectParam: () => dispatch(deselectParam()),
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbMasterSlider)));