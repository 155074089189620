import React from 'react';
import { 
  Button, CssBaseline, TextField, FormControlLabel, Grid,
  Box, Typography, Container 
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {ValidateEmail} from '../utility/validation';
import Setting from '../config/setting';
import Copyright from '../component/copyright';
// import { loadReCaptcha } from 'react-recaptcha-v3'
import Headerfront from '../component/headerfront'
import DialogBasic from '../component/dialogbasic';
import { connect } from "react-redux";
import axios from '../config/axios';
import { Redirect, Link } from "react-router-dom";
import Errormsg from "../config/errormsg";
import IntlMessages from "../util/IntlMessages";

//import actions
import { addToken } from "../store/actions/configAction";

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

class ForgotPassword extends React.Component {
  _isMounted = false;
  constructor(props){
      super(props);
      this.state = {
        form: {
          email:"",
        },
        error:{
          email:"",
        },
        // reloadRecaptcha:new Date(),
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        isSubmitSuccess:false,
        toDashboard: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const outer = this;
    // loadReCaptcha(Setting["recaptcha-site-key"]);
    // this.intervalId = setInterval(function(){ 
    //   outer.reloadRecaptcha();
    // }, Setting["recaptcha-interval"]);
  }

  componentWillUnmount(){
    this._isMounted = false;
    clearInterval(this.intervalId);
  }

  handleDialogClose = () => {
    const outer = this;
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    const outer = this;
    this.setState({dialogOpen:false});
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  // reloadRecaptcha =() => {
  //   if (this._isMounted) {
  //     this.setState({
  //       reloadRecaptcha:new Date()
  //     });
  //   }
  // }

  handleChange = (e) => {
    let outer=this;
    let form = this.state.form;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;

    this.setState({
      form:form
    }, () => {
      switch(inputName){
        case "email":
          if(inputValue){
            form[inputName]=inputValue.toLowerCase();
            outer.setState({form:form});
          }
        break; 
        default:
        break;
      }
    });
  }

  handleValidation = (e) => {
    let {form,error} = this.state;

    if(form.email.trim().length === 0){
      error.email="Alamat email harus diinput";
    }else{
      error.email="";
    }

    
    this.setState({
      error:error
    }, () => {

      if(!this.isValidForm()){
        return;
      }
  
      let validEmail = ValidateEmail(form.email.trim());
      if(!validEmail){
          error.email="Format alamat email tidak benar";   
      }else{
          error.email="";  
      }
  
      this.setState({
        error:error
      }, () => {
        if(!this.isValidForm()){
          return;
        }

        this.handleSubmit();

      });
  
    });
  }

  handleSubmit = (e) => {
    let {form} = this.state;
    const outer = this;
    this.setState({
      disableBtnSubmit:true
    }, () => {
      // this.props.onAddToken({token:"login token"});
      // outer.setState({toDashboard:true})
      axios.post('bo/int/forgotpass', form
      ).then(({data}) => {
        if (data.st && data.sc === 200) {
          outer.showDialog("Success", data.msg);
          this.props.history.push('/forgotpasswordconfirm')
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          // outer.reloadRecaptcha();
          outer.showDialog('Warning',data.msg);
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        // outer.reloadRecaptcha();
        outer.showDialog('Warning',Errormsg["500"]);
      });

    });

  }

  isValidForm = () => {
    return !this.state.error.email &&     
    !this.state.error.pwd &&
    !this.state.error.global 
  }

  // verifyCallback = (recaptchaToken) => {
  //   let {form} = this.state;
  //   form.token=recaptchaToken;
  //   this.setState({form:form})
  //   // console.log(recaptchaToken);
  // }

  render(){
    const { classes, token } = this.props;
    const { error,form } = this.state;

      return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Headerfront />
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="email"
                    name="email"
                    label={<IntlMessages id="emailaddress"/>}
                    value={this.state.form.email} 
                    variant="outlined"
                    required
                    fullWidth
                    onChange={this.handleChange} 
                    error={error.email?true:false}
                  />
                    {
                      error.email &&   
                        <FormControlLabel
                          classes={{label:classes.labelerror}}
                          control={<div>&nbsp;&nbsp;&nbsp;</div>}
                          label={error.email}
                        />
                    }
                </Grid>

                

              </Grid>
              <Button
                type="button"
                className={classes.submit}
                color="secondary"
                variant="contained"
                fullWidth
                onClick={this.handleValidation}
                disabled={this.state.disableBtnSubmit}
              >
                submit
              </Button>
              <Grid container justify="flex-end" className={classes.konfirmasi}>
                <Grid item>
                  <Link to="/" variant="body2">
                    Kembali ke login
                  </Link>
                </Grid>
              </Grid>
              <Grid container justify="flex-end" className={classes.konfirmasi}>
                <Grid item>
                  <Link to="/forgotpasswordconfirm" variant="body2">
                    Konfirmasi Lupa Password
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
          <Box mt={5} className={classes.copyright}>
            <Copyright />
          </Box>
            {/* <ReCaptcha
                sitekey={Setting["recaptcha-site-key"]}
                action='login'
                verifyCallback={this.verifyCallback}
                key={this.state.reloadRecaptcha} 
            /> */}
            <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />
        </Container>
      );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    token: state.token
  };
};

const dispatchToProps = dispatch => {
  return {
    onAddToken: token => dispatch(addToken(token))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(ForgotPassword));