import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Checkbox, Button, Select, InputLabel,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import { ValidateEmail } from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";
import urlConfig from "./../../../../../config/backend";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername: {
    color: '#204196',
    fontWeight: 'bold'
  },
  labelerror: {
    color: '#ff1744',
    fontSize: 10,
    paddingTop: '5px'
  },
  konfirmasi: {
    paddingTop: '10px'
  },
  copyright: {
    paddingBottom: '10px'
  }
});

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

class DbMasterSliderEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewSlider: '',
      form: {
        id: "",
        title: "",
        description: "",
        catname: "",
        pict: null
      },
      error: {
        id: "",
        title: "",
        catname: "",
        pict: ""
      },
      page:{
        keyword: '',
        page:1,
        count:50
      },
      categoryList: [],
      categoryListTotal: 0,
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      disableBtnSubmit: false,
      mode: 'add',
      alowPeriod: []
    };
  }

  componentDidMount() {
    let outer = this;
    this.getCategoryList();
    if (this.props.param != null) {
      let detail = this.props.param.detail;
      this.setState({
        form: {
          ...this.state.form,
          id: detail.id,
          title: detail.title,
          description: detail.description,
          catname: detail.catname
        },
        previewSlider: detail.imgpath != "" ?
          urlConfig.urlBackendSlider + detail.imgpath : "",
        mode: 'edit'
      })
    }
  }

  getCategoryList = () => {
    let outer = this;
    axios.post('bo/int/master/category/list', outer.state.page).then(({data})=>{
      if(data.st && data.sc === 200){
        outer.setState({
          categoryList:data.data.list,
          categoryListTotal: data.data.total
        });
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir. Silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      this.setState({ dialogOpen: false });
    }
  }

  showDialog = (title, content) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content
    });
  }

  handleChange = (e) => {
    let outer = this;
    let { form, error } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if (inputName === "accept") {
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if (newAccept === true) {
        error[inputName] = ""
      }
    }

    this.setState({
      form: form,
      error: error
    });
  }

  handleValidation = (e) => {
    let { form, error, mode } = this.state;

    if (form.title.trim().length === 0) {
      error.title = <IntlMessages id="master.slider.error.title" />;
    } else {
      error.title = "";
    }

    if (form.catname.trim().length === 0) {
      error.catname = <IntlMessages id="master.slider.error.catname" />;
    } else {
      error.catname = "";
    }

    if (form.pict == null && this.state.mode == "add") {
      error.pict = <IntlMessages id="master.slider.error.pict" />;
    } else {
      error.pict = "";
    }

    if (!this.isValidForm()) {
      form.accept = false;
      error.accept = "";
    }

    this.setState({
      form: form,
      error: error
    }, () => {

      if (!this.isValidForm()) {
        return;
      }

      this.handleSubmit();

    });

  }

  handleSubmit = () => {
    let { form } = this.state;
    const formData = new FormData();
    Object.entries(form).map(([key, value]) => {
      if (value != undefined) {
        switch (key) {
          default:
            formData.append(key, value)
            break;
        }
      } else {
        formData.append(key, '')
      }
    });


    if (this.state.mode == 'add') {

      const outer = this;
      this.setState({
        disableBtnSubmit: true
      }, () => {
        axios.post('bo/int/master/slider/add', formData
        ).then(({ data }) => {
          if (data.st && data.sc === 200) {
            outer.showDialog('Information', <IntlMessages id="master.slider.success.addslider" />);
            setTimeout(function () {
              outer.props.history.push("/bo/master/slider/list");
            }, 1500);
          } else {
            outer.setState({
              disableBtnSubmit: false
            });
            outer.showDialog('Warning', data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', Errormsg["500"]);
        });
      });

    } else {
      const outer = this;
      this.setState({
        disableBtnSubmit: true
      }, () => {
        axios.post('bo/int/master/slider/edit', formData
        ).then(({ data }) => {
          if (data.st && data.sc === 200) {
            outer.showDialog('Information', <IntlMessages id="master.slider.success.updateslider" />);
            setTimeout(function () {
              outer.props.deselectParam();
              outer.props.history.push("/bo/master/slider/list");
            }, 1500);
          } else {
            outer.setState({
              disableBtnSubmit: false
            });
            outer.showDialog('Warning', data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', Errormsg["500"]);
        });
      });
    }
  }

  isValidForm = () => {
    return (
      !this.state.error.title &&
      !this.state.error.catname &&
      !this.state.error.pict)
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        form: {
          ...this.state.form,
          pict: file
        },
        previewSlider: reader.result
      });
    }

    reader.readAsDataURL(file)
  }


  handleChangeCategory = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        catname: e.target.value
      }
    })
  }


  handleChangeDay = day => e => {
    this.setState({
      form: {
        ...this.state.form,
        [day]: e.target.checked == true ? "true" : "false"
      }
    });
  }


  render() {
    const { classes, theme } = this.props;
    const { error, alowPeriod } = this.state;
    const title = "Profile"
    return (
      <div style={{ marginTop: theme.spacing(4) }}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit">
              Master
            </Link>
            <Link color="inherit" href="/bo/master/slider/list">
              Daftar Banner
            </Link>
            <Link color="textPrimary" href="/bo/master/slider/edit">
              {
                this.state.mode == "add" ?
                  "Tambah Banner" : "Ubah Banner"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
            <form className={classes.form} noValidate>


              <Grid container spacing={2}>


                <Grid item xs={12}>
                  <TextField
                    id="title"
                    name="title"
                    label={<IntlMessages id="master.slider.title" />}
                    value={this.state.form.title}
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.title ? true : false}
                  />
                  {
                    error.title &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.title}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="description"
                    name="description"
                    label={<IntlMessages id="master.slider.description" />}
                    value={this.state.form.description}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    style={{
                      minWidth: '100%',
                      marginBottom: 5
                    }}
                  >
                  <InputLabel style={{ fontSize: 12 }} id="formcat"
                            variant="outlined" style={{backgroundColor:'#fff'}}>
                    <IntlMessages id="master.slider.catname" />*
                        </InputLabel>
                  <Select
                    value={this.state.form.catname}
                    onChange={this.handleChangeCategory}
                    labelId={"formcat"}
                    fullWidth
                    variant="outlined"
                    error={error.catname ? true : false}
                  >
                    <MenuItem value="Home">Beranda</MenuItem>
                    {/* <MenuItem value="Cuci Gudang">Cuci Gudang</MenuItem>
                    <MenuItem value="Last Stock">Last Stock</MenuItem>
                    <MenuItem value="Rupa-rupa">Rupa-rupa</MenuItem>
                    <MenuItem value="Free Item">Free Item</MenuItem> */}
                    {this.state.categoryList.length > 0 && this.state.categoryList.map((category)=>{
                      return <MenuItem value={category.name}>{category.name}</MenuItem>
                    })}
                  </Select>
                  {
                    error.catname &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.catname}
                    />
                  }
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <InputLabel style={{ fontSize: 12 }}>
                    Gambar Menu*
                      </InputLabel>
                  <img
                    src={this.state.previewSlider}
                    style={{ width: 100, marginBottom: 10 }}
                  />
                  <br />
                  <input
                    type="file"
                    onChange={(e) => this.handleImageChange(e)}
                  />
                  <br />
                  {
                    error.pict && this.state.mode == "add" &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.pict}
                    />
                  }
                </Grid>

              </Grid>
              <Button
                type="button"
                className={classes.submit}
                color="secondary"
                variant="contained"
                fullWidth
                onClick={this.handleValidation}
                disabled={this.state.disableBtnSubmit}
              >
                SUBMIT
                  </Button>
            </form>
          </div>
        </Container>

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
      </div>
    );
  }
}

DbMasterSliderEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, { deselectParam }
)(withStyles(styles)(withTheme(DbMasterSliderEdit)));