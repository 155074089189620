import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {InputLabel, Select, MenuItem, Card,  
  CardMedia ,CardContent, Typography} from '@material-ui/core';
import {MoodBad} from '@material-ui/icons';
import axios from '../../../../../config/axios';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { connect } from "react-redux";
import moment from 'moment';

import IntlMessages from "../../../../../util/IntlMessages";
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import DataTable from 'react-data-table-component';

//import actions
import { addParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});

function numberWithCommas(x) {
  var parts = x.toString().split(",");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
}

class DbReportSell extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        page:{
          type: '',
          datestr: new Date(),
          date: moment().format('YYYY-MM-DD')
        },
        sellList:[],
        sellListTotal: 0,
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        dialogMode:'',
        detailOpen:false,
      }
  }

  columns = [
    {
      name: <IntlMessages id="report.sell.menuname"/>,
      selector: 'menuname',
      sortable: true,
    },
    {
      name: <IntlMessages id="report.sell.price"/>,
      selector: 'price',
      sortable: true,
      format: row => numberWithCommas(row.price),
      hide: 'sm'

    },
    {
      name: <IntlMessages id="report.sell.discount"/>,
      selector: 'discount',
      sortable: true,
      format: row => numberWithCommas(row.discount),
      hide: 'sm'
    },
    {
      name: <IntlMessages id="report.sell.qty"/>,
      selector: 'qty',
      sortable: true,
      hide: 'sm'
    },
    {
      name: <IntlMessages id="report.sell.subtotal"/>,
      selector: 'subtotal',
      sortable: true,
      format: row => numberWithCommas(row.subtotal)
    }
  ];

  componentDidMount(){
    this.getSellReport();
  }

  showDialog = (title,content,mode) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content,
      dialogMode:mode
    });
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen:false
    })
  }

  getSellReport = () => {
    let outer = this;
    axios.post('bo/int/report/sell', outer.state.page).then(({data})=>{
      if(data.st && data.sc === 200){
        outer.setState({
          sellList:data.data.menulist,
          sellListTotal: data.data.grandtotal
        });
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  onchangeType = (e) => {
    this.setState({
      page: {
        ...this.state.page,
        type: e.target.value
      }
    }, () => {
      this.getSellReport();
    })
  }

  onchangeDate = (e) => {
    this.setState({
      page: {
        ...this.state.page,
        datestr: e,
        date: e ? moment(e).format('YYYY-MM-DD') : ''
      }
    }, () => {
      this.getSellReport();
    })
  }
  
  render() {
    const { classes, container, theme, content, title } = this.props;
    const { sellList, sellListTotal} = this.state;
    
      
    return (
      <div style={{marginTop: theme.spacing(4), height: '100%'}}>
         <DataTable
          title="Laporan Penjualan"
          columns={this.columns}
          data={sellList}
          noDataComponent={
            <Card style={{maxWidth: 200, borderWidth: 1, borderStyle: 'solid'}}>
              <CardMedia style={{textAlign: 'center'}}>
                <MoodBad color="action" style={{
                  fontSize: 40, 
                  marginTop: 10, 
                  marginBottom: 10
                }}/>
              </CardMedia>
              <CardContent>
                <Typography>
                  No data available
                </Typography>
              </CardContent>
            </Card>
            
          }
          highlightOnHover
          subHeader
          customStyles={customStyles}
          subHeaderComponent={
            (
              <div>
                <div>
                  <InputLabel style={{fontSize: 12}} id="formtype">
                    Tipe Laporan
                  </InputLabel>
                  <Select
                    value={this.state.page.type}
                    onChange={this.onchangeType}
                    labelId={"formtype"}
                    fullWidth
                  >
                    <MenuItem value="day">Harian</MenuItem>
                    <MenuItem value="week">Mingguan</MenuItem>
                    <MenuItem value="month">Bulanan</MenuItem>

                  </Select>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  
                  <KeyboardDatePicker
                    clearable
                    value={this.state.page.datestr}
                    placeholder="10-10-2018"
                    onChange={date => this.onchangeDate(date)}
                    format="DD-MM-YYYY"
                  />
                </div>
                <h1>Grand Total : Rp. {numberWithCommas(sellListTotal)}</h1>
              </div>

            )
          }
        />  
      </div>
    );
  }
}

DbReportSell.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbReportSell)));