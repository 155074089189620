import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {
  IconButton, TextField, Checkbox, Card, FormControl,
  CardMedia, CardContent, Typography, InputLabel, Select, MenuItem
} from '@material-ui/core';
import { MoodBad } from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import { compareSequence } from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';
import IntlMessages from "../../../../../util/IntlMessages";
import urlConfig from "./../../../../../config/backend";

import DataTable from 'react-data-table-component';

//import actions
import { addParam, deselectParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete: {
    color: '#204196',
    fontWeight: 'bold'
  },
  incomplete: {
    color: '#ff1744',
    fontWeight: 'bold'
  }
});

function numberWithCommas(x) {
  var parts = x.toString().split(",");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
}


class DbMasterItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {
        keyword: '',
        page: 1,
        count: 10
      },
      itemList: [],
      itemListTotal: 0,
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      dialogMode: '',
      detailOpen: false,
      detailTitle: 'Detail Item',
      detailContent: [],
      selectedRows: [],
    }
  }

  columns = [
    {
      name: 'Action',
      button: true,
      cell: row => {
        return <div>
          <IconButton color="primary" aria-label="detail" component="span" size="small" style={{ marginRight: 5 }} onClick={() => this.openUserDetail(row)}>
            <List />
          </IconButton>
          <IconButton color="primary" aria-label="edit" component="span" size="small" onClick={() => this.editUser(row)}>
            <Edit />
          </IconButton>
          {/* <List style={{ margin: '5px' }} color="action" /> */}
          {/* <Edit style={{ margin: '5px' }} color="action" /> */}
        </div>
      },
    },
    {
      name: <IntlMessages id="master.item.name" />,
      selector: 'name',
      sortable: true,
    },
    {
      name: <IntlMessages id="master.item.total" />,
      selector: 'total',
      sortable: true,
      format: row => (row.hideprice == 'true') ? 'Disembunyikan' : numberWithCommas(row.total)
      // hide: 'sm',
    },
  ];

  componentDidMount() {
    this.getItemList();
  }

  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen: false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    })
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir. Silahkan login kembali.") {
      this.setState({
        dialogOpen: false
      });
      this.props.history.push("/logout");
    } else {

      this.setState({
        dialogOpen: false
      });
      switch (this.state.dialogMode) {
        case "delete":
          this.handleDeleteItem();
          break;

        default:
          break;
      }
    }
  }

  getItemList = () => {
    let outer = this;
    axios.post('bo/int/master/item/list', outer.state.page).then(({ data }) => {
      if (data.st && data.sc === 200) {
        data.data.list.forEach((item) => {
          item.pricestr = numberWithCommas(item.price);
          item.discstr = numberWithCommas(item.discount);
          item.totalstr = numberWithCommas(item.total);
        });
        outer.setState({
          itemList: data.data.list,
          itemListTotal: data.data.total
        });
      }
    }).catch(function (error) {
      outer.setState({
        disableBtnSubmit: false
      });
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  openUserDetail = (row) => {
    // console.log(row)
    let detailContent = [];
    Object.keys(row).forEach(function (key) {
      let name = '';
      let seq = 0;
      let view = true;
      let pict = false;
      let url = urlConfig.urlBackendItem;
      switch (key) {
        case 'name':
          name = <IntlMessages id="master.item.name" />;
          seq = 1;
          view = true;
          pict = false;
          break;
        case 'description':
          name = <IntlMessages id="master.item.description" />;
          seq = 2;
          view = true;
          pict = false;
          break;
        case 'pricestr':
          name = <IntlMessages id="master.item.price" />;
          seq = 3;
          view = true;
          pict = false;
          break;
        case 'discstr':
          name = <IntlMessages id="master.item.discount" />;
          seq = 4;
          view = true;
          pict = false;
          break;
        case 'totalstr':
          name = <IntlMessages id="master.item.total" />;
          seq = 5;
          view = true;
          pict = false;
          break;
        case 'catname':
          name = <IntlMessages id="master.item.category" />;
          seq = 6;
          view = true;
          pict = false;
          break;
        case 'available':
          name = <IntlMessages id="master.item.avl" />;
          seq = 7;
          view = true;
          pict = false;
          break;
        case 'special':
          name = <IntlMessages id="master.item.spc" />;
          seq = 8;
          view = true;
          pict = false;
          break;
        case 'imgpath':
          name = <IntlMessages id="master.item.imgpath" />;
          seq = 9;
          view = true;
          pict = true;
          url = urlConfig.urlBackendItem;
          break;
        default:
          view = false;
          break;
      }

      let content = { id: key, name: name, value: row[key], seq: seq, view: view, pict: pict, url: url }
      detailContent.push(content);
      
    });
    detailContent.sort(compareSequence);
    // console.log(detailContent);
    this.setState({
      detailOpen: true,
      detailContent
    });

  }

  editUser = (row) => {
    this.props.onAddParam({ detail: row });
    this.props.history.push("/bo/master/item/edit");
  }

  handleSelectedRows = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    // console.log('Selected Rows: ', state.selectedRows);
    this.setState({ selectedRows: state.selectedRows });
  };

  deleteMenu = () => {
    let itemLength = this.state.selectedRows.length;
    if (itemLength == 0) {
      this.showDialog("Peringatan", "Pilih salah satu item untuk menghapus", "");
    } else {
      this.showDialog("Hapus Item", "Anda akan menghapus " + itemLength + " item. Apakah anda yakin?", "delete");
    }
  }

  handleDeleteItem = () => {

    const outer = this;
    const { selectedRows } = this.state;
    let deleteItem = [];
    selectedRows.forEach((item) => {
      deleteItem.push(item.id);
    });

    this.setState({
      disableBtnSubmit: true
    }, () => {
      axios.post('bo/int/master/item/delete', { id: deleteItem }
      ).then(({ data }) => {
        if (data.st && data.sc === 200) {
          outer.setState({
            disableBtnSubmit: false
          }, () => {
            outer.getItemList();
            outer.showDialog('Information', <IntlMessages id="master.item.success.deleteitem" />, "");
          })

        } else {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', data.msg, "");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit: false
        });
        outer.showDialog('Warning', Errormsg["500"], "");
      });
    });
  }

  search(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        keyword: val.target.value
      }
    }, () => {
      outer.getItemList();
    })
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        keyword: "",
        count: val
      }
    }, () => {
      outer.getItemList();
    })
  }

  onChangePage = (val) => {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val,
        keyword: "",
      }
    }, () => {
      outer.getItemList();
    })
  }

  render() {
    const { classes, container, theme, content, title } = this.props;
    const { itemList } = this.state;
    const Comp = (row) => {

      let listImg = [];
      row.data.listimg.forEach(elem => {
          let img =  <div key={row.data.id+'_img_'+elem.seq} style={{ padding:10,width:150,display: 'flex'}}>
            <img width={125} src={elem.imgpath ? urlConfig.urlBackendItem + elem.imgpath : require("../../../../../assets/default_item.png")} />
          </div>
          listImg.push(img);
      });

      let wrapper = (listImg.length > 0) ? (
        <div>
          <h4>Gambar Tambahan</h4>
          <div style={{ display:'flex',flexDirection:'row'}}>
            {listImg}
          </div>
        </div>
      ):(
        <span/>
      )
      
      return (
        <div>
          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <h4>Gambar Utama</h4>
            <img width={200} src={row.data.imgpath ? urlConfig.urlBackendItem + row.data.imgpath : require("../../../../../assets/default_item.png")} />
          </div>
          {wrapper}
        </div>
      )
    };

    return (
      <div style={{ marginTop: theme.spacing(4), height: '100%' }}>
        <DataTable
          title="Master Item"
          columns={this.columns}
          data={itemList}
          noDataComponent={
            <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
              <CardMedia style={{ textAlign: 'center' }}>
                <MoodBad color="action" style={{
                  fontSize: 40,
                  marginTop: 10,
                  marginBottom: 10
                }} />
              </CardMedia>
              <CardContent>
                <Typography>
                  No data available
                </Typography>
              </CardContent>
            </Card>

          }
          selectableRows
          selectableRowsComponent={Checkbox}
          expandableRows
          expandableRowsComponent={<Comp />}
          highlightOnHover
          subHeader
          customStyles={customStyles}
          onSelectedRowsChange={this.handleSelectedRows}
          pagination
          paginationServer
          paginationTotalRows={this.state.itemListTotal}
          paginationPerPage={this.state.page.count}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={(val) => this.onChangeRows(val)}
          subHeaderComponent={
            (
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>

                  <TextField
                    id="outlined-basic"
                    label="Search"
                    variant="outlined"
                    size="small"
                    style={{ margin: '5px' }}
                    onChange={(val) => this.search(val)}
                  />
                  <IconButton
                    color="primary"
                    aria-label="detail"
                    component="span"
                    onClick={() => { this.props.onDeselectParam(); this.props.history.push("/bo/master/item/edit") }}
                  >
                    <Add />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="detail"
                    component="span"
                    onClick={() => { this.deleteMenu() }}
                  >
                    <Remove />
                  </IconButton>
                </div>
              </div>
            )
          }
        />

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          mode={this.state.dialogMode}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
        <DialogDetail
          open={this.state.detailOpen}
          title={this.state.detailTitle}
          content={this.state.detailContent}
          handleClose={this.handleDetailClose}
          handleOk={this.handleDetailClose}
        />
      </div>
    );
  }
}

DbMasterItem.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param)),
    onDeselectParam: () => dispatch(deselectParam()),
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbMasterItem)));