import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {IconButton, TextField, Checkbox, Card, FormControl, Chip, Tooltip,
  CardMedia ,CardContent, Typography, InputLabel, Select, MenuItem, FormControlLabel} from '@material-ui/core';
import {MoodBad, Power, PowerOff, ArrowUpward, ArrowDownward} from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import {compareSequence} from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';
import IntlMessages from "../../../../../util/IntlMessages";
import urlConfig from "./../../../../../config/backend";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DataTable from 'react-data-table-component';

//import actions
import { addParam,deselectParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});

function numberWithCommas(x) {
  var parts = x.toString().split(",");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
}


class DbMasterCategories extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        page:{
          keyword: '',
          page:1,
          count:10
        },
        nonact: {},
        contactList:[],
        contactListTotal: 0,
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        dialogMode:'',
        detailOpen:false,
        detailTitle:'Detil Kategori',
        sortMode:false,
        rerender:0,
        detailContent:[],
        selectedRows:[],
      }
  }

  columns = [
    {
      name: 'Action',
      button: true,
      cell: row => {return <div>
        {(!this.state.sortMode)&&
        <IconButton color="primary" aria-label="edit" component="span" size="small"  onClick={()=>this.editContact(row)}>
            <Edit />
        </IconButton>
        }
        {(this.state.sortMode)&&
          <IconButton disabled={row.seq == 1} color="primary" aria-label="edit" component="span" size="small"  onClick={()=>this.handleMoveUp(row)}>
              <ArrowUpward />
          </IconButton>
        }
        {(this.state.sortMode)&&
          <IconButton disabled={row.seq == this.state.contactListTotal} color="primary" aria-label="edit" component="span" size="small"  onClick={()=>this.handleMoveDown(row)}>
              <ArrowDownward />
          </IconButton>
        }
        {/* <List style={{ margin: '5px' }} color="action" /> */}
        {/* <Edit style={{ margin: '5px' }} color="action" /> */}
      </div>},
    },
    {
      name: <IntlMessages id="master.contact.type"/>,
      selector: 'type',
      sortable: true,
    },
    {
      name: <IntlMessages id="master.contact.contact"/>,
      selector: 'value',
      sortable: true
    },
  ];

  componentDidMount(){
    this.getContactList();
  }

  showDialog = (title,content,mode) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content,
      dialogMode:mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen:false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen:false
    })
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir. Silahkan login kembali.") {

      this.setState({
        dialogOpen:false
      });
      this.props.history.push("/logout");
    } else {
      this.setState({
        dialogOpen:false
      });
      switch (this.state.dialogMode) {
        case "delete":
          this.handleDeleteContact();
          break;
        default:
          break;
      }
    }
  }

  getContactList = () => {
    let outer = this;
    axios.post('bo/int/master/contact/list', outer.state.page).then(({data})=>{
      if(data.st && data.sc === 200){
        outer.setState({
          contactList:data.data.list,
          contactListTotal: data.data.total
        });
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  
  handleMoveUp = (row) => {
    
    const outer = this;    
    this.setState({
      disableBtnSubmit:true
    }, () => {
      axios.post('bo/int/master/contact/up', {id:row.id}
      ).then(({data}) => {
        if (data.st && data.sc === 200) {
          outer.setState({
            disableBtnSubmit:false
          }, () => {
            outer.getContactList();
            // outer.showDialog('Information',<IntlMessages id="master.servicetype.success.deleteservice"/>,"");
          })
          
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',data.msg,"");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
      });
    });
  }

  handleMoveDown = (row) => {
    
    const outer = this;    
    this.setState({
      disableBtnSubmit:true
    }, () => {
      axios.post('bo/int/master/contact/down', {id:row.id}
      ).then(({data}) => {
        if (data.st && data.sc === 200) {
          outer.setState({
            disableBtnSubmit:false
          }, () => {
            outer.getContactList();
            // outer.showDialog('Information',<IntlMessages id="master.servicetype.success.deleteservice"/>,"");
          })
          
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',data.msg,"");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
      });
    });
  }

  editContact = (row) => {
    this.props.onAddParam({detail:row});
    this.props.history.push("/bo/contact/edit");
  }
 
  handleSelectedRows = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    // console.log('Selected Rows: ', state.selectedRows);
    this.setState({selectedRows:state.selectedRows});
  };

  deleteContact = () => {
    let catLength = this.state.selectedRows.length;
    if(catLength == 0) {
      this.showDialog("Peringatan","Pilih salah satu item untuk menghapus","");  
    } else {
      this.showDialog("Hapus Kontak","Anda akan menghapus "+ catLength+" kontak. Apakah anda yakin?","delete");
    }
  }

  handleDeleteContact = () => {
    
    const outer = this;
    const {selectedRows} = this.state;
    let deleteCat = [];
    selectedRows.forEach((cat)=>{
      deleteCat.push(cat.id);
    })
    
    this.setState({
      disableBtnSubmit:true
    }, () => {
      axios.post('bo/int/master/contact/delete', {id:deleteCat}
      ).then(({data}) => {
        if (data.st && data.sc === 200) {
          outer.setState({
            disableBtnSubmit:false
          }, () => {
            outer.getContactList()
            outer.showDialog('Information',<IntlMessages id="master.contact.success.delete"/>,"");
          })
          
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',data.msg,"");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
      });
    });
  }

  search(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        keyword: val.target.value
      }
    }, () => {
      outer.getContactList();
    })
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        keyword: "",
        count: val
      }
    }, () => {
      outer.getContactList();
    })
  }

  onChangePage(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val,
        keyword: "",
      }
    }, () => {
      outer.getContactList();
    })
  }
   
  render() {
    const { classes, container, theme, content, title } = this.props;
    const { contactList} = this.state;
      
    return (
      <div style={{marginTop: theme.spacing(4), height: '100%'}}>
         <DataTable
          title="Master Kontak"
          columns={this.columns}
          key={this.state.rerender}
          data={contactList}
          noDataComponent={
            <Card style={{maxWidth: 200, borderWidth: 1, borderStyle: 'solid'}}>
              <CardMedia style={{textAlign: 'center'}}>
                <MoodBad color="action" style={{
                  fontSize: 40, 
                  marginTop: 10, 
                  marginBottom: 10
                }}/>
              </CardMedia>
              <CardContent>
                <Typography>
                  No data available
                </Typography>
              </CardContent>
            </Card>
            
          }
          selectableRows
          selectableRowsComponent={Checkbox}
          highlightOnHover
          subHeader
          customStyles={customStyles}
          onSelectedRowsChange={this.handleSelectedRows}
          pagination
          paginationServer
          paginationTotalRows={this.state.contactListTotal}
          paginationPerPage={this.state.page.count}
          onChangePage={(val) => this.onChangePage(val)}
          onChangeRowsPerPage={(val) => this.onChangeRows(val)}
          subHeaderComponent={
            (
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  
                  <TextField 
                    id="outlined-basic" 
                    label="Search" 
                    variant="outlined" 
                    size="small" 
                    style={{ margin: '5px' }}
                    onChange={(val) => this.search(val)} 
                  />
                  <IconButton 
                    color="primary" 
                    aria-label="detail" 
                    component="span" 
                    onClick={()=>{this.props.onDeselectParam();this.props.history.push("/bo/contact/edit")}} 
                  >
                      <Add />
                  </IconButton>
                  <IconButton 
                    color="primary" 
                    aria-label="detail" 
                    component="span" 
                    onClick={()=>{this.deleteContact()}} 
                  >
                      <Remove />
                  </IconButton>
                </div>
                <div style={{ paddingLeft:10 }}>
                  <FormControlLabel control={<Checkbox
                    checked={this.state.sortMode}
                    onChange={()=>{
                      this.setState({sortMode: !this.state.sortMode, rerender: this.state.rerender+1})
                    }}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    label={""}
                  />} label="Ubah Urutan" />
                </div>
              </div>
            )
          }
        />

          <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            mode={this.state.dialogMode}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
          />  
          <DialogDetail
            open={this.state.detailOpen} 
            title={this.state.detailTitle}
            content={this.state.detailContent}
            handleClose={this.handleDetailClose}
            handleOk={this.handleDetailClose}
            />  
      </div>
    );
  }
}

DbMasterCategories.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param)),
    onDeselectParam: () => dispatch(deselectParam()),
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbMasterCategories)));