import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select, Input, InputAdornment, IconButton,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../config/axios';
import DialogBasic from '../../../component/dialogbasic';
import Errormsg from "../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam, addLogo } from "../../../store/actions/configAction";
import urlConfig from "./../../../config/backend";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../util/IntlMessages";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

class DbSettingsEdit extends React.Component {
  constructor(props){
      super(props);
      const today = new Date();
      this.state = {
        previewLogo: urlConfig.urlBackend + 'bo/int/logo/'+ today.getTime(),
        logo:null,
        storename:"",
        storedesc:"",
        defcontact:"",
        error:{
          id:"",
          logo:"",
          name:"",
          desc:"",
          defcontact:""
        },
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        mode:'add',
        alowPeriod:[],
        showPassword:false
    };
  }

  loadData(){
    const outer = this;
    // axios.defaults.headers.common['Authorization'] ='Bearer '+window.localStorage.getItem("tkn");
    
    axios.post('bo/int/storename', {}
    ).then(({data}) => {
      if (data.st && data.sc === 200) {
        outer.setState({
          storename:data.data.name,
          storedesc:data.data.desc
        })
      }
    }).catch(function (error) {
      outer.showDialog('Warning',Errormsg["500"]);
    });

    axios.post('bo/int/defcontact', {}
    ).then(({data}) => {
      if (data.st && data.sc === 200) {
        outer.setState({
          defcontact:data.data
        })
      }
    }).catch(function (error) {
      outer.showDialog('Warning',Errormsg["500"]);
    });
  }

  componentDidMount() {
    this.loadData();
  }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir. Silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");  
    } else {
      this.setState({dialogOpen:false});
    }
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  handleChange = (e) => {
    let outer=this;
    let {form,error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if(inputName === "accept"){
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if(newAccept === true){
        error[inputName]=""
      }
    }

    this.setState({
      form:form,
      error:error
    }, () => {
      switch(inputName){
        case "mobile":
          if(inputValue){
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
                form[inputName]=inputValue.replace(/.$/,"");
                outer.setState({form:form});
            }
          }
        break;  
        default:
        break;
      }
    });
  }

  handleNameChange = (e) => {
    this.setState({storename:e.target.value});
  }
  
  handleDescChange = (e) => {
    this.setState({storedesc:e.target.value});
  }

  handleContactChange = (e) => {
    this.setState({defcontact:e.target.value});
  }

  handleValidation = (e) => {
    let {logo,error,mode} = this.state;

    if(logo === null){
      error.logo="Harap masukan logo";
    }else{
      error.logo="";
    }

    if(!this.isValidForm()){
      error.accept="";
    }

    this.setState({
      error:error
    }, () => {

      if(!this.isValidForm()){
        return;
      }
      
      this.handleSubmit();
      
    });

  }

  handleSubmit = () => {
    let {logo} = this.state;
    const formData = new FormData();
    formData.append('logo',logo);
    // Object.entries(form).map(([key,value]) => {
    //   if(value != undefined){
    //     switch (key) {
    //       // case 'birthdate':            
    //       //   formData.append(key,formatDate(value));
    //       //   break;
    //       default:
    //         formData.append(key,value)
    //         break;
    //     }
    //   }else{
    //     formData.append(key,'')
    //   }
    // });


      const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/logo/edit', formData
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Information',<IntlMessages id="setting.logo.success.edit"/>);          
            let today = new Date();
            outer.props.onAddLogo(today.getTime());
            // console.log("setting", outer.props.logo, today.getTime());
            // setTimeout(()=>{window.location.reload()},3000);  
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      });

  }

  handleStoreNameValidation = (e) => {
    let {storename,error,mode} = this.state;

    if(storename.trim().length === 0){
      error.storename="Harap masukan nama usaha";
    }else{
      error.storename="";
    }

    if(!this.isStoreNameValidForm()){
      error.accept="";
    }

    this.setState({
      error:error
    }, () => {

      if(!this.isStoreNameValidForm()){
        return;
      }
      
      this.handleStoreNameSubmit();
      
    });

  }

  handleStoreNameSubmit = () => {
    let {storename, storedesc} = this.state;
    const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/storename/edit', {name:storename, desc:storedesc}
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Information',<IntlMessages id="setting.storename.success.edit"/>);            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      });

  }

  handleContactValidation = (e) => {
    let {defcontact,error,mode} = this.state;

    if(defcontact.trim().length === 0){
      error.defcontact="Harap masukan telepon";
    }else{
      error.defcontact="";
    }

    if(!this.isContactValidForm()){
      error.accept="";
    }

    this.setState({
      error:error
    }, () => {

      if(!this.isContactValidForm()){
        return;
      }
      
      this.handleContactSubmit();
      
    });

  }

  handleContactSubmit = () => {
    let {defcontact} = this.state;
    const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/defcontact/edit', {contact:defcontact}
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Information',<IntlMessages id="setting.contact.success.edit"/>);            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      });

  }

  isValidForm = () => {
    return (
    !this.state.error.logo)
  }

  isStoreNameValidForm = () => {
    return (
    !this.state.error.storename)
  }

  isContactValidForm = () => {
    return (
    !this.state.error.defcontact)
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        logo: file,
        previewLogo: reader.result
      });
    }

    reader.readAsDataURL(file)
  }

  handleClickShowPassword = () => {
    this.setState({showPassword: !this.state.showPassword});
  };
  
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  onImageError = () =>{
    this.setState({previewLogo:require("../../../assets/katalok.png")});
  }

 

  render(){
    const { classes, theme } = this.props;
    const { error, alowPeriod, storename, storedesc, defcontact } = this.state;
    const title = "Shop Settings"
    return (
      <div style={{marginTop: theme.spacing(4)}}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="textPrimary" href="/bo/settings">
              Pengaturan Usaha
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
              <form className={classes.form} noValidate>
                                   
                  
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="storename"
                        name="storename"
                        label={<IntlMessages id="setting.storename"/>}
                        value={storename} 
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleNameChange} 
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="storedesc"
                        name="storedesc"
                        label={<IntlMessages id="setting.storedesc"/>}
                        value={storedesc} 
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleDescChange} 
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Button
                        type="button"
                        className={classes.submit}
                        color="secondary"
                        variant="contained"
                        fullWidth
                        onClick={this.handleStoreNameValidation}
                        disabled={this.state.disableBtnSubmit}
                      >
                      UPDATE USAHA
                    </Button>

                    <Grid item xs={12}>
                      <TextField
                        id="contact"
                        name="contact"
                        label={<IntlMessages id="setting.contact"/>}
                        value={defcontact} 
                        size="small"
                        variant="outlined"
                        fullWidth
                        type="tel"
                        onChange={this.handleContactChange} 
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Button
                        type="button"
                        className={classes.submit}
                        color="secondary"
                        variant="contained"
                        fullWidth
                        onClick={this.handleContactValidation}
                        disabled={this.state.disableBtnSubmit}
                      >
                      UPDATE TELEPON
                    </Button>
                    
                    <Grid item xs={12}>
                      <label>Logo: </label>
                      <br/>
                      <img 
                        src={this.state.previewLogo}
                        onError={this.onImageError}
                        style={{width: 100, marginBottom: 10}}
                      />
                      <br/>
                      <input 
                        type="file"
                        onChange={(e) => this.handleImageChange(e)}
                      />
                    </Grid>
                    <Button
                      type="button"
                      className={classes.submit}
                      color="secondary"
                      variant="contained"
                      fullWidth
                      onClick={this.handleValidation}
                      disabled={this.state.disableBtnSubmit}
                    >
                      UPDATE LOGO
                    </Button>
                    
                  </Grid>
                </form>
          </div>
        </Container>

        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );
  }
}

DbSettingsEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param,
    logo: state.logo,
  };
};

const dispatchToProps = dispatch => {
  return {
    onAddLogo: logo => dispatch(addLogo(logo))
  };
};

export default connect(
  mapStatestoProps, dispatchToProps
)(withStyles(styles)(withTheme(DbSettingsEdit)));