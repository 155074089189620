import React from 'react';
import {Card, CssBaseline, Grid, Paper, IconButton, Checkbox, CardMedia,
  Typography, Chip, CardContent} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import axios from '../config/axios';
import DialogBasic from '../component/dialogbasic';
import DialogDetail from '../component/dialogdetail';
import {
  Remove, List, LocalShipping, CloudUpload, MoodBad,
  AccountCircleRounded, TrackChangesRounded
} from '@material-ui/icons';
import {compareSequence} from '../component/helpers';
import MUIDataTable from "mui-datatables";
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../config/errormsg";
import urlConfig from "../config/backend";
import moment from 'moment';
import IntlMessages from "../util/IntlMessages";
import DataTable from 'react-data-table-component';

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});

const columns = [
  {
    name: "Tanggal",
    options: {
      filter: true
    }
  },
  {
    name: "Nama",
    options: {
      filter: true
    }
  },
  {
    name: "Email",
    options: {
      filter: true
    }
  },
  {
    name: "Kota",
    options: {
      filter: true
    }
  },
  {
    name: "UTBK",
    options: {
      filter: true
    }
  }   
];

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "scrollFullHeight",
  rowsPerPageOptions:[10,25,100],
  print:false,
  download:false,
  selectableRows:'single',
  isRowSelectable:function(){return false}
};

class DbHomeAdmin extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        dashboardList: [],
        dashboardTotal: 0,
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        dialogMode:'',
        detailOpen:false,
        detailTitle:'Detail Transaksi',
        detailContent:[],
        visibleloader:false,
        countitem:0
    };
  }

  columns = [
    {
      name: 'Action',
      button: true,
      cell: row => {return <div>
        <IconButton color="primary" aria-label="detail" component="span" size="small" style={{marginRight:5}} onClick={()=>this.openOrderDetail(row)}>
            <List />
        </IconButton>
        <IconButton color="primary" aria-label="detail" component="span" size="small"  onClick={()=>this.openOrderShipDetail(row)}>
            <LocalShipping />
        </IconButton>
        {
          row.transfer.transfer != "" &&
          <IconButton color="primary" aria-label="detail" component="span" size="small"  onClick={()=>this.openOrderTransferDetail(row)}>
            <CloudUpload />
          </IconButton>
        }
          
        
        {/* <IconButton color="primary" aria-label="edit" component="span" size="small"  onClick={()=>this.editDiscount(row)}>
            <Edit />
        </IconButton> */}
      </div>},
    },
    {
      name: <IntlMessages id="transaction.order.username"/>,
      selector: 'username',
      sortable: true,
    },
    {
      name: <IntlMessages id="transaction.order.pickupdate"/>,
      selector: 'pickupdatescr',
      sortable: true
    },
    {
      name: <IntlMessages id="transaction.order.grandtotal"/>,
      selector: 'grandtotal',
      sortable: true
    },
    {
      name: <IntlMessages id="transaction.order.preorderstatus"/>,
      selector: 'preorder',
      sortable: true,
      cell: row => <span>
        {
          row.preorder == true ?
          "Ya" : "Tidak"
        }
      </span>
    },
    {
      name: <IntlMessages id="transaction.order.paymentstatus"/>,
      selector: 'paymentstatus',
      sortable: true,
      cell: row => <span>
      {row.paymentstatus}
    </span>
    },
    {
      name: <IntlMessages id="transaction.order.orderstatus"/>,
      selector: 'orderstatus',
      sortable: true,
      cell: row => <span>
        {row.orderstatus}
      </span>
    }
  ];
  
  menucolumns = [
    {
      name: <IntlMessages id="transaction.order.menuname"/>,
      selector: 'menuname',
      sortable: true,
    },
    {
      name: <IntlMessages id="transaction.order.desc"/>,
      selector: 'desc',
      sortable: true,
    },
    {
      name: <IntlMessages id="transaction.order.price"/>,
      selector: 'price',
      sortable: true
    },
    {
      name: <IntlMessages id="transaction.order.discount"/>,
      selector: 'discount',
      sortable: true
    },
    {
      name: <IntlMessages id="transaction.order.qty"/>,
      selector: 'qty',
      sortable: true
    },
    {
      name: <IntlMessages id="transaction.order.subtotal"/>,
      selector: 'subtotal',
      sortable: true
    }
  ];

  componentDidMount() {
    this.getDashboard();
  }

  getDashboard() {
    let outer = this;
    axios.post('bo/int/dashboard', {}).then(({data}) => {
      if(data.st && data.sc === 200) {
          const result = data.data;
          outer.setState({countitem:result.item})
      } else {
        outer.showDialog("warning", data.msg);
      }
    }).catch(function (error) {
      outer.showDialog("warning", Errormsg["500"], "");
    })
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen:false
    })
  }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir. Silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");  
    } else {
      this.setState({dialogOpen:false});
    }
  }

  showDialog = (title,content,mode) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content,
      dialogMode:mode
    });
  }

  openOrderDetail = (row) => {
    // console.log(row)
    let detailContent = [];
    Object.keys(row).forEach(function(key) {
      let name = '';
      let seq = 0;
      let view = true;
      let pict = false;
      let list = false;
      let url = urlConfig.urlBackendMenu;
      switch (key) {
        case 'ordernum':
          name = <IntlMessages id="transaction.order.ordernum"/>;
          seq = 1;
          view = true;
          pict = false;
          list = false;
          break;
        case 'username':
          name = <IntlMessages id="transaction.order.username"/>;
          seq = 2;
          view = true;
          pict = false;
          list = false;
          break;
        case 'transdate':
          name = <IntlMessages id="transaction.order.date"/>;
          seq = 3;
          view = true;
          pict = false;
          list = false;
          break;
        case 'pickupdate':
          name = <IntlMessages id="transaction.order.pickupdate"/>;
          seq = 4;
          view = true;
          pict = false;
          list = false;
          break;
        case 'grandtotal':
          name = <IntlMessages id="transaction.order.grandtotal"/>;
          seq = 5;
          view = true;
          pict = false;
          list = false;
          break;
        case 'paymentstatus':
          name = <IntlMessages id="transaction.order.paymentstatus"/>;
          seq = 4;
          view = true;
          pict = false;
          list = false;
          break;
        case 'orderstatus':
          name = <IntlMessages id="transaction.order.orderstatus"/>;
          seq = 4;
          view = true;
          pict = false;
          list = false;
          break;
        default:
          view = false;
          break;
      }
      let content = {id:key, name:name, value:row[key], seq: seq, view:view, pict: pict, url: url, list: list}
      detailContent.push(content);
      // console.log(key, row[key]);
    
    });
    detailContent.sort(compareSequence);
    // console.log(detailContent);
    this.setState({
      detailOpen:true,
      detailContent
    });

  }

  openOrderShipDetail = (row) => {
    // console.log(row.shipping);
    let shipping = row.shipping;
    let detailContent = [];
    Object.keys(shipping).forEach(function(key) {
      let name = '';
      let seq = 0;
      let view = true;
      let pict = false;
      let list = false;
      let url = urlConfig.urlBackendMenu;
      switch (key) {
        case 'address':
          name = <IntlMessages id="transaction.order.address"/>;
          seq = 1;
          view = true;
          pict = false;
          list = false;
          break;
        case 'city':
          name = <IntlMessages id="transaction.order.city"/>;
          seq = 2;
          view = true;
          pict = false;
          list = false;
          break;
        case 'district':
          name = <IntlMessages id="transaction.order.district"/>;
          seq = 3;
          view = true;
          pict = false;
          list = false;
          break;
        case 'village':
          name = <IntlMessages id="transaction.order.village"/>;
          seq = 4;
          view = true;
          pict = false;
          list = false;
          break;
        case 'mobile':
          name = <IntlMessages id="transaction.order.mobile"/>;
          seq = 5;
          view = true;
          pict = false;
          list = false;
          break;
        default:
          view = false;
          break;
      }
      let content = {id:key, name:name, value:shipping[key], seq: seq, view:view, pict: pict, url: url, list: list}
      detailContent.push(content);
      // console.log(key, row[key]);
    
    });
    detailContent.sort(compareSequence);
    // console.log(detailContent);
    this.setState({
      detailOpen:true,
      detailContent
    });

  }

  openOrderTransferDetail = (row) => {
    let transfer = row.transfer;
    let detailContent = [];
    Object.keys(transfer).forEach(function(key) {
      let name = '';
      let seq = 0;
      let view = true;
      let pict = false;
      let size = "big";
      let url = urlConfig.urlBackendTransfer;
      switch (key) {
        case 'transfer':
          name = <IntlMessages id="transaction.order.transfer"/>;
          seq = 1;
          view = true;
          pict = true;
          size = "big";
          break;
        case 'desc':
          name = <IntlMessages id="transaction.order.desc"/>;
          seq = 2;
          view = true;
          pict = false;
          size = "";
          break;
        case 'uploaddatescr':
          name = <IntlMessages id="transaction.order.uploaddate"/>;
          seq = 3;
          view = true;
          pict = false;
          size = "";
          break;
        default:
          view = false;
          break;
      }
      let content = {id:key, name:name, value:transfer[key], seq: seq, view:view, 
        pict: pict, url: url, size: size }
      detailContent.push(content);
      // console.log(key, row[key]);
    
    });
    detailContent.sort(compareSequence);
    // console.log(detailContent);
    this.setState({
      detailOpen:true,
      detailContent
    });

  }

  render(){
    const { classes } = this.props;
    const { dashboardList, dashboardTotal,countitem} = this.state;
    const title = "Dashboard Admin";
    const Comp = ({data}) => {
      return <DataTable
          title="Menu"
          columns={this.menucolumns}
          data={data.detail}
          noDataComponent={
            <Card style={{maxWidth: 200, borderWidth: 1, borderStyle: 'solid'}}>
              <CardMedia style={{textAlign: 'center'}}>
                <MoodBad color="action" style={{
                  fontSize: 40, 
                  marginTop: 10, 
                  marginBottom: 10
                }}/>
              </CardMedia>
              <CardContent>
                <Typography>
                  No data available
                </Typography>
              </CardContent>
            </Card>
            
          }
          customStyles={customStyles}
        />
      
    };

    return (
      <div>
        <CssBaseline />
        <div className={classes.paper}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography component="h5" variant="h5">
                Dashboard
              </Typography>
              <br/><br/>
              <Typography component="h6" variant="h6">
                Total Item : {countitem}
              </Typography>
            </Grid>
          </Grid>
        </div>
  
        <DialogDetail
            open={this.state.detailOpen} 
            title={this.state.detailTitle}
            content={this.state.detailContent}
            mode={this.state.dialogMode}
            handleClose={this.handleDetailClose}
            handleOk={this.handleDetailClose}
            />  
        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );

    // return (
    //   <DashboardAdmin title={title} content={content} />
    // );
  }
}

DbHomeAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DbHomeAdmin);