import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {IconButton, TextField, Checkbox, Card, FormControl,
  CardMedia ,CardContent, Typography, InputLabel, Select, MenuItem} from '@material-ui/core';
import {MoodBad} from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import {compareSequence} from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';
import IntlMessages from "../../../../../util/IntlMessages";
import urlConfig from "./../../../../../config/backend";

import DataTable from 'react-data-table-component';

//import actions
import { addParam,deselectParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});

function numberWithCommas(x) {
  var parts = x.toString().split(",");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
}


class DbTransactionDiscount extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        page:{
          categories: '',
          keyword: '',
          page:1,
          count:10
        },
        discountList:[],
        discountListTotal: 0,
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        dialogMode:'',
        detailOpen:false,
        detailTitle:'Discount Detail',
        detailContent:[],
        selectedRows:[],
      }
  }

  columns = [
    {
      name: 'Action',
      button: true,
      cell: row => {return <div>
        <IconButton color="primary" aria-label="detail" component="span" size="small" style={{marginRight:5}} onClick={()=>this.openDiscountDetail(row)}>
            <List />
        </IconButton>
        {/* <IconButton color="primary" aria-label="edit" component="span" size="small"  onClick={()=>this.editDiscount(row)}>
            <Edit />
        </IconButton> */}
        {/* <List style={{ margin: '5px' }} color="action" /> */}
        {/* <Edit style={{ margin: '5px' }} color="action" /> */}
      </div>},
    },
    {
      name: <IntlMessages id="transaction.discount.name"/>,
      selector: 'name',
      sortable: true,
    },
    {
      name: <IntlMessages id="transaction.discount.start"/>,
      selector: 'startstr',
      sortable: true
    },
    {
      name: <IntlMessages id="transaction.discount.end"/>,
      selector: 'endstr',
      sortable: true
    }
  ];
  
  menucolumns = [
    {
      name: <IntlMessages id="transaction.discount.menu"/>,
      selector: 'menuname',
      sortable: true,
    },
    {
      name: <IntlMessages id="transaction.discount.discprice"/>,
      selector: 'discountprice',
      sortable: true,
      format: row => numberWithCommas(row.discountprice)
    }
  ];

  componentDidMount(){
    this.getDiscountList();
  }

  showDialog = (title,content,mode) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content,
      dialogMode:mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen:false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen:false
    })
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir. Silahkan login kembali.") {
      this.setState({
        dialogOpen:false
      });
      this.props.history.push("/logout");  
    } else {

      this.setState({
        dialogOpen:false
      });
      switch (this.state.dialogMode) {
        case "delete":
          this.handleDeleteDiscount();
          break;
      
        default:
          break;
      }
    }
  }

  getDiscountList = () => {
    let outer = this;
    axios.post('bo/int/trans/discount/list', outer.state.page).then(({data})=>{
      data.data.list.forEach((item) => {
        item.startstr = moment(item.start).format("DD-MM-YYYY");
        item.endstr = moment(item.end).format("DD-MM-YYYY");
      })
      if(data.st && data.sc === 200){
        outer.setState({
          discountList: data.data.list,
          discountListTotal: data.data.total
        });
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  openDiscountDetail = (row) => {
    // console.log(row)
    let detailContent = [];
    Object.keys(row).forEach(function(key) {
      let name = '';
      let seq = 0;
      let view = true;
      let pict = false;
      let list = false;
      let url = urlConfig.urlBackendMenu;
      switch (key) {
        case 'name':
          name = <IntlMessages id="transaction.discount.name"/>;
          seq = 1;
          view = true;
          pict = false;
          list = false;
          break;
        case 'startstr':
          name = <IntlMessages id="transaction.discount.start"/>;
          seq = 2;
          view = true;
          pict = false;
          list = false;
          break;
        case 'endstr':
          name = <IntlMessages id="transaction.discount.end"/>;
          seq = 3;
          view = true;
          pict = false;
          list = false;
          break;
        default:
          view = false;
          break;
      }
      let content = {id:key, name:name, value:row[key], seq: seq, view:view, pict: pict, url: url, list: list}
      detailContent.push(content);
      // console.log(key, row[key]);
    
    });
    detailContent.sort(compareSequence);
    // console.log(detailContent);
    this.setState({
      detailOpen:true,
      detailContent
    });

  }

  editDiscount = (row) => {
    this.props.onAddParam({detail:row});
    this.props.history.push("/bo/transaction/discount/edit");
  }
 
  handleSelectedRows = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    // console.log('Selected Rows: ', state.selectedRows);
    this.setState({selectedRows:state.selectedRows});
  };

  deleteDiscount = () => {
    let discountLength = this.state.selectedRows.length;
    if(discountLength == 0) {
      this.showDialog("Peringatan","Pilih salah satu item untuk menghapus","");  
    } else {
      this.showDialog("Hapus Diskon","Anda akan menghapus "+ discountLength+" diskon. Apakah anda yakin?","delete");
    }
  }
   
  handleDeleteDiscount = () => {
    
    const outer = this;
    const {selectedRows} = this.state;
    let deleteDiscount = [];
    selectedRows.forEach((discount)=>{
      deleteDiscount.push(discount.id);
    })
    // console.log("delete discount", deleteDiscount);
    
    this.setState({
      disableBtnSubmit:true
    }, () => {
      axios.post('bo/int/trans/discount/delete', {id:deleteDiscount}
      ).then(({data}) => {
        if (data.st && data.sc === 200) {
          outer.setState({
            disableBtnSubmit:false
          }, () => {
            outer.getDiscountList();
            outer.showDialog('Information',<IntlMessages id="transaction.discount.success.delete"/>,"");
          })
          
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',data.msg,"");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
      });
    });
  }

  search(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        keyword: val.target.value
      }
    }, () => {
      outer.getDiscountList();
    })
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        keyword: "",
        count: val
      }
    }, () => {
      outer.getDiscountList();
    })
  }

  onChangePage = (val) => {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val,
        keyword: "",
      }
    }, () => {
      outer.getDiscountList();
    })
  }
   
  render() {
    const { classes, container, theme, content, title } = this.props;
    const { discountList} = this.state;
    const Comp = ({data}) => {
      return <DataTable
          title="Menu"
          columns={this.menucolumns}
          data={data.menu}
          noDataComponent={
            <Card style={{maxWidth: 200, borderWidth: 1, borderStyle: 'solid'}}>
              <CardMedia style={{textAlign: 'center'}}>
                <MoodBad color="action" style={{
                  fontSize: 40, 
                  marginTop: 10, 
                  marginBottom: 10
                }}/>
              </CardMedia>
              <CardContent>
                <Typography>
                  No data available
                </Typography>
              </CardContent>
            </Card>
            
          }
          customStyles={customStyles}
        />
      
    };
      
    return (
      <div style={{marginTop: theme.spacing(4), height: '100%'}}>
         <DataTable
          title="Discount"
          columns={this.columns}
          data={discountList}
          noDataComponent={
            <Card style={{maxWidth: 200, borderWidth: 1, borderStyle: 'solid'}}>
              <CardMedia style={{textAlign: 'center'}}>
                <MoodBad color="action" style={{
                  fontSize: 40, 
                  marginTop: 10, 
                  marginBottom: 10
                }}/>
              </CardMedia>
              <CardContent>
                <Typography>
                  No data available
                </Typography>
              </CardContent>
            </Card>
            
          }
          selectableRows
          selectableRowsComponent={Checkbox}
          expandableRows
          expandableRowsComponent={<Comp/>}
          highlightOnHover
          subHeader
          customStyles={customStyles}
          onSelectedRowsChange={this.handleSelectedRows}
          pagination
          paginationServer
          paginationTotalRows={this.state.discountListTotal}
          paginationPerPage={this.state.page.count}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={(val) => this.onChangeRows(val)}
          subHeaderComponent={
            (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                
                <TextField 
                  id="outlined-basic" 
                  label="Search" 
                  variant="outlined" 
                  size="small" 
                  style={{ margin: '5px' }}
                  onChange={(val) => this.search(val)} 
                />
                <IconButton 
                  color="primary" 
                  aria-label="detail" 
                  component="span" 
                  onClick={()=>{this.props.onDeselectParam();this.props.history.push("/bo/transaction/discount/edit")}} 
                >
                    <Add />
                </IconButton>
                <IconButton 
                  color="primary" 
                  aria-label="detail" 
                  component="span" 
                  onClick={()=>{this.deleteDiscount()}} 
                >
                    <Remove />
                </IconButton>
              </div>
            )
          }
        />

          <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            mode={this.state.dialogMode}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
          />  
          <DialogDetail
            open={this.state.detailOpen} 
            title={this.state.detailTitle}
            content={this.state.detailContent}
            handleClose={this.handleDetailClose}
            handleOk={this.handleDetailClose}
            />  
      </div>
    );
  }
}

DbTransactionDiscount.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param)),
    onDeselectParam: () => dispatch(deselectParam()),
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbTransactionDiscount)));