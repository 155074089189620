import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DialogBasic(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      disableBackdropClick
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {
          props.mode == "delete" &&
          <Button onClick={props.handleClose} color="primary" autoFocus>
            Cancel
          </Button> 
        }
        <Button onClick={
          props.handleOk
        } color="primary" style={{backgroundColor: props.mode == 'delete' ? "#E60000" : "#FFF", color: props.mode == 'delete' ? '#FFF' : "#000"}} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}