import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

export default function DialogDetail(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      disableBackdropClick
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
          <Grid container spacing={3}>
            {props.content.map((content, i)=>{
              if(content.view) {
                return (
                  !content.list ?
                  <React.Fragment key={"fragment-"+i}>
                    <Grid item xs={3} sm={3} md={3}>
                      {
                        content.name
                      }
                    </Grid>
                    {
                      content.pict ?
                      <Grid  item xs={9} sm={9} md={9}>
                        <img src={
                          content.value != "" ?
                          content.url + content.value : 
                          require("../assets/default_item.png")
                        } style={
                          content.size == "big" ?
                          {width: 350} : {width: 100}
                        }/>
                      </Grid> :
                      <Grid item xs={9} sm={9} md={9}>
                        {content.value}
                      </Grid> 
                    }
                    
                  </React.Fragment> :
                  <Grid key={i} item xs={6} sm={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={content.value == "true" ? true : false}
                          disabled
                        />
                      }
                      label={content.name}
                    />
                    
                  </Grid>
                )  
              }
            })}
          </Grid>
          {

          }
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleOk} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}