import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {IconButton, TextField, Checkbox, Card, FormControl,
  CardMedia ,CardContent, Typography, InputLabel, Select, MenuItem} from '@material-ui/core';
import {MoodBad} from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import {compareSequence} from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List, LocalShipping, CloudUpload } from "@material-ui/icons"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';
import IntlMessages from "../../../../../util/IntlMessages";
import urlConfig from "./../../../../../config/backend";

import DataTable from 'react-data-table-component';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

//import actions
import { addParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});

function numberWithCommas(x) {
  var parts = x.toString().split(",");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
}


class DbTransactionTransfer extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        page:{
          orderdatestr: null,
          orderdate: "",
          pickupdatestr: null,
          pickupdate: "",
          keyword: '',
          page:1,
          count:10
        },
        transferList:[],
        transferListTotal: 0,
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        dialogMode:'',
        detailOpen:false,
        detailTitle:'Detail Transfer',
        detailContent:[],
        selectedRows:[],
      }
  }

  columns = [
    {
      name: 'Action',
      button: true,
      cell: row => {return <div>
        <IconButton color="primary" aria-label="detail" component="span" size="small" style={{marginRight:5}} onClick={()=>this.openOrderTransferDetail(row)}>
            <List />
        </IconButton>
        {/* <IconButton color="primary" aria-label="detail" component="span" size="small"  onClick={()=>this.openOrderShipDetail(row)}>
            <LocalShipping />
        </IconButton>
        {
          row.transfer.transfer != "" &&
          <IconButton color="primary" aria-label="detail" component="span" size="small"  onClick={()=>this.openOrderTransferDetail(row)}>
            <CloudUpload />
          </IconButton>
        } */}
          
        
        {/* <IconButton color="primary" aria-label="edit" component="span" size="small"  onClick={()=>this.editDiscount(row)}>
            <Edit />
        </IconButton> */}
      </div>},
    },
    {
      name: <IntlMessages id="transaction.order.username"/>,
      selector: 'name',
      sortable: true,
    },
    {
      name: <IntlMessages id="transaction.order.grandtotal"/>,
      selector: 'grandtotal',
      sortable: true
    },
    {
      name: <IntlMessages id="transaction.order.desc"/>,
      selector: 'desc',
      sortable: true
    },
    {
      name: <IntlMessages id="transaction.order.paymentstatus"/>,
      selector: 'paymentstatus',
      sortable: true,
      cell: row => <Select value={row.paymentstatus} onChange={(event)=>this.handleStatusChange(row, event)}>
      <MenuItem value="Pending">
        <span style={{color: "orange"}}>Tunda</span>
      </MenuItem>
      <MenuItem value="Process">
        <span style={{color: "blue"}}>Proses</span>
      </MenuItem>
      <MenuItem value="Completed">
        <span style={{color: "green"}}>Selesai</span>
      </MenuItem>
      <MenuItem value="Canceled">
        <span style={{color: "red"}}>Batal</span>
      </MenuItem>
    </Select>
    }
  ];
  
  menucolumns = [
    {
      name: <IntlMessages id="transaction.order.menuname"/>,
      selector: 'menuname',
      sortable: true,
    },
    {
      name: <IntlMessages id="transaction.order.desc"/>,
      selector: 'desc',
      sortable: true,
    },
    {
      name: <IntlMessages id="transaction.order.price"/>,
      selector: 'price',
      sortable: true
    },
    {
      name: <IntlMessages id="transaction.order.discount"/>,
      selector: 'discount',
      sortable: true
    },
    {
      name: <IntlMessages id="transaction.order.qty"/>,
      selector: 'qty',
      sortable: true
    },
    {
      name: <IntlMessages id="transaction.order.subtotal"/>,
      selector: 'subtotal',
      sortable: true
    }
  ];

  componentDidMount(){
    this.getTransferList();
  }

  showDialog = (title,content,mode) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content,
      dialogMode:mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen:false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen:false
    })
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir. Silahkan login kembali.") {
      this.setState({
        dialogOpen:false
      });
      this.props.history.push("/logout");  
    } else {

      this.setState({
        dialogOpen:false
      });
      switch (this.state.dialogMode) {
        case "delete":
          this.handleDeleteOrder();
          break;
      
        default:
          break;
      }
    }
  }

  handleStatusChange = (row, event) => {
    let outer = this;
    let param = {id:row.orderid, status: event.target.value};
    axios.post('bo/int/trans/order/payment', param).then(({data})=>{
      if(data.st && data.sc === 200){
        outer.getTransferList();
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  handleOrderStatusChange = (row, event) => {
    let outer = this;
    let param = {id:row.id, status: event.target.value};
    axios.post('bo/int/trans/order/status', param).then(({data})=>{
      if(data.st && data.sc === 200){
        outer.getTransferList();
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  getTransferList = () => {
    let outer = this;
    axios.post('bo/int/trans/transfer/list', outer.state.page).then(({data})=>{
      if(data.st && data.sc === 200){
        data.data.list.forEach((item, i) => {
          item.uploaddatescr = item.uploaddate != "" && moment(item.uploaddate).format("DD-MM-YYYY");
        })
        outer.setState({
          transferList: data.data.list,
          transferListTotal: data.data.total
        });
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  openOrderDetail = (row) => {
    // console.log(row)
    let detailContent = [];
    Object.keys(row).forEach(function(key) {
      let name = '';
      let seq = 0;
      let view = true;
      let pict = false;
      let list = false;
      let url = urlConfig.urlBackendMenu;
      switch (key) {
        case 'ordernum':
          name = <IntlMessages id="transaction.order.ordernum"/>;
          seq = 1;
          view = true;
          pict = false;
          list = false;
          break;
        case 'username':
          name = <IntlMessages id="transaction.order.username"/>;
          seq = 2;
          view = true;
          pict = false;
          list = false;
          break;
        case 'transdate':
          name = <IntlMessages id="transaction.order.date"/>;
          seq = 3;
          view = true;
          pict = false;
          list = false;
          break;
        case 'pickupdate':
          name = <IntlMessages id="transaction.order.pickupdate"/>;
          seq = 4;
          view = true;
          pict = false;
          list = false;
          break;
        case 'grandtotal':
          name = <IntlMessages id="transaction.order.grandtotal"/>;
          seq = 5;
          view = true;
          pict = false;
          list = false;
          break;
        case 'paymentstatus':
          name = <IntlMessages id="transaction.order.paymentstatus"/>;
          seq = 4;
          view = true;
          pict = false;
          list = false;
          break;
        case 'orderstatus':
          name = <IntlMessages id="transaction.order.orderstatus"/>;
          seq = 4;
          view = true;
          pict = false;
          list = false;
          break;
        default:
          view = false;
          break;
      }
      let content = {id:key, name:name, value:row[key], seq: seq, view:view, pict: pict, url: url, list: list}
      detailContent.push(content);
      // console.log(key, row[key]);
    
    });
    detailContent.sort(compareSequence);
    // console.log(detailContent);
    this.setState({
      detailOpen:true,
      detailContent
    });

  }

  openOrderShipDetail = (row) => {
    // console.log(row.shipping);
    let shipping = row.shipping;
    let detailContent = [];
    Object.keys(shipping).forEach(function(key) {
      let name = '';
      let seq = 0;
      let view = true;
      let pict = false;
      let list = false;
      let url = urlConfig.urlBackendMenu;
      switch (key) {
        case 'address':
          name = <IntlMessages id="transaction.order.address"/>;
          seq = 1;
          view = true;
          pict = false;
          list = false;
          break;
        case 'city':
          name = <IntlMessages id="transaction.order.city"/>;
          seq = 2;
          view = true;
          pict = false;
          list = false;
          break;
        case 'district':
          name = <IntlMessages id="transaction.order.district"/>;
          seq = 3;
          view = true;
          pict = false;
          list = false;
          break;
        case 'village':
          name = <IntlMessages id="transaction.order.village"/>;
          seq = 4;
          view = true;
          pict = false;
          list = false;
          break;
        case 'mobile':
          name = <IntlMessages id="transaction.order.mobile"/>;
          seq = 5;
          view = true;
          pict = false;
          list = false;
          break;
        default:
          view = false;
          break;
      }
      let content = {id:key, name:name, value:shipping[key], seq: seq, view:view, pict: pict, url: url, list: list}
      detailContent.push(content);
      // console.log(key, row[key]);
    
    });
    detailContent.sort(compareSequence);
    // console.log(detailContent);
    this.setState({
      detailOpen:true,
      detailContent
    });

  }

  openOrderTransferDetail = (row) => {
    let transfer = row;
    let detailContent = [];
    Object.keys(transfer).forEach(function(key) {
      let name = '';
      let seq = 0;
      let view = true;
      let pict = false;
      let size = "big";
      let url = urlConfig.urlBackendTransfer;
      switch (key) {
        case 'transfer':
          name = <IntlMessages id="transaction.order.transfer"/>;
          seq = 1;
          view = true;
          pict = true;
          size = "big";
          break;
        case 'desc':
          name = <IntlMessages id="transaction.order.desc"/>;
          seq = 2;
          view = true;
          pict = false;
          size = "";
          break;
        case 'uploaddatescr':
          name = <IntlMessages id="transaction.order.uploaddate"/>;
          seq = 3;
          view = true;
          pict = false;
          size = "";
          break;
        default:
          view = false;
          break;
      }
      let content = {id:key, name:name, value:transfer[key], seq: seq, view:view, 
        pict: pict, url: url, size: size }
      detailContent.push(content);
      // console.log(key, row[key]);
    
    });
    detailContent.sort(compareSequence);
    // console.log(detailContent);
    this.setState({
      detailOpen:true,
      detailContent
    });

  }

  editDiscount = (row) => {
    this.props.onAddParam({detail:row});
    this.props.history.push("/bo/transaction/discount/edit");
  }
 
  handleSelectedRows = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    // console.log('Selected Rows: ', state.selectedRows);
    this.setState({selectedRows:state.selectedRows});
  };

  deleteDiscount = () => {
    let discountLength = this.state.selectedRows.length;
    if(discountLength == 0) {
      this.showDialog("Peringatan","Pilih salah satu item untuk menghapus","");  
    } else {
      this.showDialog("Hapus Order","Anda akan menghapus "+ discountLength+" order. Apakah anda yakin?","delete");
    }
  }
   
  handleDeleteOrder = () => {
    
    const outer = this;
    const {selectedRows} = this.state;
    let deleteDiscount = [];
    selectedRows.forEach((discount)=>{
      deleteDiscount.push(discount.id);
    })
    // console.log("delete discount", deleteDiscount);
    
    this.setState({
      disableBtnSubmit:true
    }, () => {
      axios.post('bo/int/trans/transfer/delete', {id:deleteDiscount}
      ).then(({data}) => {
        if (data.st && data.sc === 200) {
          outer.setState({
            disableBtnSubmit:false
          }, () => {
            outer.getTransferList();
            outer.showDialog('Information',<IntlMessages id="transaction.order.success.delete"/>,"");
          })
          
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',data.msg,"");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
      });
    });
  }

  search(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        keyword: val.target.value
      }
    }, () => {
      outer.getTransferList();
    })
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        keyword: "",
        count: val
      }
    }, () => {
      outer.getTransferList();
    })
  }

  onChangePage = (val) => {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val,
        keyword: "",
      }
    }, () => {
      outer.getTransferList();
    })
  }

  onchangeDateStart = (e) => {
    this.setState({
      page: {
        ...this.state.page,
        orderdatestr: e,
        orderdate: moment(e).format('YYYY-MM-DD')
      }
    }, () => {
      this.getTransferList();
    })
  }

  onchangeDateEnd = (e) => {
    this.setState({
      page: {
        ...this.state.page,
        pickupdatestr: e,
        pickupdate: moment(e).format('YYYY-MM-DD')
      }
    }, () => {
      this.getTransferList();
    })
  }
   
  render() {
    const { classes, container, theme, content, title } = this.props;
    const { transferList} = this.state;
    const Comp = (row) => {
      return (
        <div style={{paddingTop: 10, paddingBottom: 10}}>

          <img width={200} src={urlConfig.urlBackendTransfer + row.data.transfer}/>
        </div>
      )
    };
      
    return (
      <div style={{marginTop: theme.spacing(4), height: '100%'}}>
         <DataTable
          title="Transfer"
          columns={this.columns}
          data={transferList}
          noDataComponent={
            <Card style={{maxWidth: 200, borderWidth: 1, borderStyle: 'solid'}}>
              <CardMedia style={{textAlign: 'center'}}>
                <MoodBad color="action" style={{
                  fontSize: 40, 
                  marginTop: 10, 
                  marginBottom: 10
                }}/>
              </CardMedia>
              <CardContent>
                <Typography>
                  No data available
                </Typography>
              </CardContent>
            </Card>
            
          }
          // selectableRows
          // selectableRowsComponent={Checkbox}
          expandableRows
          expandableRowsComponent={<Comp/>}
          highlightOnHover
          // subHeader
          customStyles={customStyles}
          // onSelectedRowsChange={this.handleSelectedRows}
          pagination
          paginationServer
          paginationTotalRows={this.state.transferListTotal}
          paginationPerPage={this.state.page.count}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={(val) => this.onChangeRows(val)}
          // subHeaderComponent={
          //   (
          //     <div>
          //       <div>
          //         <InputLabel style={{fontSize: 12}} id="formtype">
          //           Tanggal Transaksi
          //         </InputLabel>
          //         <KeyboardDatePicker
          //           clearable
          //           value={this.state.page.orderdatestr}
          //           placeholder="10-10-2018"
          //           onChange={date => this.onchangeDateStart(date)}
          //           format="DD-MM-YYYY"
          //           fullWidth
          //         />
          //       </div>
          //       <br/>
          //       <div>
          //         <InputLabel style={{fontSize: 12}} id="formtype">
          //           Tanggal Pengantaran
          //         </InputLabel>
          //         <KeyboardDatePicker
          //           clearable
          //           value={this.state.page.pickupdatestr}
          //           placeholder="10-10-2018"
          //           onChange={date => this.onchangeDateEnd(date)}
          //           format="DD-MM-YYYY"
          //           fullWidth
          //         />
          //       </div>
          //       <br/>
          //       <div style={{ display: 'flex', alignItems: 'center' }}>
                  
          //         <TextField 
          //           id="outlined-basic" 
          //           label="Search" 
          //           variant="outlined" 
          //           size="small" 
          //           style={{ margin: '5px' }}
          //           onChange={(val) => this.search(val)} 
          //         />
          //         {/* <IconButton 
          //           color="primary" 
          //           aria-label="detail" 
          //           component="span" 
          //           onClick={()=>{this.props.history.push("/bo/transaction/discount/edit")}} 
          //         >
          //             <Add />
          //         </IconButton> */}
          //         <IconButton 
          //           color="primary" 
          //           aria-label="detail" 
          //           component="span" 
          //           onClick={()=>{this.deleteDiscount()}} 
          //         >
          //             <Remove />
          //         </IconButton>
          //       </div>
          //     </div>
          //   )
          // }
        />

          <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            mode={this.state.dialogMode}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
          />  
          <DialogDetail
            open={this.state.detailOpen} 
            title={this.state.detailTitle}
            content={this.state.detailContent}
            handleClose={this.handleDetailClose}
            handleOk={this.handleDetailClose}
            />  
      </div>
    );
  }
}

DbTransactionTransfer.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param))
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbTransactionTransfer)));