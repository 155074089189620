import { ADD_TOKEN, SELECT_TOKEN, DESELECT_TOKEN, ADD_PARAM, SELECT_PARAM, DESELECT_PARAM, ADD_LOGO, SELECT_LOGO, DESELECT_LOGO } from "./actionTypes";

export const addToken = (token) => {
    return {
        type: ADD_TOKEN,
        token: token
    };
}

export const selectToken = () => {
    return {
        type: SELECT_TOKEN
    }
}

export const deselectToken = () => {
    return {
        type: DESELECT_TOKEN
    }
}

export const addParam = (param) => {
    return {
        type: ADD_PARAM,
        param: param
    };
}

export const selectParam = () => {
    return {
        type: SELECT_PARAM
    }
}

export const deselectParam = () => {
    return {
        type: DESELECT_PARAM
    }
}

export const addLogo = (logo) => {
    return {
        type: ADD_LOGO,
        logo: logo
    };
}

export const selectLogo = () => {
    return {
        type: SELECT_LOGO
    }
}

export const deselectLogo = () => {
    return {
        type: DESELECT_LOGO
    }
}

