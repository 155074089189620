import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select, Input, InputAdornment, IconButton,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../config/axios';
import DialogBasic from '../../../component/dialogbasic';
import Errormsg from "../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../store/actions/configAction";
import urlConfig from "./../../../config/backend";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../util/IntlMessages";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  },
  textEditor:{
    border:1,
    borderStyle: 'solid',
    borderRadius: 10,
    borderColor: '#A9A9A9'
  }
});

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

class DbWelcomeEdit extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        welcome:EditorState.createEmpty(),
        error:{
          id:""
        },
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        mode:'add',
        alowPeriod:[],
        showPassword:false
    };
  }

  loadData(){
    const outer = this;
    // axios.defaults.headers.common['Authorization'] ='Bearer '+window.localStorage.getItem("tkn");
    axios.post('bo/int/welcome', {}
    ).then(({data}) => {
      if (data.st && data.sc === 200) {
        const contentBlock = htmlToDraft(data.data);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        outer.setState({
          welcome:editorState
        })
      }
    }).catch(function (error) {
      outer.showDialog('Warning',Errormsg["500"]);
    });
    
  }

  componentDidMount() {
    this.loadData();
  }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir. Silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");  
    } else {
      this.setState({dialogOpen:false});
    }
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  handleChange = (e) => {
    let outer=this;
    let {form,error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if(inputName === "accept"){
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if(newAccept === true){
        error[inputName]=""
      }
    }

    this.setState({
      form:form,
      error:error
    }, () => {
      switch(inputName){
        case "mobile":
          if(inputValue){
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
                form[inputName]=inputValue.replace(/.$/,"");
                outer.setState({form:form});
            }
          }
        break;  
        default:
        break;
      }
    });
  }

  handleWelcomeChange = (e) => {
    this.setState({welcome:e});
  }

  handleWelcomeSubmit = () => {
    let {welcome} = this.state;
    let abouthtml = draftToHtml(convertToRaw(welcome.getCurrentContent()));
    // console.log("about us", abouthtml);
    const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/welcome/edit', {content:abouthtml}
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Information',<IntlMessages id="setting.welcome.success.edit"/>);            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      });

  }

  isValidForm = () => {
    return (
    !this.state.error.logo)
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  render(){
    const { classes, theme } = this.props;
    const { error, alowPeriod, storename, storedesc, defcontact } = this.state;
    const title = "Welcome"
    return (
      <div style={{marginTop: theme.spacing(4)}}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="textPrimary" href="/bo/welcome">
              Selamat Datang
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
              <form className={classes.form} noValidate>
                                   
                  
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Editor
                        editorState={this.state.welcome}
                        // toolbarClassName="toolbarClassName"
                        // wrapperClassName="wrapperClassName"
                        // editorClassName="editorClassName"
                        editorClassName={classes.textEditor}
                        onEditorStateChange={this.handleWelcomeChange}
                        placeholder={"Tulis ucapan selamat datang yang akan muncul di beranda"}
                      />
                    </Grid>
                    <Button
                      type="button"
                      className={classes.submit}
                      color="secondary"
                      variant="contained"
                      fullWidth
                      onClick={this.handleWelcomeSubmit}
                      disabled={this.state.disableBtnSubmit}
                    >
                      UPDATE SELAMAT DATANG
                    </Button>
                  </Grid>
                </form>
          </div>
        </Container>

        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );
  }
}

DbWelcomeEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, {deselectParam}
)(withStyles(styles)(withTheme(DbWelcomeEdit)));