import React, { useState, useEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import {
    Link
} from "react-router-dom";
import { useSelector } from "react-redux";
import urlConfig from "./../config/backend";

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function Headerfront() {
    const today = new Date();
    const logoref = useSelector(state => state.logo);
    const [logo, setLogo] = useState(urlConfig.urlBackend + 'bo/int/logo/' + logoref);

    const prevLogo = usePrevious(logoref);
    // console.log("prevLogo", prevLogo, logoref);
    useEffect(() => {
        if (prevLogo !== logoref && logo !== require("../assets/katalok.png")) {
            setLogo(urlConfig.urlBackend + 'bo/int/logo/' + logoref);
        }
    });

    return (
        <Box component="span" m={1}>
            <Link to="/">
                <img id="logo" src={logo} alt="logo"
                    onError={() => setLogo(require("../assets/katalok.png"))}
                    style={{ maxWidth: 150, maxHeight: 80 }} />
            </Link>
        </Box>
    )
}

export default Headerfront