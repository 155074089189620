import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Checkbox, Button, Select, InputLabel,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {Autocomplete} from '@material-ui/lab';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import {ValidateEmail} from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";
import urlConfig from "./../../../../../config/backend";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

function numberWithCommas(x) {
  var parts = x.toString().split(",");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
}

class DbMasterHotmenuEdit extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        form: {
          id:"",
          menuid:""
        },
        error:{
          id:"",
          menuid:""
        },
        previewMenu: {
          name: "",
          description: "",
          categories: "",
          price: "",
          pict: ""
        },
        menupage: {
          keyword: "",
          page: 1,
          count: 10
        },
        menuobject: null,
        menu: [],
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        mode:'add',
        alowPeriod:[]
    };
  }
  

  componentDidMount() {
    // this.loadData();
    let outer = this;
    this.getMenuList();
    if(this.props.param != null){
      let menu = this.props.param.detail;
      this.setState({
        form:{
          ...this.state.form,
          id:menu.id,
          menuid: menu.menuid
        },
        mode:'edit'
      }, () => {
        outer.getEditHotmenu();
      })
    }
  }

  getEditHotmenu() {
    let outer = this;
    axios.post('bo/int/master/menu/get', {id: this.state.form.menuid}).then(({data})=>{
      if(data.st && data.sc === 200){
        outer.setState({
          menuobject:data.data,
          previewMenu: {
            ...this.state.previewMenu,
            name: data.data.name,
            categories: data.data.categories,
            price: data.data.price,
            pict: data.data.pict && urlConfig.urlBackendMenu + data.data.pict
          }
        });
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  inputChangeMenu(val) {
    this.setState({
      menupage: {
        ...this.state.menupage,
        keyword: val
      }
    }, () => {
      this.getMenuList();
    })
  }

  getMenuList() {
    let outer = this;
    axios.post('bo/int/master/menu/list', outer.state.menupage).then(({data})=>{
      if(data.st && data.sc === 200){
        outer.setState({
          menu:data.data.list
        });
      }
    }).catch(function (error) {
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  changeMenu = (event, val) => {
    if(val) {
      this.setState({
        menuobject: val,
        form: {
          ...this.state.form,
          menuid: val.id
        },
        previewMenu: {
          ...this.state.previewMenu,
          name: val.name,
          categories: val.categories,
          price: val.price,
          pict: val.pict && urlConfig.urlBackendMenu + val.pict
        }
      })
    }
  }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir. Silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");  
    } else {
      this.setState({dialogOpen:false});
    }
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  handleValidation = (e) => {
    let {form,error,mode} = this.state;

    if(form.menuid.trim().length === 0){
      error.menuid=<IntlMessages id="master.hotmenu.error.menuid"/>;
    }else{
      error.menuid="";
    }

    if(!this.isValidForm()){
      form.accept=false;
      error.accept="";
    }

    this.setState({
      form:form,
      error:error
    }, () => {

      if(!this.isValidForm()){
        return;
      }
      
      this.handleSubmit();
      
    });

  }

  handleSubmit = () => {
    let {form} = this.state;


    if(this.state.mode == 'add'){

      const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/master/hotmenu/add', form
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              form: {
                id:"",
                menuid:""
              },
              error:{
                menuid:""
              },
              previewMenu: {
                name: "",
                description: "",
                categories: "",
                price: "",
                pict: ""
              },
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="master.hotmenu.success.addhotmenu"/>);
            })
            this.props.history.push("/bo/master/hotmenu/list");
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      });

    }else{
      const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/master/hotmenu/edit', form
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              form: {
                id:"",
                menuid:""
              },
              error:{
                menuid:""
              },
              previewMenu: {
                name: "",
                description: "",
                categories: "",
                price: "",
                pict: ""
              },
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="master.hotmenu.success.addhotmenu"/>);
            });
            this.props.history.push("/bo/master/hotmenu/list");
            this.props.deselectParam();
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      });

    }

  }

  isValidForm = () => {
    return (
    !this.state.error.menuid )
  }

 

  render(){
    const { classes, theme } = this.props;
    const { error, menu, previewMenu } = this.state;
    return (
      <div style={{marginTop: theme.spacing(4)}}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit">
              Master
            </Link>
            <Link color="inherit" href="/bo/master/hotmenu/list">
              Daftar Hot Menu
            </Link>
            <Link color="textPrimary" href="/bo/master/hotmenu/edit">
              {
                this.state.mode == "add" ?
                "Tambah Hot Menu" : "Ubah Hot Menu"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
              <form className={classes.form} noValidate>
                                   
                  
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Autocomplete
                          options={menu}
                          getOptionLabel={option => option.name}
                          value={this.state.menuobject}
                          onChange={this.changeMenu}
                          onInputChange={(event, val) => this.inputChangeMenu(val)}
                          renderInput={
                            param => (
                              <TextField 
                                {...param}
                                id="menu"
                                label="Menu" 
                                fullWidth
                                required
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={error.menuid?true:false}
                              />
                            )
                          }
                        />
                        {
                            error.menuid &&   
                              <FormControlLabel
                                classes={{label:classes.labelerror}}
                                control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                label={error.menuid}
                              />
                        }
                    </Grid>
      
                    {
                      previewMenu.name != "" &&
                      <Grid item xs={12}>
                        <TextField
                          id="name"
                          name="name"
                          label={<IntlMessages id="master.hotmenu.name"/>}
                          value={previewMenu.name} 
                          size="small"
                          fullWidth
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    }

                    {
                      previewMenu.categories != "" &&
                      <Grid item xs={12}>
                        <TextField
                          id="categories"
                          name="categories"
                          label={<IntlMessages id="master.hotmenu.categories"/>}
                          value={previewMenu.categories} 
                          size="small"
                          fullWidth
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    }

                    {
                      previewMenu.price != "" &&
                      <Grid item xs={12}>
                        <TextField
                          id="price"
                          name="price"
                          label={<IntlMessages id="master.hotmenu.price"/>}
                          value={numberWithCommas(previewMenu.price)} 
                          size="small"
                          fullWidth
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    }

      
                    {
                      previewMenu.pict != "" &&
                      <Grid item xs={12}>
                        <InputLabel style={{fontSize: 12}}>
                            Gambar Menu
                        </InputLabel>
                        <img 
                          src={previewMenu.pict}
                          style={{width: 200, marginBottom: 10}}
                        />
                      </Grid>
                    }

      
                  </Grid>
                  <Button
                    type="button"
                    className={classes.submit}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={this.handleValidation}
                    disabled={this.state.disableBtnSubmit}
                  >
                    SUBMIT
                  </Button>
                </form>
          </div>
        </Container>

        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );
  }
}

DbMasterHotmenuEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, {deselectParam}
)(withStyles(styles)(withTheme(DbMasterHotmenuEdit)));