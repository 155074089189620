import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Checkbox, Button, Select, InputLabel,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import {ValidateEmail} from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";
import urlConfig from "./../../../../../config/backend";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

class DbMasterMenuEdit extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        previewMenu: '',
        form: {
          id:"",
          name:"",
          description:"",
          categories:"",
          price: 0,
          pict: null,
          monday: "true",
          tuesday: "true",
          wednesday: "true",
          thursday: "true",
          friday: "true",
          saturday: "true",
          sunday: "true"
        },
        error:{
          id:"",
          name:"",
          categories:"",
          price:""
        },
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        mode:'add',
        alowPeriod:[]
    };
  }

  

  componentDidMount() {
    // this.loadData();
    let outer = this;
    // console.log("food ", this.props.param);
    if(this.props.param != null){
      let food = this.props.param.detail;
      this.setState({
        form:{
          ...this.state.form,
          id:food.id,
          name:food.name,
          description:food.description,
          categories:food.categories,
          price:food.price.toString(),
          monday:food.monday,
          tuesday:food.tuesday,
          wednesday:food.wednesday,
          thursday:food.thursday,
          friday:food.friday,
          saturday:food.saturday,
          sunday:food.sunday
        },
        previewMenu: food.pict != "" ? 
        urlConfig.urlBackendMenu + food.pict : "",
        mode:'edit'
      })
    }
  }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir. Silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");  
    } else {
      this.setState({dialogOpen:false});
    }
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  handleChange = (e) => {
    let outer=this;
    let {form,error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if(inputName === "accept"){
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if(newAccept === true){
        error[inputName]=""
      }
    }

    this.setState({
      form:form,
      error:error
    }, () => {
      switch(inputName){
        case "price":
          if(inputValue){
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
                form[inputName]=inputValue.replace(/.$/,"");
                outer.setState({form:form});
            }
          }
        break; 
        default:
        break;
      }
    });
  }

  handleValidation = (e) => {
    let {form,error,mode} = this.state;

    if(form.name.trim().length === 0){
      error.name=<IntlMessages id="master.menu.error.name"/>;
    }else{
      error.name="";
    }

    if(form.categories.trim().length === 0){
      error.categories=<IntlMessages id="master.menu.error.categories"/>;
    }else{
      error.categories="";
    }

    
    if(form.price == 0){
      error.price=<IntlMessages id="master.menu.error.price"/>;
    }else{
      error.price="";
    }

    // if(form.birthdate.trim().length === 0){
    //   error.birthdate=<IntlMessages id="master.user.error.birthdate"/>;
    // }else{
    //   error.birthdate="";
    // }


    if(!this.isValidForm()){
      form.accept=false;
      error.accept="";
    }

    this.setState({
      form:form,
      error:error
    }, () => {

      if(!this.isValidForm()){
        return;
      }
      
      this.handleSubmit();
      
    });

  }

  handleSubmit = () => {
    let {form} = this.state;
    const formData = new FormData();
    Object.entries(form).map(([key,value]) => {
      if(value != undefined){
        switch (key) {
          default:
            formData.append(key,value)
            break;
        }
      }else{
        formData.append(key,'')
      }
    });


    if(this.state.mode == 'add'){

      const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/master/menu/add', formData
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              form: {
                id:"",
                name:"",
                categories:"",
                description:"",
                price: 0,
                pict: null,
                monday: "true",
                tuesday: "true",
                wednesday: "true",
                thursday: "true",
                friday: "true",
                saturday: "true",
                sunday: "true"
              },
              error:{
                name:"",
                categories:"",
                price:""
              },
              previewMenu: '',
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="master.menu.success.addmenu"/>);
            })
            this.props.history.push("/bo/master/menu/list");
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      });

    }else{
      const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/master/menu/edit', formData
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              form: {
                id:"",
                name:"",
                description:"",
                price:0,
                categories:"",
                pict: null,
                monday: "true",
                tuesday: "true",
                wednesday: "true",
                thursday: "true",
                friday: "true",
                saturday: "true",
                sunday: "true"
              },
              error:{
                name:"",
                categories:"",
                price:""
              },
              previewMenu: '',
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="master.menu.success.addmenu"/>);
            });
            this.props.history.push("/bo/master/menu/list");
            this.props.deselectParam();
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      });

    }

  }

  isValidForm = () => {
    return (
    !this.state.error.name &&     
    !this.state.error.categories &&     
    !this.state.error.price )
  }


  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    // console.log("e detail", e.target.files[0]);

    reader.onloadend = () => {
      // console.log("reader result", reader.result)
      this.setState({
        form: {
          ...this.state.form,
          pict: file 
        },
        previewMenu: reader.result
      });
    }

    reader.readAsDataURL(file)
  }


  handleChangeCat = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        categories: e.target.value
      }
    })
  }


  handleChangeDay = day => e => {
    this.setState({
      form : {
        ...this.state.form,
        [day]: e.target.checked == true ? "true" : "false"
      }
    });
  }

  

 

  render(){
    const { classes, theme } = this.props;
    const { error, alowPeriod } = this.state;
    const title = "Profile"
    return (
      <div style={{marginTop: theme.spacing(4)}}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit">
              Master
            </Link>
            <Link color="inherit" href="/bo/master/menu/list">
              Daftar Menu
            </Link>
            <Link color="textPrimary" href="/bo/master/menu/edit">
              {
                this.state.mode == "add" ?
                "Tambah Menu" : "Ubah Menu"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
              <form className={classes.form} noValidate>
                                   
                  
                  <Grid container spacing={2}>

      
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        name="name"
                        label={<IntlMessages id="master.menu.name"/>}
                        value={this.state.form.name} 
                        size="small"
                        required
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={error.name?true:false}
                      />
                        {
                            error.name &&   
                              <FormControlLabel
                                classes={{label:classes.labelerror}}
                                control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                label={error.name}
                              />
                        }
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="description"
                        name="description"
                        label={<IntlMessages id="master.menu.description"/>}
                        value={this.state.form.description} 
                        size="small"
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
      
                    <Grid item xs={12}>
                        <InputLabel style={{fontSize: 12}} id="formcat">
                          <IntlMessages id="master.menu.categories"/>*
                        </InputLabel>
                        <Select
                          value={this.state.form.categories}
                          onChange={this.handleChangeCat}
                          labelId={"formcat"}
                          fullWidth
                          error={error.categories?true:false}
                        >
                          <MenuItem value="Halal">Halal</MenuItem>
                          <MenuItem value="NonHalal">Non Halal</MenuItem>
                          <MenuItem value="Vegetarian">Vegetarian</MenuItem>
                          <MenuItem value="Misc">Lainnya</MenuItem>

                        </Select>
                        {
                          error.categories &&   
                            <FormControlLabel
                              classes={{label:classes.labelerror}}
                              control={<div>&nbsp;&nbsp;&nbsp;</div>}
                              label={error.categories}
                            />
                        }
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl component="fieldset" className={classes.formControl} style={{width:'100%'}}>
                        <FormLabel component="legend" style={{fontSize:12}}><IntlMessages id="master.menu.day"/></FormLabel>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.form.monday == "true" ? true : false}
                                  onChange={this.handleChangeDay("monday")}
                                  value={this.state.form.monday}
                                />
                              }
                              label="Senin"
                            />
                          </Grid> 
                          <Grid item xs={6} sm={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.form.tuesday == "true" ? true : false}
                                  onChange={this.handleChangeDay("tuesday")}
                                  value={this.state.form.tuesday}
                                />
                              }
                              label="Selasa"
                            />
                          </Grid> 
                          <Grid item xs={6} sm={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.form.wednesday == "true" ? true : false}
                                  onChange={this.handleChangeDay("wednesday")}
                                  value={this.state.form.wednesday}
                                />
                              }
                              label="Rabu"
                            />
                          </Grid> 
                          <Grid item xs={6} sm={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.form.thursday == "true" ? true : false}
                                  onChange={this.handleChangeDay("thursday")}
                                  value={this.state.form.thursday}
                                />
                              }
                              label="Kamis"
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.form.friday == "true" ? true : false}
                                  onChange={this.handleChangeDay("friday")}
                                  value={this.state.form.friday}
                                />
                              }
                              label="Jumat"
                            />
                          </Grid> 
                          <Grid item xs={6} sm={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.form.saturday == "true" ? true : false}
                                  onChange={this.handleChangeDay("saturday")}
                                  value={this.state.form.saturday}
                                />
                              }
                              label="Sabtu"
                            />
                          </Grid> 
                          <Grid item xs={6} sm={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.form.sunday == "true" ? true : false}
                                  onChange={this.handleChangeDay("sunday")}
                                  value={this.state.form.sunday}
                                />
                              }
                              label="Minggu"
                            />
                          </Grid> 
                        </Grid>
                        
                      </FormControl>
                    </Grid>
      
                    <Grid item xs={12}>
                      <TextField
                        id="price"
                        name="price"
                        label={<IntlMessages id="master.menu.price"/>}
                        value={this.state.form.price}
                        size="small"
                        required
                        fullWidth
                        onChange={this.handleChange} 
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={error.price?true:false}
                      />
                        {
                            error.price &&   
                              <FormControlLabel
                                classes={{label:classes.labelerror}}
                                control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                label={error.price}
                              />
                        }
                    </Grid>

                    <Grid item xs={12}>
                      <label>Gambar Menu: </label>
                      <br/>
                      <img 
                        src={this.state.previewMenu}
                        style={{width: 100, marginBottom: 10}}
                      />
                      <br/>
                      <input 
                        type="file"
                        onChange={(e) => this.handleImageChange(e)}
                      />
                    </Grid>
      
                  </Grid>
                  <Button
                    type="button"
                    className={classes.submit}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={this.handleValidation}
                    disabled={this.state.disableBtnSubmit}
                  >
                    SUBMIT
                  </Button>
                </form>
          </div>
        </Container>

        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );
  }
}

DbMasterMenuEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, {deselectParam}
)(withStyles(styles)(withTheme(DbMasterMenuEdit)));