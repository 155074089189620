import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Checkbox, Button, Select, InputLabel,
  MenuItem, Breadcrumbs, Link
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {Autocomplete} from '@material-ui/lab';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import {ValidateEmail} from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";
import urlConfig from "./../../../../../config/backend";
import moment from 'moment';

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import IntlMessages from "../../../../../util/IntlMessages";

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

function numberWithCommas(x) {
  var parts = x.toString().split(",");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
}

class DbMasterNoOrderEdit extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        form: {
          id:"",
          startstr:new Date(),
          start:moment().format('YYYY-MM-DD'),
          endstr:new Date(),
          end: moment().format('YYYY-MM-DD'),
          type:"",
          desc:"",
        },
        error:{
          id:"",
          start:"",
          end:"",
          type:""
        },
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        mode:'add',
        alowPeriod:[]
    };
  }
  

  componentDidMount() {
    // this.loadData();
    let outer = this;
    if(this.props.param != null){
      let noorder = this.props.param.detail;
      this.setState({
        form:{
          ...this.state.form,
          id:noorder.id,
          startstr:new Date(noorder.start),
          start:moment(noorder.start).format('YYYY-MM-DD'),
          endstr:new Date(noorder.end),
          end: moment(noorder.end).format('YYYY-MM-DD'),
          type: noorder.type,
          desc: noorder.desc
        },
        mode:'edit'
      })
    }
  }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir. Silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");  
    } else {
      this.setState({dialogOpen:false});
    }
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  handleValidation = (e) => {
    let {form,error,mode} = this.state;

    // console.log("moment start end", moment(form.startstr), moment(form.endstr));
    if(moment(form.startstr).isAfter(moment(form.endstr))) {
      error.start=<IntlMessages id="master.noorder.error.start"/>;
    } else {
      error.start = "";
    }

    if(moment(form.endstr).isBefore(moment(form.startstr))) {
      error.end=<IntlMessages id="master.noorder.error.end"/>;
    } else {
      error.end = "";
    }
    
    if(form.type.trim().length === 0){
      error.type=<IntlMessages id="master.noorder.error.type"/>;
    }else{
      error.type="";
    }

    if(!this.isValidForm()){
      form.accept=false;
      error.accept="";
    }

    this.setState({
      form:form,
      error:error
    }, () => {

      if(!this.isValidForm()){
        return;
      }
      
      this.handleSubmit();
      
    });

  }

  handleSubmit = () => {
    let {form} = this.state;


    if(this.state.mode == 'add'){

      const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/trans/noorder/add', form
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              form: {
                id:"",
                startstr:new Date(),
                start:moment().format('YYYY-MM-DD'),
                endstr:new Date(),
                end: moment().format('YYYY-MM-DD'),
                type: "",
                desc: ""
              },
              error:{
                id: "",
                start: "",
                end: "",
                type:"",

              },
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="master.noorder.success.addnoorder"/>);
            })
            this.props.history.push("/bo/master/noorder/list");
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      });

    }else{
      const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/trans/noorder/edit', form
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              form: {
                id:"",
                startstr:new Date(),
                start:moment().format('YYYY-MM-DD'),
                endstr:new Date(),
                end: moment().format('YYYY-MM-DD'),
                type: "",
                desc: ""
              },
              error:{
                id: "",
                start:"",
                end:"",
                type:""
              },
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="master.noorder.success.addnoorder"/>);
            });
            this.props.history.push("/bo/master/noorder/list");
            this.props.deselectParam();
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      });

    }

  }

  isValidForm = () => {
    return (
    !this.state.error.start && 
    !this.state.error.end &&
    !this.state.error.type )
  }


  handleChangeType = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        type: e.target.value
      }
    })
  }

  handleChange = (e) => {
    let outer=this;
    let {form,error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if(inputName === "accept"){
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if(newAccept === true){
        error[inputName]=""
      }
    }

    this.setState({
      form:form,
      error:error
    });
  }

 

  render(){
    const { classes, theme } = this.props;
    const { error, menu, previewMenu } = this.state;
    return (
      <div style={{marginTop: theme.spacing(4)}}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit">
              Master
            </Link>
            <Link color="inherit" href="/bo/master/noorder/list">
              Daftar Order Tutup
            </Link>
            <Link color="textPrimary" href="/bo/master/noorder/edit">
              {
                this.state.mode == "add" ?
                "Tambah Order Tutup" : "Ubah Order Tutup"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
              <form className={classes.form} noValidate>
                                   
                  
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl component="fieldset" className={classes.formControl} style={{width:'100%'}}>
                        <FormLabel component="legend" error={error.start?true:false} style={{fontSize:12}}><IntlMessages id="master.noorder.start"/></FormLabel>
                          <KeyboardDatePicker
                            clearable
                            value={this.state.form.startstr}
                            placeholder="10-10-2018"
                            onChange={date => this.setState({
                              form:{
                                ...this.state.form,
                                start:moment(date).format('YYYY-MM-DD'),
                                startstr: date
                              }
                            })}
                            minDate={new Date()}
                            format="DD-MM-YYYY"
                          />
                        {
                              error.start &&   
                                <FormControlLabel
                                  classes={{label:classes.labelerror}}
                                  control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                  label={error.start}
                                />
                          }
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset" className={classes.formControl} style={{width:'100%'}}>
                        <FormLabel component="legend" error={error.end?true:false} style={{fontSize:12}}><IntlMessages id="master.noorder.end"/></FormLabel>
                          <KeyboardDatePicker
                            clearable
                            value={this.state.form.endstr}
                            placeholder="10-10-2018"
                            onChange={date => this.setState({
                              form:{
                                ...this.state.form,
                                endstr:date,
                                end: moment(date).format('YYYY-MM-DD')
                              }
                            })}
                            minDate={new Date()}
                            format="DD-MM-YYYY"
                          />
                        {
                              error.end &&   
                                <FormControlLabel
                                  classes={{label:classes.labelerror}}
                                  control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                  label={error.end}
                                />
                          }
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel style={{fontSize: 12}} id="formtype">
                          <IntlMessages id="master.noorder.type"/>*
                        </InputLabel>
                        <Select
                          value={this.state.form.type}
                          onChange={this.handleChangeType}
                          labelId={"formtype"}
                          fullWidth
                          error={error.type?true:false}
                        >
                          <MenuItem value="Full">Order Penuh</MenuItem>
                          <MenuItem value="Holiday">Libur</MenuItem>
                          <MenuItem value="Other">Lain-lain</MenuItem>

                        </Select>
                        {
                          error.pages &&   
                            <FormControlLabel
                              classes={{label:classes.labelerror}}
                              control={<div>&nbsp;&nbsp;&nbsp;</div>}
                              label={error.pages}
                            />
                        }
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="desc"
                        name="desc"
                        label={<IntlMessages id="master.noorder.desc"/>}
                        value={this.state.form.desc}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

      
                  </Grid>
                  <Button
                    type="button"
                    className={classes.submit}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={this.handleValidation}
                    disabled={this.state.disableBtnSubmit}
                  >
                    SUBMIT
                  </Button>
                </form>
          </div>
        </Container>

        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );
  }
}

DbMasterNoOrderEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, {deselectParam}
)(withStyles(styles)(withTheme(DbMasterNoOrderEdit)));