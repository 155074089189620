import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar, CssBaseline, Drawer, Hidden,
  IconButton, Divider, Toolbar, Typography,
  withStyles, useTheme, withTheme, fade,
  Container, InputBase, MenuItem, Menu,
  Button
} from '@material-ui/core';
import {SearchIcon, ExitToApp} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from "react-redux";

import Dashboardlogo from '../component/dashboardlogo';
import DashboardProfile from '../component/dashboardprofile';
import AdminListItems from '../component/drawerlistadmin';
import PrivateRoute from '../component/privateroute';
import { Redirect } from "react-router-dom";
import DbHomeAdmin from './dbahome';
import DbHomeFt from './dbahomeft';
import DbHomePage from './dbapage';
import DbMasterUser from './pages/master/user/list/index';
import DbMasterUserEdit from './pages/master/user/edit/index';
import DbMasterCustomer from './pages/master/customer/list/index';
import DbMasterCustomerEdit from './pages/master/customer/edit/index';
import DbMasterMenu from './pages/master/menu/list/index';
import DbMasterMenuEdit from './pages/master/menu/edit/index';
import DbMasterItem from './pages/master/item/list/index';
import DbMasterItemEdit from './pages/master/item/edit/index';
import DbMasterSlider from './pages/master/slider/list/index';
import DbMasterSliderEdit from './pages/master/slider/edit/index';
import DbMasterHotmenu from './pages/master/hotmenu/list/index';
import DbMasterHotmenuEdit from './pages/master/hotmenu/edit/index';
import DbMasterAccount from './pages/master/account/list/index';
import DbMasterAccountEdit from './pages/master/account/edit/index';
import DbMasterCategory from './pages/master/categories/list/index';
import DbMasterCategoryEdit from './pages/master/categories/edit/index';
import DbMasterNoOrder from './pages/master/noorder/list/index';
import DbMasterNoOrderEdit from './pages/master/noorder/edit/index';
import DbContact from './pages/master/contact/list/index';
import DbContactEdit from './pages/master/contact/edit/index';
import DbTransactionDiscount from './pages/transaction/discount/list/index';
import DbTransactionDiscountEdit from './pages/transaction/discount/edit/index';
import DbTransactionOrder from './pages/transaction/order/list/index';
import DbTransactionTransfer from './pages/transaction/transfer/list/index';
import DbReportSell from './pages/report/sell/list/index';
import DbReportOmset from './pages/report/omset/list/index';
import DbSettingsEdit from './pages/settings/index';
import DbFirstSetupEdit from './pages/firstsetup/index';
import DbAboutUsEdit from './pages/aboutus/index';
import DbWelcomeEdit from './pages/welcome/index';
import DbProfileEdit from './pages/profile/index';
import {
  BrowserRouter as Router,Route,Switch
} from "react-router-dom";
import axios from '../config/axios';

import { Role } from '../object/role';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    // marginLeft: drawerWidth,
    // [theme.breakpoints.up('sm')]: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    // },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logo:{
    minHeight: 52
  },
  toolbar: {
    padding: '0px 16px 16px 16px',
    overflow: "auto",
    flexGrow: 1
  },
  profile: {
    display: 'flex',
    padding: '16px',
    alignItems: 'center'
  },
  sidebar:{
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  dbcontainer:{
    paddingLeft:0,
    paddingRight:0,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: 'calc(100% - 100)',
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '500px',
    },
    [theme.breakpoints.only('sm')]: {
      marginLeft: '10px',
      width: '350px',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  costumToolbar: {
    minHeight: 52,
    backgroundColor: "#ffbd4a"
  },
  organization: {
    position: 'absolute',
    right: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    color: '#fff' 
  }
});

class DashboardAdmin extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      mobileOpen:false,
      anchorEl: null,
      path: ''
    }
  }

  componentDidMount() {
    if(this.props.location && this.props.location.pathname){
      this.setState({path:this.props.location.pathname});
    }
    // console.log(this.props.location.pathname);
    // let dataprops = this.props.token;
    // axios.defaults.headers.common['Authorization'] = "Bearer " + dataprops.token.tkn;
  }

  componentDidUpdate(){
    if(this.props.location && this.props.location.pathname){
      if(this.state.path != this.props.location.pathname){
        this.setState({path:this.props.location.pathname});
      }
    }
  }

  handleClick = event => {
    // console.log("anchor",event.currentTarget);
    
    // this.setState({anchorEl:event.currentTarget});
    this.props.history.push("/logout")
  };
  
  handleClose = () => {
    this.setState({anchorEl:null});
  };

  validUser() {
    let res = true;
    // if(window){
    //   if (localStorage.getItem("uid") === null || 
    //   localStorage.getItem("email") === null || 
    //   localStorage.getItem("tu") === null || 
    //   localStorage.getItem("pict") === null || 
    //   localStorage.getItem("tkn") === null || 
    //   localStorage.getItem("tknref") === null ) {
    //     localStorage.removeItem("uid");
    //     localStorage.removeItem("email");
    //     localStorage.removeItem("tu");
    //     localStorage.removeItem("pict");
    //     localStorage.removeItem("tkn");
    //     localStorage.removeItem("tknref");
    //     res= false;
    //   }else{
    //     if(localStorage.getItem("tu") == 'User'){
    //       localStorage.removeItem("uid");
    //       localStorage.removeItem("email");
    //       localStorage.removeItem("tu");
    //       localStorage.removeItem("pict");
    //       localStorage.removeItem("tkn");
    //       localStorage.removeItem("tknref");
    //       res= false;
    //     }
    //   }
    // }
    return res;
  }

  handleDrawerToggle = () => {
    this.setState({
      mobileOpen:!this.state.mobileOpen
    })
  };

  render(){
    const { classes, container, theme, content, title } = this.props;
    const { mobileOpen } = this.state;
    const drawer = (
      <div className={classes.sidebar}>
        <div className={classes.toolbar} >
          <div className={classes.logo}>
            <Dashboardlogo refuser="admin" />
          </div>
          <AdminListItems />
        </div>
        <Divider />
        <div className={classes.profile} >
          <DashboardProfile refuser="admin" />
        </div>
      </div>
    );

    if(this.validUser()){
      return (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.costumToolbar}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                {title} 
              </Typography>
              {/* <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div> */}
              {/* <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick} className={classes.organization}>
                  <ExitToApp/>{" "}Logout
              </Button> */}
                {/* <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleClose}>Org 1</MenuItem>
                  <MenuItem onClick={this.handleClose}>Org 2</MenuItem>
                  <MenuItem onClick={this.handleClose}>Org 3</MenuItem>
                </Menu> */}
            </Toolbar>
          </AppBar>
          {this.state.path != '/bo/firstsetup' &&
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          }
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Container component="main" maxWidth="lg" className={classes.dbcontainer}>
              <PrivateRoute exact path="/bo/dashboard" component={DbHomeAdmin} />
              <PrivateRoute exact path="/bo/adminpage" roles={[Role.Admin]} component={DbHomePage} />
              {/* Administrator */}
              <PrivateRoute exact path="/bo/master/user/list" component={DbMasterUser} />
              <PrivateRoute exact path="/bo/master/user/edit" component={DbMasterUserEdit} />
              <PrivateRoute exact path="/bo/master/customer/list" component={DbMasterCustomer} />
              <PrivateRoute exact path="/bo/master/customer/edit" component={DbMasterCustomerEdit} />
              <PrivateRoute exact path="/bo/master/menu/list" component={DbMasterMenu} />
              <PrivateRoute exact path="/bo/master/menu/edit" component={DbMasterMenuEdit} />
              <PrivateRoute exact path="/bo/master/item/list" component={DbMasterItem} />
              <PrivateRoute exact path="/bo/master/item/edit" component={DbMasterItemEdit} />
              <PrivateRoute exact path="/bo/master/slider/list" component={DbMasterSlider} />
              <PrivateRoute exact path="/bo/master/slider/edit" component={DbMasterSliderEdit} />
              <PrivateRoute exact path="/bo/master/hotmenu/list" component={DbMasterHotmenu} />
              <PrivateRoute exact path="/bo/master/hotmenu/edit" component={DbMasterHotmenuEdit} />
              <PrivateRoute exact path="/bo/master/account/list" component={DbMasterAccount} />
              <PrivateRoute exact path="/bo/master/account/edit" component={DbMasterAccountEdit} />
              <PrivateRoute exact path="/bo/master/category/list" component={DbMasterCategory} />
              <PrivateRoute exact path="/bo/master/category/edit" component={DbMasterCategoryEdit} />
              <PrivateRoute exact path="/bo/master/noorder/list" component={DbMasterNoOrder} />
              <PrivateRoute exact path="/bo/master/noorder/edit" component={DbMasterNoOrderEdit} />
              <PrivateRoute exact path="/bo/contact/list" component={DbContact} />
              <PrivateRoute exact path="/bo/contact/edit" component={DbContactEdit} />
              <PrivateRoute exact path="/bo/transaction/discount/list" component={DbTransactionDiscount} />
              <PrivateRoute exact path="/bo/transaction/discount/edit" component={DbTransactionDiscountEdit} />
              <PrivateRoute exact path="/bo/transaction/order/list" component={DbTransactionOrder} />
              <PrivateRoute exact path="/bo/transaction/transfer/list" component={DbTransactionTransfer} />
              <PrivateRoute exact path="/bo/report/sell/list" component={DbReportSell} />
              <PrivateRoute exact path="/bo/report/omset/list" component={DbReportOmset} />
              <PrivateRoute exact path="/bo/settings" component={DbSettingsEdit} />
              <PrivateRoute exact path="/bo/firstsetup" component={DbFirstSetupEdit} />
              <PrivateRoute exact path="/bo/aboutus" component={DbAboutUsEdit} />
              <PrivateRoute exact path="/bo/welcome" component={DbWelcomeEdit} />
              <PrivateRoute exact path="/bo/profile/edit" component={DbProfileEdit} />
              {/* <Route path="/bo/dashadmin">
                <DbHomeAdmin />
              </Route>
              <Route path="/bo/adminpage">
                <DbHomePage />
              </Route>
              <Route path="/bo/master/user/list">
                <DbMasterUser />
              </Route>
              <Route path="/bo/master/user/edit">
                <DbMasterUserEdit />
              </Route> */}
            </Container>
      
          </main>
        </div>
      );
    }else{
      const path="/";
      return <Redirect to={path} />
    }
  }
}

DashboardAdmin.propTypes = {
  classes: PropTypes.object.isRequired,

  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
}
const mapStatestoProps = state => {
  return {
    token: state.token
  };
};

export default connect(
  mapStatestoProps
)(withStyles(styles)(withTheme(DashboardAdmin)));