import React from 'react';
import {
  BrowserRouter as Router,Route,Switch,Redirect
} from "react-router-dom";
import Container from '@material-ui/core/Container';
import Login from './login';
import ForgotPassword from './forgotpassword';
import ForgotPasswordConfirm from './forgotpasswordconfirm';
import Logout from './logout';
import NoMatch from '../component/nomatch';
import DashAdmin from './dashadmin';
import InvalidBrowser from '../component/invalidbrowser';
import BrowserDetection from 'react-browser-detection';
import {IntlProvider} from "react-intl";
import AppLocale from "../lngProvider";
import MomentUtils from '@date-io/moment'; // choose your lib
import {
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { connect } from "react-redux";
import PrivateRoute from '../component/privateroute';
import axios from '../config/axios';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCoffee, faCar, faCamera, faBath, faBeer, faFutbol, faPaw, faChessKnight, faShower, faStar, faTag, faThumbsUp, faUtensils, faAirFreshener, faAward, faBalanceScale, faBicycle, faBook, faBriefcase } from '@fortawesome/free-solid-svg-icons'

library.add(fab, faCoffee, faCar, faCamera, faBath, faBeer, faFutbol, faPaw, faChessKnight, faShower, faStar, faTag, faThumbsUp, faUtensils, faAirFreshener, faAward, faBalanceScale, faBicycle, faBook, faBriefcase)

class App extends React.Component {

  
  componentDidMount() {
    // let dataprops = this.props.token;
    // axios.defaults.headers.common['Authorization'] = "Bearer " + dataprops.token.tkn;
  }

  render() {
    // const {token, location, initURL} = this.props;
    // if (location.pathname === '/') {
    //     if (token === null) {
    //       return ( <Redirect to={'/'}/> );
    //     } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
    //       return ( <Redirect to={'/dashadmin'}/> );
    //     } else {
    //       return ( <Redirect to={initURL}/> );
    //     }
    // }

    const currentAppLocale = AppLocale['id'];
    const MainApp = (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Container>
            <Router>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/forgotpassword" component={ForgotPassword} />
              <Route exact path="/forgotpasswordconfirm" component={ForgotPasswordConfirm} />
              <Route path="/logout" component={Logout} />
              <PrivateRoute path="/bo" component={DashAdmin} />
              <Route path="*" component={NoMatch} />
            </Switch>
          </Router>
          </Container>
        </MuiPickersUtilsProvider>
      </IntlProvider>
    )

    const browserHandler = {
      chrome: () => MainApp,
      firefox: () => MainApp,
      safari: () => MainApp,
      default: (browser) => <InvalidBrowser />,
    };
    
    return (
      <BrowserDetection>
      { browserHandler }
      </BrowserDetection>
    );
  }
}

const mapStatestoProps = state => {
  return {
    token: state.token
  };
};

export default connect(mapStatestoProps)(App);
