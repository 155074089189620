import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Checkbox, Button, Select, InputLabel,
  MenuItem, Breadcrumbs, Link, Radio, RadioGroup
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import { ValidateEmail } from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";
import urlConfig from "./../../../../../config/backend";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";
import { Autocomplete } from '@material-ui/lab';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername: {
    color: '#204196',
    fontWeight: 'bold'
  },
  labelerror: {
    color: '#ff1744',
    fontSize: 10,
    paddingTop: '5px'
  },
  konfirmasi: {
    paddingTop: '10px'
  },
  copyright: {
    paddingBottom: '10px'
  }
});

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

class DbMasterItemEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewItem: null,
      form: {
        id: "",
        name: "",
        description: "",
        hideprice: "false",
        price: 0,
        discount: 0,
        total: 0,
        nego: "false",
        imgpath: null,
        condition: 'Baru',
        category: '',
        available: '',
        special: '',
        active: 'Active',
      },
      error: {
        id: "",
        name: "",
        hideprice: "",
        price: "",
        nego: "",
        condition: "",
        category: "",
        available: "",
        active: "",
      },
      dialogOpen: false,
      dialogTitle: '',
      dialogContent: '',
      disableBtnSubmit: false,
      mode: 'add',
      alowPeriod: [],
      condition: { 'id': 'Baru', 'name': 'Baru' },
      category: null,
      available: null,
      special: null,
      active: { 'id': 'Active', 'name': 'Active' },
      categoryList: [],
      availableList: [],
      specialList: [],
      conditionList: [{ 'id': 'Baru', 'name': 'Baru' }, { 'id': 'Bekas', 'name': 'Bekas' }],
      activeList: [{ 'id': 'Active', 'name': 'Active' }, { 'id': 'Inactive', 'name': 'Inactive' }],
      listImg: []
    };
  }

  componentDidMount() {
    const outer = this;
    (async () => {

      const promiseCategory = outer.loadCategory();
      const promiseAvailable = outer.loadAvailable();
      const promiseSpecial = outer.loadSpecial();

      await Promise.all([promiseCategory, promiseAvailable, promiseSpecial])

      if (outer.props.param != null) {
        outer.loadEditData();
      }
    })();

  }

  loadEditData = () => {
    const { categoryList, availableList, specialList, conditionList, activeList } = this.state;
    let item = this.props.param.detail;

    // console.log(item);

    let category = null;
    for (let i = 0; i < categoryList.length; i++) {
      let elem = categoryList[i];
      if (elem.id == item.catid) {
        category = elem;
        break;
      }
    }

    let available = null;
    for (let i = 0; i < availableList.length; i++) {
      let elem = availableList[i];
      if (elem.id == item.availableid) {
        available = elem;
        break;
      }
    }

    let special = null;
    for (let i = 0; i < specialList.length; i++) {
      let elem = specialList[i];
      if (elem.id == item.specialid) {
        special = elem;
        break;
      }
    }

    let condition = null;
    for (let i = 0; i < conditionList.length; i++) {
      let elem = conditionList[i];
      if (elem.id == item.condition) {
        condition = elem;
        break;
      }
    }

    let active = null;
    for (let i = 0; i < activeList.length; i++) {
      let elem = activeList[i];
      if (elem.id == item.active) {
        active = elem;
        break;
      }
    }

    let listImg = this.state.listImg;
    let basePath = urlConfig.urlBackendItem;
    item.listimg.forEach(elem => {
      let img = { id: elem.id, file: null, byte: basePath + elem.imgpath }
      listImg.push(img);
    });

    this.setState({
      form: {
        ...this.state.form,
        id: item.id,
        name: item.name,
        description: item.description,
        hideprice: item.hideprice,
        nego: item.nego,
        price: item.price,
        discount: item.discount,
        total: item.total,
        condition: item.condition,
        category: item.catid,
        available: item.availableid,
        special: item.specialid ? item.specialid : '',
        active: item.active
      },
      previewItem: item.pict != "" ?
        urlConfig.urlBackendItem + item.imgpath : "",
      mode: 'edit',
      category: category,
      available: available,
      special: special,
      condition: condition,
      active: active,
      listImg: listImg
    });
  }

  loadCategory = () => {
    return new Promise((resolve, reject) => {
      const outer = this;
      axios.post('bo/int/dropdown/category', {}
      ).then(({ data }) => {
        if (data.st && data.sc === 200) {
          outer.setState({ categoryList: data.data }, () => {
            resolve("success");
          });
        } else {
          outer.showDialog('Warning', data.msg);
          reject("failed get data");
        }
      });
    });
  }

  loadAvailable = () => {
    return new Promise((resolve, reject) => {
      const outer = this;
      axios.post('bo/int/dropdown/available', {}
      ).then(({ data }) => {
        if (data.st && data.sc === 200) {
          outer.setState({ availableList: data.data }, () => {
            resolve("success");
          });
        } else {
          outer.showDialog('Warning', data.msg);
          reject("failed get data");
        }
      })
    });
  }

  loadSpecial = () => {
    return new Promise((resolve, reject) => {
      const outer = this;
      axios.post('bo/int/dropdown/special', {}
      ).then(({ data }) => {
        if (data.st && data.sc === 200) {
          outer.setState({ specialList: data.data }, () => {
            resolve("success");
          });
        } else {
          outer.showDialog('Warning', data.msg);
          reject("failed get data");
        }
      })
    });
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  }

  handleDialogOk = () => {
    if (this.state.dialogContent == "Sesi anda telah berakhir. Silahkan login kembali.") {
      this.setState({ dialogOpen: false });
      this.props.history.push("/logout");
    } else {
      this.setState({ dialogOpen: false });
    }
  }

  showDialog = (title, content) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content
    });
  }

  handleChange = (e) => {
    let outer = this;
    let { form, error } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if (inputName === "accept") {
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if (newAccept === true) {
        error[inputName] = ""
      }
    }
    if(inputName === 'hideprice' && inputValue === 'true'){
      if(typeof form.price != 'number' && form.price.trim() == ''){
        form.price = 0;
        form.discount = 0;
        form.total = 0;
      }
      if(typeof form.discount != 'number' && form.discount.trim() == ''){
        form.discount = 0;
      }
    }

    this.setState({
      form: form,
      error: error
    }, () => {
      switch (inputName) {
        case "price":
          if (inputValue) {
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        case "discount":
          if (inputValue) {
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        case "total":
          if (inputValue) {
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
              form[inputName] = inputValue.replace(/.$/, "");
              outer.setState({ form: form });
            }
          }
          break;
        default:
          break;
      }
    });
  }

  handleFocusOut = (e) => {
    let outer = this;
    let { form, error } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;

    if (inputName === "price") {
      let total = inputValue - outer.state.form.discount;
      form['total'] = total
      outer.setState(
        { form: form }
      );
    } else if (inputName === "discount") {
      let total = outer.state.form.price - inputValue;
      form['total'] = total
      outer.setState(
        { form: form }
      );
    }
  }

  handleValidation = (e) => {
    let { form, error, mode } = this.state;

    if (form.name.trim().length === 0) {
      error.name = <IntlMessages id="master.item.error.name" />;
    } else {
      error.name = "";
    }

    if (form.hideprice.trim().length === 0) {
      error.hideprice = <IntlMessages id="master.item.error.hideprice" />;
    } else {
      error.hideprice = "";
    }

    if (form.hideprice == 'false'){
      if (form.price == 0) {
        error.price = <IntlMessages id="master.item.error.price" />;
      } else {
        error.price = "";
      }

      if (form.nego.trim().length === 0) {
        error.nego = <IntlMessages id="master.item.error.nego" />;
      } else {
        error.nego = "";
      }
    }else{
      error.price = "";
      error.nego = "";
    }

    if (!form.category) {
      error.category = <IntlMessages id="master.item.error.category" />;
    } else {
      error.category = "";
    }

    if (!form.condition) {
      error.condition = <IntlMessages id="master.item.error.condition" />;
    } else {
      error.condition = "";
    }

    if (!form.available) {
      error.available = <IntlMessages id="master.item.error.available" />;
    } else {
      error.available = "";
    }

    if (!form.active) {
      error.active = <IntlMessages id="master.item.error.active" />;
    } else {
      error.active = "";
    }

    if (!this.isValidForm()) {
      form.accept = false;
      error.accept = "";
    }

    this.setState({
      form: form,
      error: error
    }, () => {

      if (!this.isValidForm()) {
        return;
      }

      this.handleSubmit();

    });

  }

  handleSubmit = () => {
    const outer = this;
    let { form, category, special, available, active } = this.state;
    const formData = new FormData();

    if (this.state.mode == 'add') {

      formData.append('name', form.name);
      formData.append('description', form.description);
      formData.append('hideprice', form.hideprice);
      formData.append('price', form.price);
      formData.append('disc', form.discount);
      formData.append('total', form.total);
      formData.append('nego', form.nego);
      formData.append('imgpath', form.imgpath);
      formData.append('condition', form.condition);
      formData.append('catid', category.id);
      formData.append('catname', category.name);
      formData.append('alvid', available.id);
      formData.append('spcid', (special) ? special.id : '');
      formData.append('active', active.id);


      this.setState({
        disableBtnSubmit: true
      }, () => {
        axios.post('bo/int/master/item/add', formData
        ).then(({ data }) => {
          if (data.st && data.sc === 200) {
            let itemId = data.msg;

            const formDataImg = new FormData();
            formDataImg.append('id', itemId);

            const listImg = outer.state.listImg;
            listImg.forEach(elem => {
              formDataImg.append('imgname[]', elem.id);
              formDataImg.append('imgfile[]', elem.file);
            });

            axios.post('bo/int/master/item/img/add', formDataImg
            ).then(({ data }) => {
              if (data.st && data.sc === 200) {
                outer.showDialog('Information', <IntlMessages id="master.item.success.additem" />);
                setTimeout(function () {
                  outer.props.history.push("/bo/master/item/list");
                }, 1500);

              } else {
                outer.setState({
                  disableBtnSubmit: false
                });
                outer.showDialog('Warning', data.msg);
              }
            });

          } else {
            outer.setState({
              disableBtnSubmit: false
            });
            outer.showDialog('Warning', data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', Errormsg["500"]);
        });
      });

    } else {

      formData.append('id', form.id);
      formData.append('name', form.name);
      formData.append('description', form.description);
      formData.append('hideprice', form.hideprice);
      formData.append('price', form.price);
      formData.append('disc', form.discount);
      formData.append('total', form.total);
      formData.append('nego', form.nego);
      formData.append('imgpath', form.imgpath);
      formData.append('condition', form.condition);
      formData.append('catid', category.id);
      formData.append('catname', category.name);
      formData.append('alvid', available.id);
      formData.append('spcid', (special) ? special.id : '');
      formData.append('active', active.id);

      this.setState({
        disableBtnSubmit: true
      }, () => {
        axios.post('bo/int/master/item/edit', formData
        ).then(({ data }) => {
          if (data.st && data.sc === 200) {

            const formDataImg = new FormData();
            formDataImg.append('id', form.id);

            const listImg = outer.state.listImg;
            listImg.forEach(elem => {
              formDataImg.append('imgname[]', elem.id);
              formDataImg.append('imgfile[]', elem.file);
            });

            axios.post('bo/int/master/item/img/edit', formDataImg
            ).then(({ data }) => {
              if (data.st && data.sc === 200) {
                outer.showDialog('Information', <IntlMessages id="master.item.success.edititem" />);
                setTimeout(function () {
                  outer.props.deselectParam();
                  outer.props.history.push("/bo/master/item/list");
                }, 1500);

              } else {
                outer.setState({
                  disableBtnSubmit: false
                });
                outer.showDialog('Warning', data.msg);
              }
            });

          } else {
            outer.setState({
              disableBtnSubmit: false
            });
            outer.showDialog('Warning', data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', Errormsg["500"]);
        });
      });

    }

  }

  isValidForm = () => {
    return (
      !this.state.error.name &&
      !this.state.error.hideprice &&
      !this.state.error.price &&
      !this.state.error.nego &&
      !this.state.error.condition &&
      !this.state.error.category &&
      !this.state.error.available &&
      !this.state.error.active
    )
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        form: {
          ...this.state.form,
          imgpath: file
        },
        previewItem: reader.result
      });
    }

    reader.readAsDataURL(file)
  }


  handleImageDetailChange = index => e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    let listImg = this.state.listImg;

    reader.onloadend = () => {
      listImg[index].file = file;
      listImg[index].byte = reader.result;
      this.setState({
        listImg: listImg
      });
    }

    reader.readAsDataURL(file);

  }

  handleImageDetailDelete = index => e => {
    let listImg = this.state.listImg;
    listImg.splice(index, 1);
    this.setState({ listImg: listImg });
  }

  changeDropdown = name => (event, value) => {
    if (value) {
      this.setState({
        [name]: value,
        form: {
          ...this.state.form,
          [name]: value.id
        }
      });
    } else {
      this.setState({
        [name]: null,
        form: {
          ...this.state.form,
          [name]: null
        }
      })
    }
  }

  handleAddImg = () => {
    let listImg = this.state.listImg;
    let elem = { id: 'new', file: null, byte: null };
    listImg.push(elem);
    this.setState({ listImg: listImg });
  }

  render() {
    const { classes, theme } = this.props;
    const { error, alowPeriod, categoryList, availableList, specialList, activeList, conditionList } = this.state;

    let listImgElem = [];

    this.state.listImg.forEach((elem, i) => {
      let imgElem = <div key={"img_" + i} style={{ padding: 10 }}>
        <img
          src={elem.byte}
          style={{ width: 100, marginBottom: 10 }} />
        <br />
        <input
          type="file"
          name="imgfile[]"
          onChange={this.handleImageDetailChange(i)} />
        <button type="button" onClick={this.handleImageDetailDelete(i)} style={{ float: 'right' }}>Hapus Gambar</button>
      </div>
      listImgElem.push(imgElem);
    });

    return (
      <div style={{ marginTop: theme.spacing(4) }}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit">
              Master
            </Link>
            <Link color="inherit" href="/bo/master/item/list">
              Daftar Item
            </Link>
            <Link color="textPrimary" href="/bo/master/item/edit">
              {
                this.state.mode == "add" ?
                  "Tambah Item" : "Ubah Item"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
            <form className={classes.form} noValidate>


              <Grid container spacing={2}>


                <Grid item xs={12}>
                  <TextField
                    id="name"
                    name="name"
                    label={<IntlMessages id="master.item.name" />}
                    value={this.state.form.name}
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.name ? true : false}
                  />
                  {
                    error.name &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.name}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="description"
                    name="description"
                    label={<IntlMessages id="master.item.description" />}
                    value={this.state.form.description}
                    size="small"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl component="fieldset" className={classes.formControl} style={{width:'100%'}}>
                    <FormLabel component="legend" error={error.hideprice?true:false} style={{fontSize:12}}><IntlMessages id="master.item.hideprice"/>*</FormLabel>
                    <RadioGroup aria-label="hideprice" id="hideprice" name="hideprice" value={this.state.form.hideprice} onChange={this.handleChange}>
                      <FormControlLabel value="true" control={<Radio />} label={<IntlMessages id="yes"/>} />
                      <FormControlLabel value="false" control={<Radio />} label={<IntlMessages id="no"/>} />
                    </RadioGroup>
                    {
                      error.hideprice &&   
                        <FormControlLabel
                          classes={{label:classes.labelerror}}
                          control={<div>&nbsp;&nbsp;&nbsp;</div>}
                          label={error.hideprice}
                        />
                    }
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="price"
                    name="price"
                    label={<IntlMessages id="master.item.price" />}
                    value={this.state.form.price}
                    size="small"
                    variant="outlined"
                    required={this.state.form.hideprice == 'false'}
                    fullWidth
                    disabled={this.state.form.hideprice == 'true'}
                    onChange={this.handleChange}
                    onBlur={this.handleFocusOut}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.price ? true : false}
                  />
                  {
                    error.price &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.price}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="discount"
                    name="discount"
                    label={<IntlMessages id="master.item.discount" />}
                    value={this.state.form.discount}
                    size="small"
                    variant="outlined"
                    required={this.state.form.hideprice == 'false'}
                    fullWidth
                    disabled={this.state.form.hideprice == 'true'}
                    onChange={this.handleChange}
                    onBlur={this.handleFocusOut}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error.discount ? true : false}
                  />
                  {
                    error.discount &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.discount}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="total"
                    name="total"
                    label={<IntlMessages id="master.item.total" />}
                    value={this.state.form.total}
                    size="small"
                    variant="outlined"
                    required={this.state.form.hideprice == 'false'}
                    fullWidth
                    disabled={this.state.form.hideprice == 'true'}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    error={error.total ? true : false}
                  />
                  {
                    error.total &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.total}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <FormControl component="fieldset" className={classes.formControl} style={{width:'100%'}}>
                    <FormLabel component="legend" error={error.nego?true:false} style={{fontSize:12}}><IntlMessages id="master.item.nego"/>{this.state.form.hideprice == 'true' ? '' : '*'}</FormLabel>
                    <RadioGroup aria-label="nego" id="nego" name="nego" value={this.state.form.nego} onChange={this.handleChange}>
                      <FormControlLabel value="true" control={<Radio />} label={<IntlMessages id="can"/>} />
                      <FormControlLabel value="false" control={<Radio />} label={<IntlMessages id="cannot"/>} />
                    </RadioGroup>
                    {
                      error.nego &&   
                        <FormControlLabel
                          classes={{label:classes.labelerror}}
                          control={<div>&nbsp;&nbsp;&nbsp;</div>}
                          label={error.nego}
                        />
                    }
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    options={categoryList}
                    getOptionLabel={(option) => option.name}
                    value={this.state.category}
                    onChange={this.changeDropdown("category")}
                    renderInput={
                      param => (
                        <TextField
                          {...param}
                          id="category"
                          label="Kategori"
                          fullWidth
                          variant="outlined"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )
                    }
                  />
                  {
                    error.category &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.category}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    options={conditionList}
                    getOptionLabel={(option) => option.name}
                    value={this.state.condition}
                    onChange={this.changeDropdown("condition")}
                    getOptionSelected={(option, value) => {
                      return (option.id === value.id);
                    }}
                    renderInput={
                      param => (
                        <TextField
                          {...param}
                          id="condition"
                          label="Kondisi"
                          fullWidth
                          variant="outlined"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )
                    }
                  />
                  {
                    error.condition &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.condition}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    options={availableList}
                    getOptionLabel={(option) => option.name}
                    value={this.state.available}
                    onChange={this.changeDropdown("available")}
                    getOptionSelected={(option, value) => {
                      return (option.id === value.id);
                    }}
                    renderInput={
                      param => (
                        <TextField
                          {...param}
                          id="available"
                          label="Ketersediaan"
                          fullWidth
                          variant="outlined"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )
                    }
                  />
                  {
                    error.available &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.available}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    options={specialList}
                    getOptionLabel={(option) => option.name}
                    value={this.state.special}
                    onChange={this.changeDropdown("special")}
                    renderInput={
                      param => (
                        <TextField
                          {...param}
                          id="special"
                          label="Attribut"
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    options={activeList}
                    getOptionLabel={(option) => option.name}
                    value={this.state.active}
                    onChange={this.changeDropdown("active")}
                    getOptionSelected={(option, value) => {
                      return (option.id === value.id);
                    }}
                    renderInput={
                      param => (
                        <TextField
                          {...param}
                          id="active"
                          label="Status Aktif"
                          fullWidth
                          variant="outlined"
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )
                    }
                  />
                  {
                    error.active &&
                    <FormControlLabel
                      classes={{ label: classes.labelerror }}
                      control={<div>&nbsp;&nbsp;&nbsp;</div>}
                      label={error.active}
                    />
                  }
                </Grid>

                <Grid item xs={12}>
                  <label>Gambar Item: </label>
                  <br />
                  <img
                    src={this.state.previewItem}
                    style={{ width: 100, marginBottom: 10 }}
                  />
                  <br />
                  <input
                    type="file"
                    onChange={(e) => this.handleImageChange(e)}
                  />
                </Grid>

                {(this.state.previewItem != null) &&
                  <Grid item xs={12}>
                    <h4>Gambar Tambahan</h4>
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      onClick={this.handleAddImg}
                    >
                      TAMBAH GAMBAR
                    </Button>

                    <div>
                      {listImgElem}
                    </div>

                  </Grid>
                }

              </Grid>
              <Button
                type="button"
                className={classes.submit}
                color="secondary"
                variant="contained"
                fullWidth
                onClick={this.handleValidation}
                disabled={this.state.disableBtnSubmit}
              >
                SUBMIT
                  </Button>
            </form>
          </div>
        </Container>

        <DialogBasic
          open={this.state.dialogOpen}
          title={this.state.dialogTitle}
          content={this.state.dialogContent}
          handleClose={this.handleDialogClose}
          handleOk={this.handleDialogOk}
        />
      </div>
    );
  }
}

DbMasterItemEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, { deselectParam }
)(withStyles(styles)(withTheme(DbMasterItemEdit)));