import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import {IconButton, TextField, Checkbox, Card, FormControl, Chip, Tooltip,
  CardMedia ,CardContent, Typography, InputLabel, Select, MenuItem} from '@material-ui/core';
import {MoodBad, Power, PowerOff} from '@material-ui/icons';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import DialogDetail from '../../../../../component/dialogdetail';
import {compareSequence} from '../../../../../component/helpers';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Errormsg from "../../../../../config/errormsg";
import { Add, Edit, Remove, List } from "@material-ui/icons"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';
import IntlMessages from "../../../../../util/IntlMessages";
import urlConfig from "./../../../../../config/backend";

import DataTable from 'react-data-table-component';

//import actions
import { addParam,deselectParam } from "../../../../../store/actions/configAction";

const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 320,
  },
  complete:{
    color:'#204196',
    fontWeight:'bold'
  },
  incomplete:{
    color:'#ff1744',
    fontWeight:'bold'
  }
});

function numberWithCommas(x) {
  var parts = x.toString().split(",");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
}


class DbMasterHotmenu extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        page:{
          active: "", //Inactive, Active
          keyword: '',
          page:1,
          count:10
        },
        nonact: {},
        hotmenuList:[],
        hotmenuListTotal: 0,
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        dialogMode:'',
        detailOpen:false,
        detailTitle:'Detil Hotmenu',
        detailContent:[],
        selectedRows:[],
      }
  }

  columns = [
    {
      name: 'Action',
      button: true,
      cell: row => {return <div>
        <IconButton color="primary" aria-label="edit" component="span" size="small"  onClick={()=>this.editHotmenu(row)}>
            <Edit />
        </IconButton>
        <IconButton color="primary" aria-label="edit" component="span" size="small"  onClick={()=>this.nonActivate(row)}>
            {
              row.active == "Active" ?
              <Tooltip title="Non Aktifkan">
                <PowerOff />
              </Tooltip> :
              <Tooltip title="Aktifkan">
                <Power />
              </Tooltip>
            }
        </IconButton>
        {/* <List style={{ margin: '5px' }} color="action" /> */}
        {/* <Edit style={{ margin: '5px' }} color="action" /> */}
      </div>},
    },
    {
      name: <IntlMessages id="master.hotmenu.name"/>,
      selector: 'menuname',
      sortable: true,
    },
    {
      name: <IntlMessages id="master.hotmenu.price"/>,
      selector: 'price',
      sortable: true,
      format: row => numberWithCommas(row.price)
    },
    {
      name: <IntlMessages id="master.slider.active"/>,
      selector: 'active',
      sortable: true,
      cell: row => {
        return (
          <Chip label={row.active == "Active" ? "Aktif" : "Tidak Aktif"} color={row.active == "Active" ? "secondary" : "primary"}/> 
        )
      }
    },
  ];

  componentDidMount(){
    this.getHotmenuList();
  }

  showDialog = (title,content,mode) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content,
      dialogMode:mode
    });
  }

  handleDetailClose = () => {
    this.setState({
      detailOpen:false
    })
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen:false
    })
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir. Silahkan login kembali.") {

      this.setState({
        dialogOpen:false
      });
      this.props.history.push("/logout");
    } else {
      this.setState({
        dialogOpen:false
      });
      switch (this.state.dialogMode) {
        case "delete":
          this.handleDeleteHotmenu();
          break;
        case "nonactive":
          this.handleActivate();
          break;
        default:
          break;
      }
    }
  }

  getHotmenuList = () => {
    let outer = this;
    axios.post('bo/int/master/hotmenu/list', outer.state.page).then(({data})=>{
      if(data.st && data.sc === 200){
        outer.setState({
          hotmenuList:data.data.list,
          hotmenuListTotal: data.data.t
        });
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  

  editHotmenu = (row) => {
    this.props.onAddParam({detail:row});
    this.props.history.push("/bo/master/hotmenu/edit");
  }

  nonActivate = (row) => {
    this.setState({
      nonact: row
    }, () => {
      if(row.active == "Active") {
        this.showDialog("Non Aktif Hot Menu", "Anda akan menon-aktifkan menu ini. Apakah Anda yakin ?", "nonactive");
      } else {
        this.showDialog("Aktifkan Hot Menu", "Anda akan mengaktifkan menu ini. Apakah Anda yakin ?", "nonactive");
      }
    })
  }
 
  handleSelectedRows = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    // console.log('Selected Rows: ', state.selectedRows);
    this.setState({selectedRows:state.selectedRows});
  };

  deleteHotmenu = () => {
    let hotmenuLength = this.state.selectedRows.length;
    if(hotmenuLength == 0) {
      this.showDialog("Peringatan","Pilih salah satu item untuk menghapus","");  
    } else {
      this.showDialog("Hapus Hot Menu","Anda akan menghapus "+ hotmenuLength+" hot menu. Apakah anda yakin?","delete");
    }
  }

  handleActivate = () => {
    
    const outer = this;
    const {nonact} = this.state;
    let slidecont = {
      id: nonact.id,
      active: nonact.active == "Active" ? "Inactive" : "Active"
    }
    this.setState({
      disableBtnSubmit:true
    }, () => {
      axios.post('bo/int/master/hotmenu/active', slidecont
      ).then(({data}) => {
        if (data.st && data.sc === 200) {
          outer.setState({
            disableBtnSubmit:false
          }, () => {
            outer.getHotmenuList();
            outer.showDialog('Information',<IntlMessages id="master.hotmenu.success.addhotmenu"/>,"");
          })
          
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',data.msg,"");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
      });
    });
  }
   
  handleDeleteHotmenu = () => {
    
    const outer = this;
    const {selectedRows} = this.state;
    let deleteHotmenu = [];
    selectedRows.forEach((menu)=>{
      deleteHotmenu.push(menu.id);
    })
    
    this.setState({
      disableBtnSubmit:true
    }, () => {
      axios.post('bo/int/master/hotmenu/delete', {id:deleteHotmenu}
      ).then(({data}) => {
        if (data.st && data.sc === 200) {
          outer.setState({
            disableBtnSubmit:false
          }, () => {
            outer.getHotmenuList();
            outer.showDialog('Information',<IntlMessages id="master.hotmenu.success.deletehotmenu"/>,"");
          })
          
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',data.msg,"");
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
      });
    });
  }

  search(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        keyword: val.target.value
      }
    }, () => {
      outer.getHotmenuList();
    })
  }

  onChangeRows(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: 1,
        keyword: "",
        count: val
      }
    }, () => {
      outer.getHotmenuList();
    })
  }

  onChangePage(val) {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        page: val,
        keyword: "",
      }
    }, () => {
      outer.getHotmenuList();
    })
  }

  handleChangeCat = (e) => {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        pages: e.target.value
      }
    }, () => {
      outer.getSliderList();
    })
  }

  handleChangeActive = (e) => {
    let outer = this;
    this.setState({
      page: {
        ...this.state.page,
        active: e.target.value
      }
    }, () => {
      outer.getHotmenuList();
    })
  }
   
  render() {
    const { classes, container, theme, content, title } = this.props;
    const { hotmenuList} = this.state;
      
    return (
      <div style={{marginTop: theme.spacing(4), height: '100%'}}>
         <DataTable
          title="Master Hot Menu"
          columns={this.columns}
          data={hotmenuList}
          noDataComponent={
            <Card style={{maxWidth: 200, borderWidth: 1, borderStyle: 'solid'}}>
              <CardMedia style={{textAlign: 'center'}}>
                <MoodBad color="action" style={{
                  fontSize: 40, 
                  marginTop: 10, 
                  marginBottom: 10
                }}/>
              </CardMedia>
              <CardContent>
                <Typography>
                  No data available
                </Typography>
              </CardContent>
            </Card>
            
          }
          selectableRows
          selectableRowsComponent={Checkbox}
          highlightOnHover
          subHeader
          customStyles={customStyles}
          onSelectedRowsChange={this.handleSelectedRows}
          pagination
          paginationServer
          paginationTotalRows={this.state.userListTotal}
          paginationPerPage={this.state.page.count}
          onChangePage={(val) => this.onChangePage(val)}
          onChangeRowsPerPage={(val) => this.onChangeRows(val)}
          subHeaderComponent={
            (
              <div>
                <div>
                  <FormControl
                    style={{
                      minWidth: '100%',
                      marginBottom: 5
                    }}
                  >
                    <InputLabel style={{fontSize: 12}} id="formact">
                      <IntlMessages id="master.slider.active"/>
                    </InputLabel>
                    <Select
                      value={this.state.page.active}
                      onChange={this.handleChangeActive}
                      labelId={"formcat"}
                      style={{width: '100%'}}
                    >
                      <MenuItem value="">Tampilkan Semua</MenuItem>
                      <MenuItem value="Active">Ya</MenuItem>
                      <MenuItem value="Inactive">Tidak</MenuItem>

                    </Select>
                  </FormControl>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  
                  <TextField 
                    id="outlined-basic" 
                    label="Search" 
                    variant="outlined" 
                    size="small" 
                    style={{ margin: '5px' }}
                    onChange={(val) => this.search(val)} 
                  />
                  <IconButton 
                    color="primary" 
                    aria-label="detail" 
                    component="span" 
                    onClick={()=>{this.props.onDeselectParam();this.props.history.push("/bo/master/hotmenu/edit")}} 
                  >
                      <Add />
                  </IconButton>
                  <IconButton 
                    color="primary" 
                    aria-label="detail" 
                    component="span" 
                    onClick={()=>{this.deleteHotmenu()}} 
                  >
                      <Remove />
                  </IconButton>
                </div>
              </div>
            )
          }
        />

          <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            mode={this.state.dialogMode}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
          />  
          <DialogDetail
            open={this.state.detailOpen} 
            title={this.state.detailTitle}
            content={this.state.detailContent}
            handleClose={this.handleDetailClose}
            handleOk={this.handleDetailClose}
            />  
      </div>
    );
  }
}

DbMasterHotmenu.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    onAddParam: param => dispatch(addParam(param)),
    onDeselectParam: () => dispatch(deselectParam()),
  };
};

export default connect(
  mapStatestoProps,
  dispatchToProps
)(withStyles(styles)(withTheme(DbMasterHotmenu)));