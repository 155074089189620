import React from 'react';
import {Avatar, Badge, Typography, Popover,
List, ListItem, ListItemText, ListItemIcon} from '@material-ui/core';
import {MoreVert, Face, ExitToAppOutlined} from '@material-ui/icons';
import {
    Link
} from "react-router-dom";
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import urlConfig from "../config/backend";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles({
    
    listitem: {
      padding: '2px 8px',
    },
    menu: {
        padding:0
    }
  });
  
function Dashboardlogo(props){
    const classes = useStyles();
    let link = "/bo/dashboard";
    if(props.refuser == 'admin'){
        link = "/bo/dashadmin";
    }
    const token = useSelector(state => state.token.token);
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const handleClick = event => {
     setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseClick = () => {
        setAnchorEl(null);
        props.history.push("/bo/profile/edit");
    }

    const openpop = Boolean(anchorEl);

    return (
        <>
            {/* <Link to={link}> */}
                <Badge onClick={() => props.history.push("/bo/profile/edit")}>
                    <Avatar 
                        // id="logo" 
                        src={
                            token.pict == "" ?
                            require("../assets/blank-profile.png") :
                            urlConfig.urlBackendProfile + token.pict
                        } 
                        width="42px" 
                        height="42px"
                        style={{cursor: 'pointer'}}
                    /> 
                </Badge>
                <div 
                    onClick={() => props.history.push("/bo/profile/edit")} 
                    style={{
                        display:'block', 
                        marginLeft:15,
                        cursor: 'pointer',
                        wordBreak: 'break-word'
                    }}
                >
                    <Typography
                    >
                        <a>{token.email}</a>
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {token.role}
                    </Typography>
                </div>
                <div>
                    <MoreVert onClick={handleClick}/>
                    <Popover
                        open={openpop}
                        onClose={handleClose}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        anchorPosition={
                            {left: 400, top: 200}
                        }
                    >
                        <div style={{width: 150}}>
                            <List className={classes.menu} onClick={handleCloseClick}>
                                <ListItem button key="li-dashadmin" className={classes.listitem}>
                                    <ListItemIcon><Face/></ListItemIcon>
                                    <ListItemText style={{textAlign: 'center'}} primary="Profile"/>
                                </ListItem>
                            </List>
                            <List className={classes.menu} onClick={() => props.history.push("/logout")}>
                                <ListItem button key="li-dashadmin" className={classes.listitem}>
                                    <ListItemIcon><ExitToAppOutlined/></ListItemIcon>
                                    <ListItemText style={{textAlign: 'center'}} primary="Logout"/>
                                </ListItem>
                            </List>
                        </div>

                    </Popover>
                </div>
            {/* </Link> */}
        </>
    )
}

export default withRouter(Dashboardlogo)