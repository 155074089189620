import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Checkbox, Button, Select, InputLabel,
  MenuItem, Breadcrumbs, Link, IconButton
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../../../config/axios';
import DialogBasic from '../../../../../component/dialogbasic';
import { Remove } from "@material-ui/icons"
import {ValidateEmail} from '../../../../../utility/validation';
import Errormsg from "../../../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../../../store/actions/configAction";
import Autocomplete from '@material-ui/lab/Autocomplete';
import urlConfig from "./../../../../../config/backend";

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../../../util/IntlMessages";

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

class DbTransactionDiscountEdit extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        page:{
          categories: '',
          keyword: '',
          page:1,
          count:10
        },
        form: {
          id:"",
          name:"",
          start:new Date(),
          end:new Date(),
          menu: []
        },
        error:{
          id:"",
          name:"",
          start:"",
          end:"",
          menu:""
        },
        menuList:[],
        dialogOpen:false,
        dialogTitle:'',
        dialogContent:'',
        disableBtnSubmit:false,
        mode:'add',
        alowPeriod:[]
    };
  }

  

  componentDidMount() {
    // this.loadData();
    let outer = this;
    this.getMenuList();
    // console.log("food ", this.props.param);
    if(this.props.param != null){
      let discount = this.props.param.detail;
      this.setState({
        form:{
          ...this.state.form,
          id:discount.id,
          name:discount.name,
          start:new Date(discount.start),
          end:new Date(discount.end),
          menu:discount.menu
        },
        mode:'edit'
      })
    }
  }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir. Silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");  
    } else {
      this.setState({dialogOpen:false});
    }
  }

  showDialog = (title,content) => {
    this.setState({
      dialogOpen:true,
      dialogTitle:title,
      dialogContent:content
    });
  }

  handleChange = (e) => {
    let outer=this;
    let {form,error} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    form[inputName] = inputValue;
    if(inputName === "accept"){
      let accept = (inputValue === 'true');
      let newAccept = !accept;
      form[inputName] = newAccept;
      if(newAccept === true){
        error[inputName]=""
      }
    }

    this.setState({
      form:form,
      error:error
    }, () => {
      switch(inputName){
        case "price":
          if(inputValue){
            let regex = /^[0-9]+$/;
            if (!regex.test(inputValue)) {
                form[inputName]=inputValue.replace(/.$/,"");
                outer.setState({form:form});
            }
          }
        break; 
        default:
        break;
      }
    });
  }

  getMenuList = () => {
    let outer = this;
    axios.post('bo/int/master/menu/list', outer.state.page).then(({data})=>{
      if(data.st && data.sc === 200){
        outer.setState({
          menuList: data.data.list,
          menuListTotal: data.data.total
        });
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  handleValidation = (e) => {
    let {form,error,mode} = this.state;

    if(form.name.trim().length === 0){
      error.name=<IntlMessages id="transaction.discount.error.name"/>;
    }else{
      error.name="";
    }

    
    // if(form.birthdate.trim().length === 0){
    //   error.birthdate=<IntlMessages id="master.user.error.birthdate"/>;
    // }else{
    //   error.birthdate="";
    // }
  
    if(!this.isValidForm()){
      form.accept=false;
      error.accept="";
    }

    this.setState({
      form:form,
      error:error
    }, () => {

      if(!this.isValidForm()){
        return;
      }
      
      this.handleSubmit();
      
    });

  }

  handleSubmit = () => {
    let {form} = this.state;
    let menu = form.menu;
    menu.forEach((m)=>{
      m.discountprice = parseFloat(m.discountprice);
    })
    let formData = {
      name: form.name,
      start: formatDate(form.start),
      end: formatDate(form.end),
      menu: menu
    }


    if(this.state.mode == 'add'){

      const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/trans/discount/add', formData
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              form: {
                id:"",
                name:"",
                start:new Date(),
                end:new Date(),
                menu: []
              },
              error:{
                name:"",
                start:"",
                end:"",
                menu:""
              },
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="transaction.discount.success.add"/>);
            })
            this.props.history.push("/bo/transaction/discount/list");
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      });

    }else{
      const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/trans/discount/edit', formData
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              form: {
                id:"",
                name:"",
                start:"",
                end:"",
                menu:[]
              },
              error:{
                name:"",
                start:"",
                end:"",
                menu:""
              },
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="transaction.discount.success.edit"/>);
            });
            this.props.history.push("/bo/transaction/discount/list");
            this.props.deselectParam();
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg);
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"]);
        });
      });

    }

  }

  isValidForm = () => {
    return (
    !this.state.error.name &&     
    !this.state.error.start &&     
    !this.state.error.end &&
    !this.state.error.menu )
  }


  handleChangeCat = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        categories: e.target.value
      }
    })
  }


  handleChangeDay = day => e => {
    this.setState({
      form : {
        ...this.state.form,
        [day]: e.target.checked == true ? "true" : "false"
      }
    });
  }

  handleAddMenu = () => {
    const menu = this.state.form.menu;
    menu.push({menuid:"",menuname:"",menuobj:{},discountprice:0});
    this.setState({form:{...this.state.form,menu}})
  }
 
  inputChangeMenu(val) {
    this.setState({
      page: {
        ...this.state.page,
        keyword: val
      }
    }, () => {
      this.getMenuList();
    })
  }

  changeMenu = (val,i) => {
    if(val) {
      let menu = this.state.form.menu;
      menu[i].menuid = val.id;
      menu[i].menuname = val.name;
      menu[i].menuobj = val;
      menu[i].discountprice = val.price;
      this.setState({
        stobject: val,
        form: {
          ...this.state.form,
          menu
        }
      });
    } else {
      let menu = this.state.form.menu;
      menu[i].menuid = "";
      menu[i].menuname = "";
      menu[i].menuobj = {};
      menu[i].discountprice = 0;
      this.setState({
        stobject: null,
        form: {
          ...this.state.form,
          menu,
        }
      })
    }
  }

  changeDiscPrice = (val,i) =>{
    // console.log("val",val.target.value);
    if(val != '') {
      let menu = this.state.form.menu;
      menu[i].discountprice = val.target.value;
      this.setState({
        stobject: val,
        form: {
          ...this.state.form,
          menu
        }
      });
    } else {
      let menu = this.state.form.menu;
      menu[i].discountprice = 0;
      this.setState({
        stobject: null,
        form: {
          ...this.state.form,
          menu,
        }
      })
    }

  }
  
  deleteMenu = (i) =>{
    let menu = this.state.form.menu;
    menu.splice(i,1);
    this.setState({
      stobject: null,
      form: {
        ...this.state.form,
        menu,
      }
    })
  }
  
  render(){
    const { classes, theme } = this.props;
    const { error, alowPeriod, form, menuList} = this.state;
    const title = "Profile"
    const menus = form.menu.map((menu,i)=>{
      return <>
      <Grid item xs={5} md={5}>
        <Autocomplete
          options={menuList}
          getOptionLabel={option => option.name}
          value={menu.menuobj}
          onChange={(event,val) => this.changeMenu(val,i)}
          onInputChange={(event, val) => this.inputChangeMenu(val)}
          onFocus={(event, val) => this.inputChangeMenu(val)}
          renderInput={
            param => (
              <TextField 
                {...param}
                id="servicetype" 
                fullWidth
                required
                label={<IntlMessages id="transaction.discount.menu"/>}
                InputLabelProps={{
                  shrink: true,
                }}
                error={error.servicetypeid?true:false}
              />
            )
          }
        />
      </Grid>
      <Grid item xs={5} md={5}>
        <TextField
        id="address"
        name="address"
        label={<IntlMessages id="transaction.discount.discprice"/>}
        value={menu.discountprice}
        size="small"
        fullWidth
        onChange={(e) => this.changeDiscPrice(e,i)}
        InputLabelProps={{
          shrink: true,
        }}
        // error={error.address?true:false}
      />
      </Grid>
      <Grid item xs={2} md={2}>
        <IconButton color="primary" aria-label="detail" component="span" style={{marginRight:5}} onClick={()=>this.deleteMenu(i)}>
            <Remove />
        </IconButton>
      </Grid>
      </>
    })
    return (
      <div style={{marginTop: theme.spacing(4)}}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit">
              Master
            </Link>
            <Link color="inherit" href="/bo/transaction/discount/list">
              Daftar Diskon
            </Link>
            <Link color="textPrimary" href="/bo/transaction/discount/edit">
              {
                this.state.mode == "add" ?
                "Tambah Diskon" : "Ubah Diskon"
              }
            </Link>
          </Breadcrumbs>
          <div className={classes.paper}>
              <form className={classes.form} noValidate>
                                   
                  
                  <Grid container spacing={2}>

      
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        name="name"
                        label={<IntlMessages id="transaction.discount.name"/>}
                        value={this.state.form.name} 
                        size="small"
                        required
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={error.name?true:false}
                      />
                        {
                            error.name &&   
                              <FormControlLabel
                                classes={{label:classes.labelerror}}
                                control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                label={error.name}
                              />
                        }
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl component="fieldset" className={classes.formControl} style={{width:'100%'}}>
                        <FormLabel component="legend" error={error.start?true:false} style={{fontSize:12}}><IntlMessages id="transaction.discount.start"/></FormLabel>
                          <KeyboardDatePicker
                            clearable
                            value={this.state.form.start}
                            placeholder="10-10-2018"
                            onChange={date => this.setState({form:{...this.state.form,start:date}})}
                            minDate={new Date()}
                            format="DD-MM-YYYY"
                          />
                        {
                              error.start &&   
                                <FormControlLabel
                                  classes={{label:classes.labelerror}}
                                  control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                  label={error.start}
                                />
                          }
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl component="fieldset" className={classes.formControl} style={{width:'100%'}}>
                        <FormLabel component="legend" error={error.start?true:false} style={{fontSize:12}}><IntlMessages id="transaction.discount.end"/></FormLabel>
                          <KeyboardDatePicker
                            clearable
                            value={this.state.form.end}
                            placeholder="10-10-2018"
                            onChange={date => this.setState({form:{...this.state.form,end:date}})}
                            minDate={new Date()}
                            format="DD-MM-YYYY"
                          />
                        {
                              error.end &&   
                                <FormControlLabel
                                  classes={{label:classes.labelerror}}
                                  control={<div>&nbsp;&nbsp;&nbsp;</div>}
                                  label={error.start}
                                />
                          }
                      </FormControl>
                    </Grid>
                    {menus}
                    <Grid item xs={12}>
                      <Button
                        type="button"
                        className={classes.submit}
                        color="primary"
                        variant="outlined"
                        fullWidth
                        onClick={this.handleAddMenu}
                        // disabled={this.state.disableBtnSubmit}
                        >
                        <IntlMessages id="transaction.discount.addmenu"/>
                      </Button>
                    </Grid>
                  </Grid>
                  <Button
                    type="button"
                    className={classes.submit}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={this.handleValidation}
                    disabled={this.state.disableBtnSubmit}
                  >
                    SUBMIT
                  </Button>
                </form>
          </div>
        </Container>

        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );
  }
}

DbTransactionDiscountEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, {deselectParam}
)(withStyles(styles)(withTheme(DbTransactionDiscountEdit)));