import React from 'react';
import {
  CssBaseline, Grid, Container, TextField,
  FormControlLabel, FormControl, FormLabel,
  Radio, RadioGroup, Button, Select, Input, InputAdornment, IconButton,
  MenuItem, Breadcrumbs, Link, Stepper, StepLabel, Step, Form, InputLabel,
  Card, CardMedia, CardContent, Typography, Checkbox, Tabs, Tab, List
} from '@material-ui/core';
import {MoodBad, Power, PowerOff, ArrowUpward, ArrowDownward, Edit, Delete} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/styles';
import axios from '../../../config/axios';
import DialogBasic from '../../../component/dialogbasic';
import Errormsg from "../../../config/errormsg";
import PasswordField from 'material-ui-password-field';
import { connect } from "react-redux";
import { deselectParam } from "../../../store/actions/configAction";
import urlConfig from "./../../../config/backend";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DataTable from 'react-data-table-component';
import { Autocomplete } from '@material-ui/lab';

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import IntlMessages from "../../../util/IntlMessages";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


const customStyles = {
  tableWrapper: {
    style: {
      display: "inline"
    }
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
};

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelusername:{
    color:'#204196',
    fontWeight:'bold'
  },
  labelerror:{
    color:'#ff1744',
    fontSize:10,
    paddingTop:'5px'
  },
  konfirmasi :{
    paddingTop:'10px'
  },
  copyright:{
    paddingBottom:'10px'
  }
});

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function numberWithCommas(x) {
  var parts = x.toString().split(",");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(",");
}

class DbFirstSetupEdit extends React.Component {
  constructor(props){
      super(props);
      const today = new Date();
      this.state = {
        previewLogo: urlConfig.urlBackend + 'bo/int/logo/' + today.getTime(),
        logo:null,
        storename:"",
        storedesc:"",
        defcontact:"",
        page:{
          keyword: '',
          page:1,
          count:10
        },
        itempage:{
          keyword: '',
          page:1,
          count:10
        },
        catpage:{
          keyword: '',
          page:1,
          count:10
        },
        conform: {
          id:"",
          type: "",
          value: ""
        },
        catform: {
          id:"",
          name: "",
          desc: "",
          icon: ""
        },
        form: {
          id: "",
          name: "",
          description: "",
          hideprice: "false",
          price: 0,
          discount: 0,
          total: 0,
          nego: "false",
          imgpath: null,
          condition: 'Baru',
          category: '',
          available: '',
          special: '',
          active: 'Active',
        },
        error:{
          id:"",
          logo:"",
          name:"",
          desc:"",
          defcontact:"",
          catname: ""
        },
        contactList:[],
        contactListTotal:0,
        editContact:false,
        categoryList:[],
        categoryListTotal:0,
        itemList: [],
        itemListTotal: 0,
        activeStep:0,
        activeTab:0,
        dialogOpen:false,
        deleteid:'',
        dialogTitle:'',
        dialogMode:'',
        dialogContent:'',
        disableBtnSubmit:false,
        mode:'add',
        alowPeriod:[],
        showPassword:false,
        active: { 'id': 'Active', 'name': 'Active' },
        condition: { 'id': 'Baru', 'name': 'Baru' },
        category: null,
        available: null,
        special: null,
        previewItem: null,
        availableList: [],
        specialList: [],
        conditionList: [{ 'id': 'Baru', 'name': 'Baru' }, { 'id': 'Bekas', 'name': 'Bekas' }],
        activeList: [{ 'id': 'Active', 'name': 'Active' }, { 'id': 'Inactive', 'name': 'Inactive' }],
        listImg: []
    };
    
  }

  columns = [
    {
      name: 'Action',
      button: true,
      cell: row => {return <div>
        <IconButton color="primary" aria-label="edit" component="span" size="small"  onClick={()=>this.deleteItem(row)}>
            <Delete />
        </IconButton>
      </div>},
    },
    {
      name: <IntlMessages id="master.contact.type"/>,
      selector: 'type',
      sortable: true,
    },
    {
      name: <IntlMessages id="master.contact.contact"/>,
      selector: 'value',
      sortable: true
    },
  ];

  catcolumns = [
    {
      name: 'Action',
      button: true,
      cell: row => {return <div>
        <IconButton color="primary" aria-label="edit" component="span" size="small"  onClick={()=>this.deleteItem(row)}>
            <Delete />
        </IconButton>
      </div>},
    },
    {
      name: <IntlMessages id="master.category.name"/>,
      selector: 'name',
      sortable: true,
    },
    {
      name: <IntlMessages id="master.category.description"/>,
      selector: 'description',
      sortable: true
    },
    {
      name: <IntlMessages id="master.category.icon"/>,
      selector: 'icon',
      cell: row => <FontAwesomeIcon icon={row.icon}/>,
    },
  ];

  itemcolumns = [
    {
      name: 'Action',
      button: true,
      cell: row => {
        return <div>
          <IconButton color="primary" aria-label="detail" component="span" size="small" style={{ marginRight: 5 }} onClick={() => this.openUserDetail(row)}>
            <List />
          </IconButton>
          <IconButton color="primary" aria-label="edit" component="span" size="small" onClick={() => this.deleteItem(row)}>
            <Delete />
          </IconButton>
          {/* <List style={{ margin: '5px' }} color="action" /> */}
          {/* <Edit style={{ margin: '5px' }} color="action" /> */}
        </div>
      },
    },
    {
      name: <IntlMessages id="master.item.name" />,
      selector: 'name',
      sortable: true,
    },
    {
      name: <IntlMessages id="master.item.total" />,
      selector: 'total',
      sortable: true,
      format: row => (row.hideprice == 'true') ? 'Disembunyikan' : numberWithCommas(row.total)
      // hide: 'sm',
    },
  ];

  loadData(){
    const outer = this;
    // axios.defaults.headers.common['Authorization'] ='Bearer '+window.localStorage.getItem("tkn");
    
    axios.post('bo/int/storename', {}
    ).then(({data}) => {
      if (data.st && data.sc === 200) {
        outer.setState({
          storename:data.data.name,
          storedesc:data.data.desc
        })
      }
    }).catch(function (error) {
      outer.showDialog('Warning',Errormsg["500"]);
    });

    axios.post('bo/int/defcontact', {}
    ).then(({data}) => {
      if (data.st && data.sc === 200) {
        outer.setState({
          defcontact:data.data
        })
      }
    }).catch(function (error) {
      outer.showDialog('Warning',Errormsg["500"]);
    });
  }

  componentDidMount() {
    this.loadData();
    this.getContactList();
    this.getCategoryList();
    this.getItemList();
    this.loadAvailable();
    this.loadSpecial();
  }

  loadAvailable = () => {
    return new Promise((resolve, reject) => {
      const outer = this;
      axios.post('bo/int/dropdown/available', {}
      ).then(({ data }) => {
        if (data.st && data.sc === 200) {
          outer.setState({ availableList: data.data }, () => {
            resolve("success");
          });
        } else {
          outer.showDialog('Warning', data.msg);
          reject("failed get data");
        }
      })
    });
  }

  loadSpecial = () => {
    return new Promise((resolve, reject) => {
      const outer = this;
      axios.post('bo/int/dropdown/special', {}
      ).then(({ data }) => {
        if (data.st && data.sc === 200) {
          outer.setState({ specialList: data.data }, () => {
            resolve("success");
          });
        } else {
          outer.showDialog('Warning', data.msg);
          reject("failed get data");
        }
      })
    });
  }

  handleDialogClose = () => {
    this.setState({dialogOpen:false});
  }

  handleDialogOk = () => {
    if(this.state.dialogContent == "Sesi anda telah berakhir. Silahkan login kembali.") {
      this.setState({dialogOpen:false});
      this.props.history.push("/logout");  
    } else {
      this.setState({
        dialogOpen: false
      });
      switch (this.state.dialogMode) {
        case "delete":
          this.handleDeleteItem();
          break;

        default:
          break;
      }
    }
  }

  showDialog = (title, content, mode) => {
    this.setState({
      dialogOpen: true,
      dialogTitle: title,
      dialogContent: content,
      dialogMode: mode
    });
  }

  deleteItem = (row) => {
    let {activeStep} = this.state;
    let outer = this;
    this.setState({deleteid:row.id},()=>{
      if(activeStep == 1){
        outer.showDialog("Hapus Kontak", "Anda akan menghapus " + row.type +" dengan nomor "+ row.value + ". Apakah anda yakin?", "delete");
      }else if(activeStep == 2){
        outer.showDialog("Hapus Kategori", "Anda akan menghapus " + row.name + ". Apakah anda yakin?", "delete");
      }else if(activeStep == 3){
        outer.showDialog("Hapus Item", "Anda akan menghapus " + row.name + ". Apakah anda yakin?", "delete");
      }
    })
  }

  handleDeleteItem = () => {

    const outer = this;
    const { deleteid, activeStep } = this.state;
    let deleteItem = [];
    // selectedRows.forEach((item) => {
      deleteItem.push(deleteid);
    // });
    if(activeStep == 1){
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/master/contact/delete', {id:deleteItem}
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              disableBtnSubmit:false
            }, () => {
              outer.getContactList()
              outer.showDialog('Information',<IntlMessages id="master.contact.success.delete"/>,"");
            })
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg,"");
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"],"");
        });
      });
    }else if(activeStep == 2){
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/master/category/delete', {id:deleteItem}
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              disableBtnSubmit:false
            }, () => {
              outer.getCategoryList();
              outer.showDialog('Information',<IntlMessages id="master.category.success.delete"/>,"");
            })
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg,"");
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"],"");
        });
      });
    }else if(activeStep == 3){
      this.setState({
        disableBtnSubmit: true
      }, () => {
        axios.post('bo/int/master/item/delete', { id: deleteItem }
        ).then(({ data }) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              disableBtnSubmit: false
            }, () => {
              outer.getItemList();
              outer.showDialog('Information', <IntlMessages id="master.item.success.deleteitem" />, "");
            })
  
          } else {
            outer.setState({
              disableBtnSubmit: false
            });
            outer.showDialog('Warning', data.msg, "");
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', Errormsg["500"], "");
        });
      });
    }
  }

  handleChange = (e) => {
    let outer=this;
    let {conform,catform,error,activeStep, form} = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;
    if(activeStep == 1){
      conform[inputName] = inputValue;  
      this.setState({
        conform:conform,
        error:error
      });
    }else if(activeStep == 2){
      catform[inputName] = inputValue;  
      this.setState({
        catform:catform,
        error:error
      });
    }else if(activeStep == 3){
      form[inputName] = inputValue;
      if (inputName === "accept") {
        let accept = (inputValue === 'true');
        let newAccept = !accept;
        form[inputName] = newAccept;
        if (newAccept === true) {
          error[inputName] = ""
        }
      }

      this.setState({
        form: form,
        error: error
      }, () => {
        switch (inputName) {
          case "price":
            if (inputValue) {
              let regex = /^[0-9]+$/;
              if (!regex.test(inputValue)) {
                form[inputName] = inputValue.replace(/.$/, "");
                outer.setState({ form: form });
              }
            }
            break;
          case "discount":
            if (inputValue) {
              let regex = /^[0-9]+$/;
              if (!regex.test(inputValue)) {
                form[inputName] = inputValue.replace(/.$/, "");
                outer.setState({ form: form });
              }
            }
            break;
          case "total":
            if (inputValue) {
              let regex = /^[0-9]+$/;
              if (!regex.test(inputValue)) {
                form[inputName] = inputValue.replace(/.$/, "");
                outer.setState({ form: form });
              }
            }
            break;
          default:
            break;
        }
      });
    }
  }

  handleNameChange = (e) => {
    this.setState({storename:e.target.value});
  }
  
  handleDescChange = (e) => {
    this.setState({storedesc:e.target.value});
  }

  handleContactChange = (e) => {
    this.setState({defcontact:e.target.value});
  }

  handleValidation = (e) => {
    let {logo,error,mode, activeStep, catform, form} = this.state;

    if(activeStep == 0){
      if(logo === null){
        error.logo="Harap masukan logo";
      }else{
        error.logo="";
      }
  
      if(!this.isValidForm()){
        error.accept="";
      }
  
      this.setState({
        error:error
      }, () => {
  
        if(!this.isValidForm()){
          return;
        }
        
        this.handleSubmit();
        
      });  
    }else if(activeStep == 2){

      if(catform.name.trim().length === 0){
        error.catname=<IntlMessages id="master.category.error.name"/>;
      }else{
        error.nacatnamee="";
      }

      this.setState({
        catform:catform,
        error:error
      }, () => {

        if(!this.isValidForm()){
          return;
        }
        
        this.handleSubmit();
        
      });
    }else if(activeStep == 3){
      if (form.name.trim().length === 0) {
        error.name = <IntlMessages id="master.item.error.name" />;
      } else {
        error.name = "";
      }

      if (form.hideprice.trim().length === 0) {
        error.hideprice = <IntlMessages id="master.item.error.hideprice" />;
      } else {
        error.hideprice = "";
      }

      if (form.hideprice == 'false'){
        if (form.price == 0) {
          error.price = <IntlMessages id="master.item.error.price" />;
        } else {
          error.price = "";
        }

        if (form.nego.trim().length === 0) {
          error.nego = <IntlMessages id="master.item.error.nego" />;
        } else {
          error.nego = "";
        }
      }

      if (!form.category) {
        error.category = <IntlMessages id="master.item.error.category" />;
      } else {
        error.category = "";
      }

      if (!form.condition) {
        error.condition = <IntlMessages id="master.item.error.condition" />;
      } else {
        error.condition = "";
      }

      if (!form.available) {
        error.available = <IntlMessages id="master.item.error.available" />;
      } else {
        error.available = "";
      }

      if (!form.active) {
        error.active = <IntlMessages id="master.item.error.active" />;
      } else {
        error.active = "";
      }

      if (!this.isValidForm()) {
        form.accept = false;
        error.accept = "";
      }

      this.setState({
        form: form,
        error: error
      }, () => {

        if (!this.isValidForm()) {
          return;
        }

        this.handleSubmit();

      });
    }

  }

  handleSubmit = () => {
    let {logo, catform, activeStep, form, category, active, available, special} = this.state;
    const outer = this;
    if(activeStep == 0){
      const formData = new FormData();
      formData.append('logo',logo);
        this.setState({
          disableBtnSubmit:true
        }, () => {
          axios.post('bo/int/logo/edit', formData
          ).then(({data}) => {
            if (data.st && data.sc === 200) {
              outer.setState({
                disableBtnSubmit:false
              });
              outer.showDialog('Information',<IntlMessages id="setting.logo.success.edit"/>,"");            
            } else {
              outer.setState({
                disableBtnSubmit:false
              });
              outer.showDialog('Warning',data.msg,"");
            }
          }).catch(function (error) {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',Errormsg["500"],"");
          });
        });  
    }else if(activeStep == 2){
      const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/master/category/add', catform
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              catform: {
                id:"",
                name: "",
                desc: "",
                icon: ""
              },
              error:{
                name: ""
              },
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="master.category.success.add"/>,"");
            })
            outer.getCategoryList();
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg,"");
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"],"");
        });
      });
    }else if(activeStep == 3){
      const formData = new FormData();
      formData.append('name', form.name);
      formData.append('description', form.description);
      formData.append('hideprice', form.hideprice);
      formData.append('price', form.price);
      formData.append('disc', form.discount);
      formData.append('total', form.total);
      formData.append('nego', form.nego);
      formData.append('imgpath', form.imgpath);
      formData.append('condition', form.condition);
      formData.append('catid', category.id);
      formData.append('catname', category.name);
      formData.append('alvid', available.id);
      formData.append('spcid', (special) ? special.id : '');
      formData.append('active', active.id);

      this.setState({
        disableBtnSubmit: true
      }, () => {
        axios.post('bo/int/master/item/add', formData
        ).then(({ data }) => {
          if (data.st && data.sc === 200) {
            let itemId = data.msg;

            const formDataImg = new FormData();
            formDataImg.append('id', itemId);

            const listImg = outer.state.listImg;
            listImg.forEach(elem => {
              formDataImg.append('imgname[]', elem.id);
              formDataImg.append('imgfile[]', elem.file);
            });

            axios.post('bo/int/master/item/img/add', formDataImg
            ).then(({ data }) => {
              if (data.st && data.sc === 200) {
                outer.setState({
                  form: {
                    id: "",
                    name: "",
                    description: "",
                    hideprice: "false",
                    price: 0,
                    discount: 0,
                    total: 0,
                    nego: "false",
                    imgpath: null,
                    condition: 'Baru',
                    category: '',
                    available: '',
                    special: '',
                    active: 'Active',
                  },
                  active: { 'id': 'Active', 'name': 'Active' },
                  condition: { 'id': 'Baru', 'name': 'Baru' },
                  category: null,
                  available: null,
                  special: null,
                  previewItem: null,
                  disableBtnSubmit:false
                });
                outer.showDialog('Information', <IntlMessages id="master.item.success.additem" />,"");
                outer.getItemList();                

              } else {
                outer.setState({
                  disableBtnSubmit: false
                });
                outer.showDialog('Warning', data.msg,"");
              }
            });

          } else {
            outer.setState({
              disableBtnSubmit: false
            });
            outer.showDialog('Warning', data.msg,"");
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit: false
          });
          outer.showDialog('Warning', Errormsg["500"],"");
        });
      });
    }

  }

  handleStoreNameValidation = (e) => {
    let {storename,error,mode} = this.state;

    if(storename.trim().length === 0){
      error.storename="Harap masukan nama usaha";
    }else{
      error.storename="";
    }

    if(!this.isStoreNameValidForm()){
      error.accept="";
    }

    this.setState({
      error:error
    }, () => {

      if(!this.isStoreNameValidForm()){
        return;
      }
      
      this.handleStoreNameSubmit();
      
    });

  }

  handleStoreNameSubmit = () => {
    let {storename, storedesc} = this.state;
    const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/storename/edit', {name:storename, desc:storedesc}
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Information',<IntlMessages id="setting.storename.success.edit"/>,"");            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg,"");
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"],"");
        });
      });

  }

  editContact = (row) => {
    this.setState({
      conform:{
        type:row.type,
        value:row.value
      },
      editContact:true
    });
  }

  handleContactValidation = (e) => {
    let {defcontact,error,mode} = this.state;

    if(defcontact.trim().length === 0){
      error.defcontact="Harap masukan telepon";
    }else{
      error.defcontact="";
    }

    if(!this.isContactValidForm()){
      error.accept="";
    }

    this.setState({
      error:error
    }, () => {

      if(!this.isContactValidForm()){
        return;
      }
      
      this.handleContactSubmit();
      
    });

  }

  handleContactSubmit = () => {
    let {defcontact} = this.state;
    const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/defcontact/edit', {contact:defcontact}
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Information',<IntlMessages id="setting.contact.success.edit"/>,"");            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg,"");
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"],"");
        });
      });

  }

  getContactList = () => {
    let outer = this;
    axios.post('bo/int/master/contact/list', outer.state.page).then(({data})=>{
      if(data.st && data.sc === 200){
        outer.setState({
          contactList:data.data.list,
          contactListTotal: data.data.total
        });
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  handleStoreContactValidation = (e) => {
    let {conform,error,mode} = this.state;

    if(conform.type.trim().length === 0){
      error.type=<IntlMessages id="master.contact.error.type"/>;
    }else{
      error.type="";
    }

    if(conform.value.trim().length === 0){
      error.value=<IntlMessages id="master.contact.error.contact"/>;
    }else{
      error.value="";
    }

    this.setState({
      conform:conform,
      error:error
    }, () => {

      if(!this.isStoreContactValidForm()){
        return;
      }
      
      this.handleStoreContactSubmit();
      
    });

  }

  handleStoreContactSubmit = () => {
    let {conform} = this.state;


    if(!this.state.editContact){

      const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/master/contact/add', conform
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              conform: {
                id:"",
                type:"",
                value:""
              },
              error:{
                type:"",
                value:""
              },
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="master.contact.success.add"/>,"");
              outer.getContactList();
            })
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg,"");
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"],"");
        });
      });

    }else{
      const outer = this;
      this.setState({
        disableBtnSubmit:true
      }, () => {
        axios.post('bo/int/master/contact/edit', conform
        ).then(({data}) => {
          if (data.st && data.sc === 200) {
            outer.setState({
              conform: {
                id:"",
                type:"",
                value:""
              },
              error:{
                type:"",
                value:""
              },
              disableBtnSubmit:false
            }, () => {
              outer.showDialog('Information',<IntlMessages id="master.contact.success.add"/>,"");
              outer.getContactList();
            });
            
          } else {
            outer.setState({
              disableBtnSubmit:false
            });
            outer.showDialog('Warning',data.msg,"");
          }
        }).catch(function (error) {
          outer.setState({
            disableBtnSubmit:false
          });
          outer.showDialog('Warning',Errormsg["500"],"");
        });
      });

    }

  }

  getCategoryList = () => {
    let outer = this;
    axios.post('bo/int/master/category/list', outer.state.page).then(({data})=>{
      if(data.st && data.sc === 200){
        outer.setState({
          categoryList:data.data.list,
          categoryListTotal: data.data.total
        });
      }
    }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        outer.showDialog('Warning',Errormsg["500"],"");
    });
  }

  getItemList = () => {
    let outer = this;
    axios.post('bo/int/master/item/list', outer.state.page).then(({ data }) => {
      if (data.st && data.sc === 200) {
        data.data.list.forEach((item) => {
          item.pricestr = numberWithCommas(item.price);
          item.discstr = numberWithCommas(item.discount);
          item.totalstr = numberWithCommas(item.total);
        });
        outer.setState({
          itemList: data.data.list,
          itemListTotal: data.data.total
        });
      }
    }).catch(function (error) {
      outer.setState({
        disableBtnSubmit: false
      });
      outer.showDialog('Warning', Errormsg["500"], "");
    });
  }

  handleFirstChange = () => {
    const outer = this;
    this.setState({
      disableBtnSubmit:true
    }, () => {
      axios.post('bo/int/newstore/edit', {"new":"false"}
      ).then(({data}) => {
        // console.log(data);
        if (data.st && data.sc === 200) {
          this.props.history.push("/bo/dashboard");
        } else {
          outer.setState({
            disableBtnSubmit:false
          });
          // outer.reloadRecaptcha();
          outer.showDialog('Warning',data.msg);
        }
      }).catch(function (error) {
        outer.setState({
          disableBtnSubmit:false
        });
        // outer.reloadRecaptcha();
        outer.showDialog('Warning',Errormsg["500"]);
      });

    });

  }

  isValidForm = () => {
    if(this.state.activeStep == 0){
      return (!this.state.error.logo)
    }else if(this.state.activeStep == 2){
      return (!this.state.error.catname)
    }else if(this.state.activeStep == 3){
      return (
      !this.state.error.name &&
      !this.state.error.hideprice &&
      !this.state.error.price &&
      !this.state.error.nego &&
      !this.state.error.condition &&
      !this.state.error.category &&
      !this.state.error.available &&
      !this.state.error.active
      )
    }
  }

  isStoreNameValidForm = () => {
    return (
    !this.state.error.storename)
  }

  isContactValidForm = () => {
    return (
    !this.state.error.defcontact)
  }

  isStoreContactValidForm = () => {
    return (
    !this.state.error.type &&
    !this.state.error.value )
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        logo: file,
        previewLogo: reader.result
      });
    }

    reader.readAsDataURL(file)
  }

  handleClickShowPassword = () => {
    this.setState({showPassword: !this.state.showPassword});
  };
  
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  onImageError = () =>{
    this.setState({previewLogo:require("../../../assets/katalok.png")});
  }

  handleNext = () => {
    if(this.state.activeStep != 3){
      this.setState({
        activeStep: this.state.activeStep + 1
      });
    }else{
      this.handleFirstChange();
    }
  }

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    });
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      activeTab: newValue
    });
  }

  onChangeRows(val) {
    let outer = this;
    if(this.state.activeStep == 1){
      this.setState({
        page: {
          ...this.state.page,
          page: 1,
          keyword: "",
          count: val
        }
      }, () => {
        outer.getContactList();
      })  
    }else if(this.state.activeStep == 2){
      this.setState({
        catpage: {
          ...this.state.catpage,
          page: 1,
          keyword: "",
          count: val
        }
      }, () => {
        outer.getCategoryList();
      })
    }
  }

  onChangePage(val) {
    let outer = this;
    if(this.state.activeStep == 1){
      this.setState({
        page: {
          ...this.state.page,
          page: val,
          keyword: "",
        }
      }, () => {
        outer.getContactList();
      })
    }else if(this.state.activeStep == 2){
      this.setState({
        catpage: {
          ...this.state.catpage,
          page: val,
          keyword: "",
        }
      }, () => {
        outer.getCategoryList();
      })
    }
  }

  handleItemImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        form: {
          ...this.state.form,
          imgpath: file
        },
        previewItem: reader.result
      });
    }

    reader.readAsDataURL(file)
  }


  handleImageDetailChange = index => e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    let listImg = this.state.listImg;

    reader.onloadend = () => {
      listImg[index].file = file;
      listImg[index].byte = reader.result;
      this.setState({
        listImg: listImg
      });
    }

    reader.readAsDataURL(file);

  }

  handleImageDetailDelete = index => e => {
    let listImg = this.state.listImg;
    listImg.splice(index, 1);
    this.setState({ listImg: listImg });
  }

  changeDropdown = name => (event, value) => {
    if (value) {
      this.setState({
        [name]: value,
        form: {
          ...this.state.form,
          [name]: value.id
        }
      });
    } else {
      this.setState({
        [name]: null,
        form: {
          ...this.state.form,
          [name]: null
        }
      })
    }
  }

  handleFocusOut = (e) => {
    let outer = this;
    let { form, error } = this.state;
    let inputName = e.target.name;
    let inputValue = e.target.value;

    if (inputName === "price") {
      let total = inputValue - outer.state.form.discount;
      form['total'] = total
      outer.setState(
        { form: form }
      );
    } else if (inputName === "discount") {
      let total = outer.state.form.price - inputValue;
      form['total'] = total
      outer.setState(
        { form: form }
      );
    }
  }

  handleAddImg = () => {
    let listImg = this.state.listImg;
    let elem = { id: 'new', file: null, byte: null };
    listImg.push(elem);
    this.setState({ listImg: listImg });
  }

  render(){
    const { classes, theme } = this.props;
    const { error, alowPeriod, storename, storedesc, defcontact, activeStep, conform, contactList, catform, categoryList, 
      form, conditionList, availableList, specialList, activeList, itemList, activeTab } = this.state;
    const title = "Pengaturan Awal"
    const steps = ["Pengaturan Usaha","Pengaturan Kontak","Tambah Kategori","Tambah Item"];

    let listImgElem = [];

    this.state.listImg.forEach((elem, i) => {
      let imgElem = <div key={"img_" + i} style={{ padding: 10 }}>
        <img
          src={elem.byte}
          style={{ width: 100, marginBottom: 10 }} />
        <br />
        <input
          type="file"
          name="imgfile[]"
          onChange={this.handleImageDetailChange(i)} />
        <button type="button" onClick={this.handleImageDetailDelete(i)} style={{ float: 'right' }}>Hapus Gambar</button>
      </div>
      listImgElem.push(imgElem);
    });

    const Comp = (row) => {

      let listImg = [];
      row.data.listimg.forEach(elem => {
          let img =  <div key={row.data.id+'_img_'+elem.seq} style={{ padding:10,width:150,display: 'flex'}}>
            <img width={125} src={elem.imgpath ? urlConfig.urlBackendItem + elem.imgpath : require("../../../assets/default_item.png")} />
          </div>
          listImg.push(img);
      });

      let wrapper = (listImg.length > 0) ? (
        <div>
          <h4>Gambar Tambahan</h4>
          <div style={{ display:'flex',flexDirection:'row'}}>
            {listImg}
          </div>
        </div>
      ):(
        <span/>
      )
      
      return (
        <div>
          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <h4>Gambar Utama</h4>
            <img width={200} src={row.data.imgpath ? urlConfig.urlBackendItem + row.data.imgpath : require("../../../assets/default_item.png")} />
          </div>
          {wrapper}
        </div>
      )
    };

    return (
      <div style={{marginTop: theme.spacing(4)}}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div style={{marginBottom:20, display: 'flex', justifyContent:'space-between'}}>
            <Button
              disabled={activeStep === 0}
              onClick={this.handleBack}
              className={classes.backButton}
            >
              Kembali
            </Button>
            <Button 
              disabled={activeStep === 1 && this.state.contactList.length === 0}
              variant="contained" 
              color="primary" 
              onClick={this.handleNext}>
              {activeStep === steps.length - 1 ? 'Selesai' : 'Berikutnya'}
            </Button>
          </div>
          <div className={classes.paper}>
            {activeStep == 0 &&
              <form className={classes.form} noValidate>
                                   
                  
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="storename"
                        name="storename"
                        label={<IntlMessages id="setting.storename"/>}
                        value={storename} 
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleNameChange} 
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="storedesc"
                        name="storedesc"
                        label={<IntlMessages id="setting.storedesc"/>}
                        value={storedesc} 
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleDescChange} 
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Button
                        type="button"
                        className={classes.submit}
                        color="secondary"
                        variant="contained"
                        fullWidth
                        onClick={this.handleStoreNameValidation}
                        disabled={this.state.disableBtnSubmit}
                      >
                      UPDATE USAHA
                    </Button>

                    <Grid item xs={12}>
                      <TextField
                        id="contact"
                        name="contact"
                        label={<IntlMessages id="setting.contact"/>}
                        value={defcontact} 
                        size="small"
                        variant="outlined"
                        fullWidth
                        type="tel"
                        onChange={this.handleContactChange} 
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Button
                        type="button"
                        className={classes.submit}
                        color="secondary"
                        variant="contained"
                        fullWidth
                        onClick={this.handleContactValidation}
                        disabled={this.state.disableBtnSubmit}
                      >
                      UPDATE TELEPON
                    </Button>
                    
                    <Grid item xs={12}>
                      <label>Logo: </label>
                      <br/>
                      <img 
                        src={this.state.previewLogo}
                        onError={this.onImageError}
                        style={{width: 100, marginBottom: 10}}
                      />
                      <br/>
                      <input 
                        type="file"
                        onChange={(e) => this.handleImageChange(e)}
                      />
                    </Grid>
                    <Button
                      type="button"
                      className={classes.submit}
                      color="secondary"
                      variant="contained"
                      fullWidth
                      onClick={this.handleValidation}
                      disabled={this.state.disableBtnSubmit}
                    >
                      UPDATE LOGO
                    </Button>
                    
                  </Grid>
                </form>
              }
              {activeStep == 1 &&
                <>
                <form className={classes.form} noValidate>
                  <Grid container spacing={2}>
      
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="type"
                            variant="outlined" style={{backgroundColor:'#fff'}}>Tipe kontak</InputLabel>
                          <Select
                            labelId="type"
                            id="type"
                            name="type"
                            variant="outlined"
                            value={conform.type}
                            fullWidth
                            onChange={this.handleChange}
                          >
                            <MenuItem value={'Phone'}>Telepon</MenuItem>
                            <MenuItem value={'WhatsApp'}>WhatsApp</MenuItem>
                            <MenuItem value={'Line'}>Line</MenuItem>
                            {/* <MenuItem value={'Telegram'}>Telegram</MenuItem> */}
                            <MenuItem value={'Email'}>Email</MenuItem>
                          </Select>
                        </FormControl>
                        {
                          error.type &&   
                            <FormControlLabel
                              classes={{label:classes.labelerror}}
                              control={<div>&nbsp;&nbsp;&nbsp;</div>}
                              label={error.type}
                            />
                        }
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="value"
                          name="value"
                          label={<IntlMessages id="master.contact.contact"/>}
                          value={conform.value} 
                          size="small"
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange} 
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {
                          error.value &&   
                            <FormControlLabel
                              classes={{label:classes.labelerror}}
                              control={<div>&nbsp;&nbsp;&nbsp;</div>}
                              label={error.value}
                            />
                        }
                      </Grid>
                  </Grid>
                  <Button
                    type="button"
                    className={classes.submit}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={this.handleStoreContactValidation}
                    disabled={this.state.disableBtnSubmit}
                  >
                    TAMBAH
                  </Button>
                </form>
                <DataTable
                title="Daftar Kontak"
                columns={this.columns}
                data={contactList}
                noDataComponent={
                  <Card style={{maxWidth: 200, borderWidth: 1, borderStyle: 'solid'}}>
                    <CardMedia style={{textAlign: 'center'}}>
                      <MoodBad color="action" style={{
                        fontSize: 40, 
                        marginTop: 10, 
                        marginBottom: 10
                      }}/>
                    </CardMedia>
                    <CardContent>
                      <Typography>
                        No data available
                      </Typography>
                    </CardContent>
                  </Card>
                  
                }
                highlightOnHover
                customStyles={customStyles}
                pagination
                paginationServer
                paginationTotalRows={this.state.contactListTotal}
                paginationPerPage={this.state.page.count}
                onChangePage={(val) => this.onChangePage(val)}
                onChangeRowsPerPage={(val) => this.onChangeRows(val)}
              />
              </>
              }
              {activeStep == 2 &&
                <>
                <form className={classes.form} noValidate>
                  <Grid container spacing={2}>      
                      <Grid item xs={12}>
                        <TextField
                          id="name"
                          name="name"
                          label={<IntlMessages id="master.category.name"/>}
                          value={catform.name} 
                          size="small"
                          required
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange} 
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={error.catname?true:false}
                        />
                        {
                          error.catname &&   
                            <FormControlLabel
                              classes={{label:classes.labelerror}}
                              control={<div>&nbsp;&nbsp;&nbsp;</div>}
                              label={error.catname}
                            />
                        }
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="desc"
                          name="desc"
                          label={<IntlMessages id="master.category.description"/>}
                          value={catform.desc} 
                          size="small"
                          variant="outlined"
                          fullWidth
                          onChange={this.handleChange} 
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="icon"
                            variant="outlined" style={{backgroundColor:'#fff'}}>Icon</InputLabel>
                          <Select
                            labelId="icon"
                            id="icon"
                            name="icon"
                            variant="outlined"
                            value={catform.icon}
                            fullWidth
                            onChange={this.handleChange}
                          >
                            <MenuItem value={'coffee'}><FontAwesomeIcon icon='coffee'/></MenuItem>
                            <MenuItem value={'car'}><FontAwesomeIcon icon='car'/></MenuItem>
                            <MenuItem value={'camera'}><FontAwesomeIcon icon='camera'/></MenuItem>
                            <MenuItem value={'bath'}><FontAwesomeIcon icon='bath'/></MenuItem>
                            <MenuItem value={'beer'}><FontAwesomeIcon icon='beer'/></MenuItem>
                            <MenuItem value={'futbol'}><FontAwesomeIcon icon='futbol'/></MenuItem>
                            <MenuItem value={'paw'}><FontAwesomeIcon icon='paw'/></MenuItem>
                            <MenuItem value={'chess-knight'}><FontAwesomeIcon icon='chess-knight'/></MenuItem>
                            <MenuItem value={'shower'}><FontAwesomeIcon icon='shower'/></MenuItem>
                            <MenuItem value={'star'}><FontAwesomeIcon icon='star'/></MenuItem>
                            <MenuItem value={'tag'}><FontAwesomeIcon icon='tag'/></MenuItem>
                            <MenuItem value={'thumbs-up'}><FontAwesomeIcon icon='thumbs-up'/></MenuItem>
                            <MenuItem value={'utensils'}><FontAwesomeIcon icon='utensils'/></MenuItem>
                            <MenuItem value={'air-freshener'}><FontAwesomeIcon icon='air-freshener'/></MenuItem>
                            <MenuItem value={'award'}><FontAwesomeIcon icon='award'/></MenuItem>
                            <MenuItem value={'balance-scale'}><FontAwesomeIcon icon='balance-scale'/></MenuItem>
                            <MenuItem value={'bicycle'}><FontAwesomeIcon icon='bicycle'/></MenuItem>
                            <MenuItem value={'book'}><FontAwesomeIcon icon='book'/></MenuItem>
                            <MenuItem value={'briefcase'}><FontAwesomeIcon icon='briefcase'/></MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                  </Grid>
                  <Button
                    type="button"
                    className={classes.submit}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={this.handleValidation}
                    disabled={this.state.disableBtnSubmit}
                  >
                    TAMBAH KATEGORI
                  </Button>
                </form>
                <DataTable
                title="Daftar Kategori"
                columns={this.catcolumns}
                data={categoryList}
                noDataComponent={
                  <Card style={{maxWidth: 200, borderWidth: 1, borderStyle: 'solid'}}>
                    <CardMedia style={{textAlign: 'center'}}>
                      <MoodBad color="action" style={{
                        fontSize: 40, 
                        marginTop: 10, 
                        marginBottom: 10
                      }}/>
                    </CardMedia>
                    <CardContent>
                      <Typography>
                        No data available
                      </Typography>
                    </CardContent>
                  </Card>
                  
                }
                highlightOnHover
                customStyles={customStyles}
                pagination
                paginationServer
                paginationTotalRows={this.state.categoryListTotal}
                paginationPerPage={this.state.page.count}
                onChangePage={(val) => this.onChangePage(val)}
                onChangeRowsPerPage={(val) => this.onChangeRows(val)}
              />
              </>
              }
              {activeStep == 3 &&
              <>
               <Tabs
               value={this.state.activeTab}
               onChange={this.handleTabChange}
               indicatorColor="primary"
               textColor="primary"
               variant="fullWidth"
               aria-label="full width tabs example"
             >
               <Tab label="Tambah Item" {...a11yProps(0)} />
               <Tab label="Daftar Item" {...a11yProps(1)} />
             </Tabs>
               {activeTab == 0&&
                <form className={classes.form} noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        name="name"
                        label={<IntlMessages id="master.item.name" />}
                        value={this.state.form.name}
                        size="small"
                        required
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={error.name ? true : false}
                      />
                      {
                        error.name &&
                        <FormControlLabel
                          classes={{ label: classes.labelerror }}
                          control={<div>&nbsp;&nbsp;&nbsp;</div>}
                          label={error.name}
                        />
                      }
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="description"
                        name="description"
                        label={<IntlMessages id="master.item.description" />}
                        value={this.state.form.description}
                        size="small"
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={3}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl component="fieldset" className={classes.formControl} style={{width:'100%'}}>
                        <FormLabel component="legend" error={error.hideprice?true:false} style={{fontSize:12}}><IntlMessages id="master.item.hideprice"/>*</FormLabel>
                        <RadioGroup aria-label="hideprice" id="hideprice" name="hideprice" value={this.state.form.hideprice} onChange={this.handleChange}>
                          <FormControlLabel value="true" control={<Radio />} label={<IntlMessages id="yes"/>} />
                          <FormControlLabel value="false" control={<Radio />} label={<IntlMessages id="no"/>} />
                        </RadioGroup>
                        {
                          error.hideprice &&   
                            <FormControlLabel
                              classes={{label:classes.labelerror}}
                              control={<div>&nbsp;&nbsp;&nbsp;</div>}
                              label={error.hideprice}
                            />
                        }
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="price"
                        name="price"
                        label={<IntlMessages id="master.item.price" />}
                        value={this.state.form.price}
                        size="small"
                        variant="outlined"
                        required={this.state.form.hideprice == 'false'}
                        fullWidth
                        disabled={this.state.form.hideprice == 'true'}
                        onChange={this.handleChange}
                        onBlur={this.handleFocusOut}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={error.price ? true : false}
                      />
                      {
                        error.price &&
                        <FormControlLabel
                          classes={{ label: classes.labelerror }}
                          control={<div>&nbsp;&nbsp;&nbsp;</div>}
                          label={error.price}
                        />
                      }
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="discount"
                        name="discount"
                        label={<IntlMessages id="master.item.discount" />}
                        value={this.state.form.discount}
                        size="small"
                        variant="outlined"
                        required={this.state.form.hideprice == 'false'}
                        fullWidth
                        disabled={this.state.form.hideprice == 'true'}
                        onChange={this.handleChange}
                        onBlur={this.handleFocusOut}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={error.discount ? true : false}
                      />
                      {
                        error.discount &&
                        <FormControlLabel
                          classes={{ label: classes.labelerror }}
                          control={<div>&nbsp;&nbsp;&nbsp;</div>}
                          label={error.discount}
                        />
                      }
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="total"
                        name="total"
                        label={<IntlMessages id="master.item.total" />}
                        value={this.state.form.total}
                        size="small"
                        variant="outlined"
                        required={this.state.form.hideprice == 'false'}
                        fullWidth
                        disabled={this.state.form.hideprice == 'true'}
                        onChange={this.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                        error={error.total ? true : false}
                      />
                      {
                        error.total &&
                        <FormControlLabel
                          classes={{ label: classes.labelerror }}
                          control={<div>&nbsp;&nbsp;&nbsp;</div>}
                          label={error.total}
                        />
                      }
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl component="fieldset" className={classes.formControl} style={{width:'100%'}} 
                        disabled={this.state.form.hideprice == 'true'}>
                        <FormLabel component="legend" error={error.nego?true:false} style={{fontSize:12}}><IntlMessages id="master.item.nego"/>{this.state.form.hideprice == 'true' ? '' : '*'}</FormLabel>
                        <RadioGroup aria-label="nego" id="nego" name="nego" value={this.state.form.nego} onChange={this.handleChange}>
                          <FormControlLabel value="true" control={<Radio />} label={<IntlMessages id="can"/>} />
                          <FormControlLabel value="false" control={<Radio />} label={<IntlMessages id="cannot"/>} />
                        </RadioGroup>
                        {
                          error.nego &&   
                            <FormControlLabel
                              classes={{label:classes.labelerror}}
                              control={<div>&nbsp;&nbsp;&nbsp;</div>}
                              label={error.nego}
                            />
                        }
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <Autocomplete
                        options={categoryList}
                        getOptionLabel={(option) => option.name}
                        value={this.state.category}
                        onChange={this.changeDropdown("category")}
                        renderInput={
                          param => (
                            <TextField
                              {...param}
                              id="category"
                              label="Kategori"
                              fullWidth
                              variant="outlined"
                              required
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )
                        }
                      />
                      {
                        error.category &&
                        <FormControlLabel
                          classes={{ label: classes.labelerror }}
                          control={<div>&nbsp;&nbsp;&nbsp;</div>}
                          label={error.category}
                        />
                      }
                    </Grid>

                    <Grid item xs={12}>
                      <Autocomplete
                        options={conditionList}
                        getOptionLabel={(option) => option.name}
                        value={this.state.condition}
                        onChange={this.changeDropdown("condition")}
                        getOptionSelected={(option, value) => {
                          return (option.id === value.id);
                        }}
                        renderInput={
                          param => (
                            <TextField
                              {...param}
                              id="condition"
                              label="Kondisi"
                              fullWidth
                              variant="outlined"
                              required
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )
                        }
                      />
                      {
                        error.condition &&
                        <FormControlLabel
                          classes={{ label: classes.labelerror }}
                          control={<div>&nbsp;&nbsp;&nbsp;</div>}
                          label={error.condition}
                        />
                      }
                    </Grid>

                    <Grid item xs={12}>
                      <Autocomplete
                        options={availableList}
                        getOptionLabel={(option) => option.name}
                        value={this.state.available}
                        onChange={this.changeDropdown("available")}
                        getOptionSelected={(option, value) => {
                          return (option.id === value.id);
                        }}
                        renderInput={
                          param => (
                            <TextField
                              {...param}
                              id="available"
                              label="Ketersediaan"
                              fullWidth
                              variant="outlined"
                              required
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )
                        }
                      />
                      {
                        error.available &&
                        <FormControlLabel
                          classes={{ label: classes.labelerror }}
                          control={<div>&nbsp;&nbsp;&nbsp;</div>}
                          label={error.available}
                        />
                      }
                    </Grid>

                    <Grid item xs={12}>
                      <Autocomplete
                        options={specialList}
                        getOptionLabel={(option) => option.name}
                        value={this.state.special}
                        onChange={this.changeDropdown("special")}
                        renderInput={
                          param => (
                            <TextField
                              {...param}
                              id="special"
                              label="Attribut"
                              fullWidth
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Autocomplete
                        options={activeList}
                        getOptionLabel={(option) => option.name}
                        value={this.state.active}
                        onChange={this.changeDropdown("active")}
                        getOptionSelected={(option, value) => {
                          return (option.id === value.id);
                        }}
                        renderInput={
                          param => (
                            <TextField
                              {...param}
                              id="active"
                              label="Status Aktif"
                              fullWidth
                              variant="outlined"
                              required
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )
                        }
                      />
                      {
                        error.active &&
                        <FormControlLabel
                          classes={{ label: classes.labelerror }}
                          control={<div>&nbsp;&nbsp;&nbsp;</div>}
                          label={error.active}
                        />
                      }
                    </Grid>

                    <Grid item xs={12}>
                      <label>Gambar Item: </label>
                      <br />
                      <img
                        src={this.state.previewItem}
                        style={{ width: 100, marginBottom: 10 }}
                      />
                      <br />
                      <input
                        type="file"
                        onChange={(e) => this.handleItemImageChange(e)}
                      />
                    </Grid>
                    {(this.state.previewItem != null) &&
                      <Grid item xs={12}>
                        <h4>Gambar Tambahan</h4>
                        <Button
                          type="button"
                          color="primary"
                          variant="contained"
                          onClick={this.handleAddImg}
                        >
                          TAMBAH GAMBAR
                        </Button>

                        <div>
                          {listImgElem}
                        </div>

                      </Grid>
                      }

                  </Grid>
                  <Button
                    type="button"
                    className={classes.submit}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={this.handleValidation}
                    disabled={this.state.disableBtnSubmit}
                  >
                    TAMBAH ITEM
                      </Button>
                </form>
                }
                {activeTab == 1&&
                  <DataTable
                  title="Daftar Item"
                  columns={this.itemcolumns}
                  data={itemList}
                  noDataComponent={
                    <Card style={{ maxWidth: 200, borderWidth: 1, borderStyle: 'solid' }}>
                      <CardMedia style={{ textAlign: 'center' }}>
                        <MoodBad color="action" style={{
                          fontSize: 40,
                          marginTop: 10,
                          marginBottom: 10
                        }} />
                      </CardMedia>
                      <CardContent>
                        <Typography>
                          No data available
                        </Typography>
                      </CardContent>
                    </Card>

                  }
                  expandableRows
                  expandableRowsComponent={<Comp />}
                  highlightOnHover
                  customStyles={customStyles}
                  pagination
                  paginationServer
                  paginationTotalRows={this.state.itemListTotal}
                  paginationPerPage={this.state.page.count}
                  onChangePage={this.onChangePage}
                  onChangeRowsPerPage={(val) => this.onChangeRows(val)}
                />
                }
                </>
              }
          </div>            
        </Container>

        <DialogBasic
            open={this.state.dialogOpen} 
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            mode={this.state.dialogMode}
            handleClose={this.handleDialogClose}
            handleOk={this.handleDialogOk}
            />  
      </div>
    );
  }
}

DbFirstSetupEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStatestoProps = state => {
  return {
    param: state.param
  };
};

export default connect(
  mapStatestoProps, {deselectParam}
)(withStyles(styles)(withTheme(DbFirstSetupEdit)));